import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import DownloadIcon from "@mui/icons-material/Download";
//import DeleteIcon from '@mui/icons-material/Delete';
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";

import DownloadIcon from "@mui/icons-material/Download";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import axios from "axios";
import { red } from "@mui/material/colors";
import {
  addToken,
  deleteToken,
  getToken,
  updateToken,
} from "../utilities/backofficeservice";
import { useDispatch } from "react-redux";
import { loaderOff, loaderOn } from "../redux/actions";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,

  bgcolor: "background.paper",
  border: "2px solid white",

  // boxShadow: 24,
  p: 4,
};

interface Column {
  id:
    | "User"
    | "Category"
    | "Location_Name"
    | "Location_Address"
    | "IMG_Attached"
    | "Token"
    | "Status"
    | "referenceId";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}
interface Data {
  User: string;
  Category: string;
  Location_Name: string;
  Location_Address: string;
  IMG_Attached: string;
  Token: number;
  Status: string;
  referenceId: string;
}
const columns: readonly Column[] = [
  { id: "User", label: "User", color: "#8E47E6" },
  { id: "Category", label: "Category", color: "#8E47E6" },
  { id: "Location_Name", label: "Location Name", color: "#8E47E6" },
  { id: "Location_Address", label: "Location Address", color: "#8E47E6" },
  { id: "IMG_Attached", label: "IMG Attached", color: "#8E47E6" },
  { id: "Token", label: "Token", color: "#8E47E6" },
  { id: "Status", label: "Status", color: "#8E47E6" },
  { id: "referenceId", label: "Action", color: "#8E47E6" },
];

interface Column2 {
  id:
    | "Category"
    | "Details"
    | "Discovery"
    | "Collector"
    | "Updatepoi"
    | "token_id";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}
const columns2: readonly Column2[] = [
  { id: "Category", label: "Category", color: "#8E47E6" },
  { id: "Details", label: "Details", color: "#8E47E6" },
  {
    id: "Discovery",
    label: "Discovery",

    format: (value: number) => value.toLocaleString("en-US"),
    color: "#8E47E6",
  },
  {
    id: "Collector",
    label: "2nd Collector (Verif)",

    format: (value: number) => value.toLocaleString("en-US"),
    color: "#8E47E6",
  },
  {
    id: "Updatepoi",
    label: "Update (POI> 6 Months)",
    color: "#8E47E6",
  },
  {
    id: "token_id",
    label: "Action",

    format: (value: number) => value.toFixed(2),
    color: "#8E47E6",
  },
];

interface Data2 {
  category: string;
  details: string;
  discovery: number;
  collector: number;
  updatepoi: number;
  token_id: number;
}

export default function HomePage() {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState<Data[]>([]);
  const [rows1, setRows1] = useState<Data2[]>([]);
  let Totaltokens = 0;
  const [Token, setToken] = useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [len, setLen] = useState(0);
  let Length = 0;
  const [pending, setPending] = useState(0);
  const [approved, setApproved] = useState(0);
  const [rejected, setRejected] = useState(0);

  const [tokenId, setTokenId] = React.useState(null);
  const [referenceId, setReferenceId] = React.useState(null);
  const [Category, setCategory] = useState("");
  const [Collector, setCollector] = useState("");
  const [Details, setDetails] = useState("");
  const [Discovery, setDiscovery] = useState("");
  const [Update, setUpdate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");

  const [formvalues, setFormvalues]: any = useState({
    category: {
      name: "",
      value: "",
      error: false,
    },

    details: {
      name: "",
      value: "",
      error: false,
    },

    discovery: {
      name: "",
      value: "",
      error: false,
    },

    collector: {
      name: "",
      value: "",
      error: false,
    },
    updatepoi: {
      name: "",
      value: "",
      error: false,
    },

    status: {
      name: "",
      value: "",
      error: false,
    },

    token: {
      name: "",
      value: "",
      error: false,
    },

    address: {
      name: "",
      value: "",
      error: false,
    },

    locationName: {
      name: "",
      value: "",
      error: false,
    },

    locationType: {
      name: "",
      value: "",
      error: false,
    },
  });

  const handleChangee = (name: string | number, newValue: any) => {
    setFormvalues((prevState: { [x: string]: any }) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: newValue,
      },
    }));
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const filteredRows = rows.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const filteredRows1 = rows1.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery1.toLowerCase())
    )
  );

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [openToken, setOpenToken] = React.useState(false);
  const handleOpenToken = (tokenId: any) => {
    setTokenId(tokenId);
    setOpenToken(true);
  };

  const handleClose4 = () => {
    setTokenId(null);
    setOpenToken(false);
  };

  const handleUpdateToken = async (tokenId: any) => {
    const formData = {
      category: formvalues.category.value,
      details: formvalues.details.value,
      discovery: formvalues.discovery.value,
      collector: formvalues.collector.value,
      updatepoi: formvalues.updatepoi.value,
    };
    // try {
    //   const res = await axios.put(`https://dev-backoffice.tribe.sn/token/${tokenId}`, formData)
    //   if (res.data != null) {
    //     console.log("data edited sucessfully");
    //     handleClose4();
    //     fetchLocationType();

    //   }
    // } catch (error) {
    //   console.log(error);
    // }

    const res = await updateToken(tokenId, formData);

    console.log(res);
  };

  const handleDeleteTokenn = async (tokenId: any) => {
    const formData = {
      deleteStatus: "yes",
    };
    // try {
    //   const res = await axios.put(`https://dev-backoffice.tribe.sn/token/delete/${tokenId}`, formData)
    //   if (res.data != null) {
    //     console.log("data edited sucessfully")
    //     fetchLocationType();

    //   }

    // } catch (error) {
    //   console.log(error);
    // }

    const res = await deleteToken(tokenId, formData);
    console.log(res);
  };

  // const handleAutoFill = async(tokenId:any) =>{
  //   try{
  //     const res  = await axios.get(`https://dev-backoffice.tribe.sn/token/${tokenId}`)

  //     if(res.data != null){
  //       console.log(res.data);
  //       setCategory(res.data.category);
  //       setCollector(res.data.collector);
  //       setDetails(res.data.details);
  //       setDiscovery(res.data.discovery);
  //       setUpdate(res.data.updatepoi);

  //     }

  //   }catch(error){

  //     console.log(error);

  //   }
  // }

  const handleEditToken = async (tokenId: any) => {
    const res = await getToken(tokenId);

    if (res != null) {
      console.log(res.data);

      formvalues.category.value = res.data.category;
      formvalues.collector.value = res.data.collector;
      formvalues.details.value = res.data.details;
      formvalues.discovery.value = res.data.discovery;
      formvalues.updatepoi.value = res.data.updatepoi;

      // setCategory(res.data.category);
      // setCollector(res.data.collector);
      // setDetails(res.data.details);
      // setDiscovery(res.data.discovery);
      // setUpdate(res.data.updatepoi);
    }
    handleOpenToken(tokenId);
    // handleAutoFill(tokenId);
    console.log(`Edit  ${tokenId}`);
  };

  const handleDeleteToken = (tokenId: any) => {
    handleDeleteTokenn(tokenId);
    console.log(`Delete   ${tokenId}`);
  };

  const dispatch = useDispatch();

  const handleAddToken = async () => {
    const formData = {
      category: formvalues.category.value,
      details: formvalues.details.value,
      discovery: formvalues.discovery.value,
      collector: formvalues.collector.value,
      updatepoi: formvalues.updatepoi.value,
    };
    dispatch(loaderOn());
    try {
      const res = await addToken(formData);

      if (res != null) {
        console.log("Token Added Sucessfully");
        handleClose();
        fetchLocationType();
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  const fetchTokenDistributed = async () => {
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get(
        "https://dev-location.tribe.sn/location/token/getall", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data != null) {
        console.log(res.data);
        Totaltokens = res.data;
        setToken(Totaltokens);
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  };

  const fetchLocationPending = async () => {
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get("https://dev-location.tribe.sn/location/getpending", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data != null) {
        console.log(res.data);
        Length = res.data.length;
        setPending(Length);
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  };

  const fetchLocationApproved = async () => {
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get("https://dev-location.tribe.sn/location/getapproved", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data != null) {
        console.log(res.data);
        Length = res.data.length;
        setApproved(Length);
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  };

  const fetchLocationRejected = async () => {
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get("https://dev-location.tribe.sn/location/getrejected", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data != null) {
        console.log(res.data);
        Length = res.data.length;
        setRejected(Length);
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  };

  const fetchData = async () => {
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get("https://dev-backoffice.tribe.sn/locations/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data != null) {
        console.log("Fetched data:", res.data);
        Length = res.data.length;
        setLen(Length);
        console.log("Length of the array:", res.data.length);
        dispatch(loaderOff())
      }
    } catch (error) {
      console.error("Failed to fetch data", error);
      dispatch(loaderOff())
    }
  };

  const fetchLocation = async () => {
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://dev-location.tribe.sn/location/active/getall", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const backendData: any[] = response.data;

      const sortedData = backendData.sort((a, b) => a.user_id - b.user_id);

      const frontendData: Data[] = sortedData.map((backendRow, index) => ({
        User: "" + (index + 1),
        Category: backendRow.locationType,
        Location_Name: backendRow.locationName,
        Location_Address: backendRow.address,
        IMG_Attached:
          backendRow.uploads && backendRow.uploads.length > 0 ? "Yes" : "No",
        Token: backendRow.token,
        Status: backendRow.status,
        referenceId: backendRow.referenceId,
      }));

      setRows(frontendData);
      dispatch(loaderOff())
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch(loaderOff())
    }
  };

  const fetchLocationType = () => {
    const token = sessionStorage.getItem("jwtToken");
    axios
      .get("https://dev-backoffice.tribe.sn/token/active", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const backendData = response.data;
        const frontendData = backendData.map((backendRow: any) => ({
          category: backendRow.category,
          details: backendRow.details,
          discovery: backendRow.discovery,
          collector: backendRow.collector,
          updatepoi: backendRow.updatepoi,
          token_id: backendRow.token_id,
        }));

        setRows1(frontendData);
        console.log(frontendData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const [openLocation, setOpenLocation] = React.useState(false);
  const handleOpenLocation = (referenceId: any) => {
    setReferenceId(referenceId);
    setOpenLocation(true);
  };

  const handleClose5 = () => {
    setReferenceId(null);
    setOpenLocation(false);
  };

  // const handleFetchLocationById = async (referenceId: any) => {

  //   try {
  //     const res = await axios.get(`https://dev-location.tribe.sn/location/getbyid/${referenceId}`);
  //           if (res.data != null) {
  //       console.log("Successfully fetched the data");
  //       console.log(res.data);
  //       formvalues.locationType.value = res.data.locationType
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const handleEditLocation = async (referenceId: any) => {
    // syed
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get(
        `https://dev-location.tribe.sn/location/getbyid/${referenceId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data != null) {
        console.log("Successfully fetched the data");
        console.log(res.data);
        formvalues.locationType.value = res.data.locationType;
        formvalues.locationName.value = res.data.locationName;
        formvalues.address.value = res.data.address;
        formvalues.token.value = res.data.token;
        formvalues.status.value = res.data.status;
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
    handleOpenLocation(referenceId);
    console.log(`Edit  ${referenceId}`);
  };
  const handleUpdateLocation = async (referenceId: any) => {
    const FormData = {
      locationType: formvalues.locationType.value,
      locationName: formvalues.locationName.value,
      address: formvalues.address.value,
      token: formvalues.token.value,
      status: formvalues.status.value,
      referenceId: referenceId,
    };
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.put(
        "https://dev-location.tribe.sn/location/update",
        FormData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data != null) {
        console.log("location Data updated sucessfully");
        fetchLocation();
        handleClose5();
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  };

  const handleDeleteLocation = (referenceId: any) => {
    handleLocationDelete(referenceId);

    console.log(`Delete  ${referenceId}`);
  };

  const handleLocationDelete = async (referenceId: any) => {
    const formData = {
      delete: "Yes",
      referenceId: referenceId,
    };
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.put(
        "https://dev-location.tribe.sn/location/delete",
        formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data != null) {
        fetchLocation();
        console.log("location deleted sucessfully");
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  };

  useEffect(() => {
    fetchData();
    fetchLocation();
    fetchLocationType();
    fetchTokenDistributed();
    fetchLocationPending();
    fetchLocationApproved();
    fetchLocationRejected();
  }, []);

  return (
    <div>
      <div style={{ padding: "30px 30px 42px 250px" }}>
        {/*<div
          style={{
            display: "flex",
            justifyContent: "start",
            paddingBottom: "24px",
          }}
        >
          Analysis
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              height: "100px",
              width: "150px",
              // border: "2px solid black",
              marginRight: "65px",
            }}
          >
            <div style={{ padding: "12px 5px 12px 10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize:'16px',
                  fontFamily:'400',
                  width:'150px'
                }}
              >
                Locations Uploaded
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                }}
              >
                {len}
              </div>
              <div style={{ display: "flex", justifyContent: "start" }}>
                +5%
              </div>
            </div>
          </Box>
          <Box
            sx={{
              height: "100px",
              width: "150px",
              // border: "2px solid black",
              marginRight: "65px",
            }}
          >
            <div style={{ padding: "12px 5px 12px 10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize:'16px',
                  fontFamily:'400',
                  width:'150px'
                }}
              >
                Locations Rejected
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                }}
              >
                {rejected}

              </div>
              <div style={{ display: "flex", justifyContent: "start" }}>
                -2%
              </div>
            </div>
          </Box>
          <Box
            sx={{
              height: "100px",
              width: "150px",
              // border: "2px solid black",
              marginRight: "65px",
            }}
          >
            <div style={{ padding: "12px 5px 12px 10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize:'16px',
                  fontFamily:'400',
                  width:'150px'
                }}
              >
                Locations Approved
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                }}
              >
                {approved}
              </div>
              <div style={{ display: "flex", justifyContent: "start" }}>
                +10%
              </div>
            </div>
          </Box>
          <Box
            sx={{
              height: "100px",
              width: "150px",
              // border: "2px solid black",
              marginRight: "65px",
            }}
          >
            <div style={{ padding: "12px 12px 12px 12px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize:'16px',
                  fontFamily:'400',
                  width:'150px'
                }}
              >
                Locations Pending
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                }}
              >
                {pending}
              </div>
              <div style={{ display: "flex", justifyContent: "start" }}>
                +5%
              </div>
            </div>
          </Box>
          <Box
            sx={{
              height: "100px",
              // width: "150px",
              // border: "2px solid black",
              marginRight: "65px",
              backgroundColor:'#DEC9FE'

            }}
          >
            <div style={{ padding: "12px 12px 12px 12px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize:'16px',
                  fontFamily:'400',
                  width:'150px'
                }}
              >
                Tokens Distributed
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                }}
              >
                {Token}
              </div>
              <div style={{ display: "flex", justifyContent: "start" }}>
                +5%
              </div>
            </div>
          </Box>

          <Box
            sx={{
              height: "100px",
              // width: "150px",
              // border: "2px solid black",
              marginRight: "65px",
              backgroundColor:'#DEC9FE'

            }}
          >
            <div style={{ padding: "12px 12px 12px 12px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize:'16px',
                  fontFamily:'400',
                  width:'150px'
                }}
              >
                Token To Cash{" "}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                }}
              >
                1,000,000
              </div>
              <div style={{ display: "flex", justifyContent: "start" }}>
                +5%
              </div>
            </div>
          </Box>
        </div>
        <hr
          style={{
            borderTop: "10px solid  #F7F6F6",
          }}
        /> */}

        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                fontFamily: "",
                fontSize: "16px",
                fontWeight: "700",
                paddingBottom: "",
              }}
            >
              Location Data
            </div>
            {/* <div
              style={{
                height: "40px",
                width: "389px",
              }}
            >
              <input type="text" placeholder="Search..." />
            </div> */}
          </div>
          <div>
            {/* <Button
              style={{
                color: "black",
                backgroundColor: "#8E47E6",
              }}
              endIcon={<DownloadIcon />}
            >
              Export{" "}
            </Button> */}
          </div>
        </span>

        <Box
          sx={{
            color: "black",
            marginTop: "25px",
            marginBottom: "50px",
          }}
        >
          <Paper sx={{ overflow: "hidden" }}>
            <div style={{ display: "flex", justifyContent: "start" }}>
              <TextField
                label="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                variant="outlined"
                margin="normal"
                inputProps={{
                  style: {
                    width: "200px",
                  },
                }}
              />
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          color: column.color,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.referenceId}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "referenceId" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <p
                                      onClick={() =>
                                        handleEditLocation(row.referenceId)
                                      }
                                    >
                                      <CreateIcon />
                                    </p>
                                    <p
                                      onClick={() =>
                                        handleDeleteLocation(row.referenceId)
                                      }
                                    >
                                      <DeleteIcon />
                                    </p>
                                  </div>
                                ) : column.format &&
                                  typeof value === "number" ? (
                                  column.format(value)
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>

        <div style={{ paddingTop: "50px" }}>
          <span style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{ fontFamily: "", fontSize: "16px", fontWeight: "700" }}
              >
                Token Configuration
              </div>
              {/* <div
                style={{
                  height: "40px",
                  width: "389px",
                }}
              >
                <input type="text" placeholder="Search..." />
              </div> */}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ paddingRight: "10px" }}>
                <Button
                  style={{
                    color: "black",
                    backgroundColor: "#8E47E6",
                  }}
                  onClick={handleOpen}
                  startIcon={<AddIcon />}
                >
                  Add Token
                </Button>
              </div>
              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <h1>Add Token</h1>
                  </div>

                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                      >
                        Category
                      </div>
                      <TextField
                        id="filled-basic"
                        variant="filled"
                        name="category"
                        value={formvalues.category.value}
                        onChange={(e) =>
                          handleChangee("category", e.target.value)
                        }
                        InputProps={{
                          disableUnderline: true,

                          style: {
                            border: "1px solid #8E47E6",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                      >
                        Details
                      </div>
                      <TextField
                        id="filled-basic"
                        variant="filled"
                        name="details"
                        value={formvalues.details.value}
                        onChange={(e) =>
                          handleChangee("details", e.target.value)
                        }
                        InputProps={{
                          disableUnderline: true,

                          style: {
                            border: "1px solid #8E47E6",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                      >
                        Discovery
                      </div>
                      <TextField
                        id="filled-basic"
                        variant="filled"
                        name="discovery"
                        value={formvalues.discovery.value}
                        onChange={(e) =>
                          handleChangee("discovery", e.target.value)
                        }
                        InputProps={{
                          disableUnderline: true,

                          style: {
                            border: "1px solid #8E47E6",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                      >
                        Collector
                      </div>
                      <TextField
                        id="filled-basic"
                        variant="filled"
                        name="collector"
                        value={formvalues.collector.value}
                        onChange={(e) =>
                          handleChangee("collector", e.target.value)
                        }
                        InputProps={{
                          disableUnderline: true,

                          style: {
                            border: "1px solid #8E47E6",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                      >
                        UpdatePOI
                      </div>
                      <TextField
                        id="filled-basic"
                        variant="filled"
                        name="updatepoi"
                        value={formvalues.updatepoi.value}
                        onChange={(e) =>
                          handleChangee("updatepoi", e.target.value)
                        }
                        InputProps={{
                          disableUnderline: true,

                          style: {
                            border: "1px solid #8E47E6",
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        style={{
                          width: "250px",
                          backgroundColor: "#8E47E6",
                          color: "black",
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                        onClick={handleAddToken}
                      >
                        Add Token
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openToken}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                    onClick={handleClose4}
                  >
                    <CloseIcon />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <h1>Update Token</h1>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      Category
                    </div>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      name="category"
                      value={formvalues.category.value}
                      onChange={(e) =>
                        handleChangee("category", e.target.value)
                      }
                      InputProps={{
                        disableUnderline: true,

                        style: {
                          height: "40px",
                          width: "300px",
                          border: "1px solid #8E47E6",
                          paddingBottom: "10px",
                        },
                      }}
                    />
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        paddingTop: "20px",
                      }}
                    >
                      Details
                    </div>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      name="details"
                      value={formvalues.details.value}
                      onChange={(e) => handleChangee("details", e.target.value)}
                      InputProps={{
                        disableUnderline: true,

                        style: {
                          height: "40px",
                          width: "300px",
                          border: "1px solid #8E47E6",
                          paddingBottom: "10px",
                        },
                      }}
                    />
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        paddingTop: "20px",
                      }}
                    >
                      Discovery
                    </div>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      name="discovery"
                      value={formvalues.discovery.value}
                      onChange={(e) =>
                        handleChangee("discovery", e.target.value)
                      }
                      InputProps={{
                        disableUnderline: true,

                        style: {
                          height: "40px",
                          width: "300px",
                          border: "1px solid #8E47E6",
                          paddingBottom: "10px",
                        },
                      }}
                    />
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      Collector
                    </div>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      name="collector"
                      value={formvalues.collector.value}
                      onChange={(e) =>
                        handleChangee("collector", e.target.value)
                      }
                      InputProps={{
                        disableUnderline: true,

                        style: {
                          height: "40px",
                          width: "300px",
                          border: "1px solid #8E47E6",
                          paddingBottom: "10px",
                        },
                      }}
                    />
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      UpdatePOI
                    </div>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      name="updatepoi"
                      value={formvalues.updatepoi.value}
                      onChange={(e) =>
                        handleChangee("updatepoi", e.target.value)
                      }
                      InputProps={{
                        disableUnderline: true,

                        style: {
                          height: "40px",
                          width: "300px",
                          border: "1px solid #8E47E6",
                          paddingBottom: "10px",
                        },
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "20px",
                    }}
                  >
                    <Button
                      style={{
                        width: "250px",
                        backgroundColor: "#8E47E6",
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                      onClick={() => handleUpdateToken(tokenId)}
                    >
                      Update Token
                    </Button>
                  </div>
                </Box>
              </Modal>
              <Modal
                open={openLocation}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                    onClick={handleClose5}
                  >
                    <CloseIcon />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <h1>Update Location Data</h1>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      Category
                    </div>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      name="locationType"
                      value={formvalues.locationType.value}
                      onChange={(e) =>
                        handleChangee("locationType", e.target.value)
                      }
                      InputProps={{
                        disableUnderline: true,

                        style: {
                          height: "40px",
                          width: "300px",
                          border: "1px solid #8E47E6",
                          paddingBottom: "10px",
                        },
                      }}
                    />
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        paddingTop: "20px",
                      }}
                    >
                      Location Name
                    </div>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      name="locationName"
                      value={formvalues.locationName.value}
                      onChange={(e) =>
                        handleChangee("locationName", e.target.value)
                      }
                      InputProps={{
                        disableUnderline: true,

                        style: {
                          height: "40px",
                          width: "300px",
                          border: "1px solid #8E47E6",
                          paddingBottom: "10px",
                        },
                      }}
                    />
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        paddingTop: "20px",
                      }}
                    >
                      Location Address
                    </div>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      name="address"
                      value={formvalues.address.value}
                      onChange={(e) => handleChangee("address", e.target.value)}
                      InputProps={{
                        disableUnderline: true,

                        style: {
                          height: "40px",
                          width: "300px",
                          border: "1px solid #8E47E6",
                          paddingBottom: "10px",
                        },
                      }}
                    />
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      Token
                    </div>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      name="token"
                      value={formvalues.token.value}
                      onChange={(e) => handleChangee("token", e.target.value)}
                      InputProps={{
                        disableUnderline: true,

                        style: {
                          height: "40px",
                          width: "300px",
                          border: "1px solid #8E47E6",
                          paddingBottom: "10px",
                        },
                      }}
                    />
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      Status
                    </div>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      name="status"
                      value={formvalues.status.value}
                      onChange={(e) => handleChangee("status", e.target.value)}
                      InputProps={{
                        disableUnderline: true,

                        style: {
                          height: "40px",
                          width: "300px",
                          border: "1px solid #8E47E6",
                          paddingBottom: "10px",
                        },
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "20px",
                    }}
                  >
                    <Button
                      style={{
                        width: "250px",
                        backgroundColor: "#8E47E6",
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                      onClick={() => handleUpdateLocation(referenceId)}
                    >
                      Update Location Data
                    </Button>
                  </div>
                </Box>
              </Modal>

              {/* <Button
                style={{
                  color: "black",
                  backgroundColor: "#8E47E6",
                }}
                endIcon={<DownloadIcon />}
              >
                Export{" "}
              </Button> */}
            </div>
          </span>
        </div>
        <div></div>

        <Box
          sx={{
            // height: "550px",
            color: "black",
          }}
        >
          <div></div>
          <Paper sx={{ overflow: "hidden" }}>
            <div style={{ display: "flex", justifyContent: "start" }}>
              <TextField
                label="Search"
                value={searchQuery1}
                onChange={(e) => setSearchQuery1(e.target.value)}
                variant="outlined"
                margin="normal"
                inputProps={{
                  style: {
                    width: "200px",
                  },
                }}
              />
            </div>

            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns2.map((column2) => (
                      <TableCell
                        key={column2.id}
                        align={column2.align}
                        style={{
                          minWidth: column2.minWidth,
                          color: column2.color,
                        }}
                      >
                        {column2.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRows1
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.category}
                      >
                        {columns2.map((column2) => {
                          const value =
                            row[column2.id.toLowerCase() as keyof Data2];
                          return (
                            <TableCell key={column2.id} align={column2.align}>
                              {column2.id === "token_id" ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <p
                                    onClick={() =>
                                      handleEditToken(row.token_id)
                                    }
                                  >
                                    <CreateIcon />
                                  </p>
                                  <p
                                    onClick={() =>
                                      handleDeleteToken(row.token_id)
                                    }
                                  >
                                    <DeleteIcon />
                                  </p>
                                </div>
                              ) : column2.format &&
                                typeof value === "number" ? (
                                column2.format(value)
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredRows1.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>
    </div>
  );
}
