import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Slider from "react-slick";
import axios from "axios";
import { getActiveElement } from "@testing-library/user-event/dist/utils";
import PreviewIcon from "@mui/icons-material/Preview";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getActiveLocation,
  getById,
  updateDeleteLocation,
  updateLocation,
} from "../utilities/locationService";
import { getUser } from "../utilities/adminservice";
import CommonDialog from "../commondialog/confirm";
import { CustomPrimaryButton } from "../customtextfield/CustomButton";
import { useDispatch } from "react-redux";
import { loaderOff, loaderOn, logout, openSnackbar } from "../redux/actions";
import { allocateToken, updateStatus } from "../utilities/userService";
import { useSelector } from "react-redux";
// import { useNavigate } from "@reach/router";
import { useNavigate } from "react-router-dom";

import store from "../redux/store";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid white",
  borderRadius: "18px",
  // boxShadow: 24,
  p: 3,
};
const overlayStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const popupStyle: React.CSSProperties = {
  background: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
  position: "relative", // Ensure relative positioning for close icon
};
const closeIconStyle: React.CSSProperties = {
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
};

const popupContentStyle: React.CSSProperties = {
  textAlign: "center",
  marginTop: "30px", // Adjust the margin to make space for the close icon
};
interface Column {
  id:
    | "SerialNumber"
    | "User"
    | "Category"
    | "Location_Name"
    | "Location_Address"
    | "IMG_Attached"
    | "Token"
    | "Status"
    | "referenceId";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}

interface Data {
  User: string;
  Category: string;
  Location_Name: string;
  Location_Address: string;
  IMG_Attached: string;
  Token: number;
  Status: string;
  referenceId: string;
}
const columns: readonly Column[] = [
  { id: "SerialNumber", label: "Sl No", color: "#8E47E6" },
  // { id: "User", label: "User", color: "#8E47E6" },
  { id: "Category", label: "Category", color: "#8E47E6" },
  { id: "Location_Name", label: "Location Name", color: "#8E47E6" },
  { id: "Location_Address", label: "Location Address", color: "#8E47E6" },
  { id: "IMG_Attached", label: "IMG Attached", color: "#8E47E6" },
  { id: "Token", label: "Token", color: "#8E47E6" },
  { id: "Status", label: "Status", color: "#8E47E6" },
  { id: "referenceId", label: "Action", color: "#8E47E6" },
];
const columnss: readonly Column[] = [
  { id: "User", label: "Sl No", color: "#8E47E6" },
  { id: "Category", label: "Category", color: "#8E47E6" },
  { id: "Location_Name", label: "Location Name", color: "#8E47E6" },
  { id: "Location_Address", label: "Location Address", color: "#8E47E6" },
  { id: "IMG_Attached", label: "IMG Attached", color: "#8E47E6" },
  { id: "Token", label: "Token", color: "#8E47E6" },
  { id: "Status", label: "Status", color: "#8E47E6" },
  // { id: "referenceId", label: "Action", color: "#8E47E6" },
];
export default function Location() {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState<Data[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tokenId, setTokenId] = React.useState(null);
  const [referenceId, setReferenceId] = React.useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [openDialog, setopenDialog] = React.useState(false);
  const [openDialogRejected, setopenDialogRejected] = React.useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState<any | null>(
    null
  );
 
  const [email, setEmail] = useState("");

  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isTokenExpired = useSelector((state: any) => state.auth.isTokenExpired);
  console.log("syed" + isLoggedIn);
  useEffect(() => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken || isTokenExpired) {
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    } else if (!isLoggedIn) { 
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    }
  }, [isLoggedIn, isTokenExpired]);


  const handleCloseDialog = () => {
    setopenDialog(false);
    setopenDialogRejected(false);
    setShowContent(false);
  };
  const [formvalues, setFormvalues]: any = useState({
    status: {
      name: "",
      value: "",
      error: false,
    },

    token: {
      name: "",
      value: "",
      error: false,
    },

    address: {
      name: "",
      value: "",
      error: false,
    },

    locationName: {
      name: "",
      value: "",
      error: false,
    },

    locationType: {
      name: "",
      value: "",
      error: false,
    },
    createdBy: {
      name: "",
      value: "",
      error: false,
    },
    name_location: [],
  });

  const handleChangee = (name: string | number, newValue: any) => {
    setFormvalues((prevState: { [x: string]: any }) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: newValue,
      },
    }));
  };

  const filteredRows = rows
    .filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())
      )
    )
    .filter((row) => row.Status !== "Rejected")
    .filter((row) => row.Status !== "Approved");

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const dispatch = useDispatch();
  
  const fetchLocation = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn());
    try {
      const response = await getActiveLocation();
      const backendData: any[] = response;
      const sortedData = backendData.sort((a, b) => a.user_id - b.user_id);
      const frontendData: Data[] = sortedData.map((backendRow, index) => ({
        User: "" + (index + 1),
        Category: backendRow.locationType,
        Location_Name: backendRow.locationName,
        Location_Address: backendRow.address,
        IMG_Attached:
          backendRow.uploads && backendRow.uploads.length > 0 ? "Yes" : "No",
        Token: backendRow.token,
        Status: backendRow.status,
        referenceId: backendRow.referenceId,
        createdBy: backendRow.backendRow,
      }));
      console.log(frontendData);
      setRows(frontendData);
      dispatch(loaderOff());
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    dispatch(loaderOff());
  };

  const [openLocation, setOpenLocation] = React.useState(false);
  const handleOpenLocation = (referenceId: any) => {
    setReferenceId(referenceId);

    setOpenLocation(true);
  };

  const handleClose5 = () => {
    setReferenceId(null);
    setOpenLocation(false);
  };

  const [file, setFile] = useState("");
  const [images, setImages] = useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleEditLocation = async (referenceId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn());
    // syed
    try {
      // const res = await axios.get(`https://dev-location.tribe.sn/location/getbyid/${referenceId}`);
      const res = await getById(referenceId);
      if (res != null) {
        console.log("Successfully fetched the data");
        console.log(res);
        formvalues.locationType.value = res.locationType;
        formvalues.locationName.value = res.locationName;
        formvalues.address.value = res.address;
        formvalues.token.value = res.token;
        formvalues.status.value = res.status;
        formvalues.createdBy.value = res.createdBy;
        formvalues.name_location = res.name_location;
        setEmail(res.createdBy);

        console.log(res.createdBy);
        // setFile(res.images[0].image_content);
        // setImages(res.images[0].image_content);
        const imageContents = res.images.map(
          (image: any) => image.image_content
        );
        setImages(imageContents);
        dispatch(loaderOff());
      }
      const decodedImage = atob(file);
      // Create a Uint8Array from the decoded string
      const uint8Array = new Uint8Array(decodedImage.length);
      for (let i = 0; i < decodedImage.length; i++) {
        uint8Array[i] = decodedImage.charCodeAt(i);
      }

      const blob = new Blob([uint8Array], { type: "image/png" });

      console.log("file:" + file);
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
    handleOpenLocation(referenceId);
    console.log(`Edit  ${referenceId}`);
  };

  const handleAllocateToken = async (token: any, email: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    const FormData = {
      token_allocated: token,
    };
    console.log("Email being useddddddddddddddddddddddddddddddddd:", email);
    dispatch(loaderOn());
    try {
       const token = sessionStorage.getItem("jwtToken");
      const res = await allocateToken(email, FormData); 
      // {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );

      if (res != null) {
        console.log(res);
        console.log("Token allocated successfully");
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  const handleUpdateLocationApproved = async (referenceId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    const FormData = {
      locationType: formvalues.locationType.value,
      locationName: formvalues.locationName.value,
      address: formvalues.address.value,
      token: formvalues.token.value,
      status: "Approved",
      referenceId: referenceId,
    };
    dispatch(loaderOn());
    try {
      // const res = await axios.put("https://dev-location.tribe.sn/location/update", FormData)
      const res = await updateLocation(FormData);
      if (res != null) {
        console.log("location Data updated sucessfully");
        handleAllocateToken(formvalues.token.value, email);
        fetchLocation();
        handleClose5();
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };
  const handleUpdateLocationRejected = async (referenceId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    const FormData = {
      locationType: formvalues.locationType.value,
      locationName: formvalues.locationName.value,
      address: formvalues.address.value,
      token: formvalues.token.value,
      status: "Rejected",
      referenceId: referenceId,
    };

    dispatch(loaderOn());
    try {
      // const res = await axios.put("https://dev-location.tribe.sn/location/update", FormData)
      const res = await updateLocation(FormData);
      if (res != null) {
        console.log("location Data updated sucessfully");
        fetchLocation();
        handleClose5();
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  const handleDeleteLocation = (referenceId: any) => {
    setReferenceId(referenceId);
    setShowPopup(true);
    console.log(`Delete  ${referenceId}`);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleLocationDelete = async (referenceId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    const formData = {
      delete: "Yes",
      referenceId: referenceId,
    };
    dispatch(loaderOn());
    try {
      // const res = await axios.put("https://dev-location.tribe.sn/location/delete", formData)
      const res = await updateDeleteLocation(formData);
      if (res != null) {
        fetchLocation();
        console.log("location deleted sucessfully");
        togglePopup();
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  useEffect(() => {
    fetchLocation();
  }, []);
  const CustomTabs = styled(Tabs)(({ theme: any }) => ({
    "& .MuiTabs-indicator": {
      backgroundColor: "#8E47E6",
    },
  }));
  const getStatusColor = (status: any) => {
    switch (status) {
      case "Pending":
        return ""; // Set the color for 'Pending' status
      case "Approved":
        return "#2A7221"; // Set the color for 'Approved' status
      case "Rejected":
        return "#FF0000B2"; // Set the color for 'Rejected' status
      default:
        return "inherit"; // Use default color for other statuses
    }
  };
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  // Restriction
  const [userId, setUserId] = useState(0);
  let temp: number;
  const [location_read, setLocationRead] = useState(false);
  const [location_write, setLocationWrite] = useState(false);
  const getRoleData = async () => {
    const res: any = await getUser();
    if (res && res.role_type) {
      if (
        res.role_type.bonus_read !== undefined &&
        res.role_type.bonus_write !== undefined
      ) {
        setLocationRead(res.role_type.location_read);
        setLocationWrite(res.role_type.location_write);
      }
    }
  };
  const filteredColumns = location_write ? columns : columns.filter(column => column.id !== 'referenceId');

  const getLoginUserData = async () => {
    const res: any = await getUser();
    if (res != null) {
      temp = res.id;
      setUserId(temp);
    }
    console.log(temp);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getLoginUserData();
      getRoleData();
    };

    fetchData();
  }, []);

  return (
    <Box marginTop={"40px"}>
      {
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 700,
              bgcolor: "background.paper",
              border: "2px solid white",
              borderRadius: "18px",
              // boxShadow: 24,
              p: 4,
              overflow: "auto",
            }}
          >
            <div
              style={{
                // display: "flex",
                // justifyContent: "end",
                float: "right",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </div>
            {/* <div>
            <img
              // src="https://t3.ftcdn.net/jpg/05/71/06/76/360_F_571067620_JS5T5TkDtu3gf8Wqm78KoJRF1vobPvo6.jpg"
              style={{ height: "100%", width: "100%" }}
              // src={image}
              src={`data:image/png;base64,${image}`}
            />
            </div> */}
            <InfiniteScroll
              style={{ maxHeight: "400px", overflow: "auto" }}
              dataLength={images.length}
              next={() => {}}
              hasMore={false}
              loader={<h4>Loading...</h4>}
            >
              {images.map((imageUrl, index) => (
                <div key={index}>
                  <img
                    style={{
                      height: "50%",
                      width: "50%",
                      paddingLeft: "170px",
                    }}
                    src={`data:image/png;base64,${imageUrl}`}
                    alt={`Image ${index + 1}`}
                  />
                </div>
              ))}
            </InfiniteScroll>
          </Box>
        </Modal>
      }
      <div style={{}}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "white" }}>
            <CustomTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Location"
                {...a11yProps(0)}
                style={{
                  color: "#8E47E6",
                  fontWeight: "600",
                  textTransform: "none",
                  fontSize: "16px",
                }}
              />
              <Tab
                label="Location History"
                {...a11yProps(1)}
                style={{
                  color: "#8E47E6",
                  fontWeight: "600",
                  textTransform: "none",
                  fontSize: "16px",
                }}
              />
            </CustomTabs>
          </Box>
        </Box>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingBottom: "34px",
        }}
      >
        <span
          style={{
            paddingRight: "56px",
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          Location Data
        </span>
        <TextField
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search..."
          variant="outlined"
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: "#140026" }} />
              </InputAdornment>
            ),
            style: {
              width: "300px",
              boxShadow: "0px 4px 4px 0px rgba(142, 71, 230, 0.25)",
              borderRadius: "6px",
            },
          }}
        />
      </div>

      {/* </div> */}
      {/* <Button
              style={{
                color: "black",
                backgroundColor: "#8E47E6",
              }}
              endIcon={<DownloadIcon />}
            >
              Export{" "}
            </Button> */}
      <div>
        {value === 0 && (
          <Box
            sx={{
              color: "black",
              marginTop: "25px",
            }}
          >
            <Paper sx={{ overflow: "hidden" }}>
              <TableContainer
                sx={{
                  maxHeight: 440,
                  borderRadius: "18px",
                  boxShadow: "0px 4px 12px 0px #8E47E640",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            color: column.color,
                          }}
                        >
                          {/* {column.label} */}
                          {location_write || column.id !== 'referenceId' ? column.label : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.referenceId}
                          >
                            {/* {columns.map((column) => { */}
                            {filteredColumns.map((column, columnIndex) => {
                              // const value = row[column.id];
                              const value =
                                column.id === "SerialNumber"
                                  ? index + 1
                                  : row[column.id as keyof Data];
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    color:
                                      column.id === "Status"
                                        ? getStatusColor(value)
                                        : "inherit",
                                  }}
                                >
                                  {column.id === "referenceId" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      {location_write === true ? (
                                        <p
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleEditLocation(row.referenceId)
                                          }
                                        >
                                          <CreateIcon
                                            style={{ color: "#5151ED" }}
                                          />
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            cursor: "not-allowed",
                                            filter: "blur(1px)",
                                          }}
                                        >
                                          <CreateIcon
                                            style={{ color: "#5151ED" }}
                                          />
                                        </p>
                                      )}
                                      {location_write === true ? (
                                        <p
                                          onClick={() => {
                                            setopenDialog(true);
                                            setSelectedLocationId(
                                              row.referenceId
                                            );
                                          }}
                                        >
                                          <DeleteIcon
                                            style={{ color: "#DF2525" }}
                                          />
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            cursor: "not-allowed",
                                            filter: "blur(1px)",
                                          }}
                                        >
                                          <DeleteIcon
                                            style={{ color: "#DF2525" }}
                                          />
                                        </p>
                                      )}
                                    </div>
                                  ) : column.format &&
                                    typeof value === "number" ? (
                                    column.format(value)
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <CommonDialog
                open={openDialog}
                handleClose={handleCloseDialog}
                handleConfirm={() => {
                  handleLocationDelete(selectedLocationId);
                  handleCloseDialog();
                }}
                contentText="Are you sure to delete location?"
              />
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        )}
        {value === 1 && (
          <Box
            sx={{
              color: "black",
              marginTop: "25px",
            }}
          >
            <Paper sx={{ overflow: "hidden" }}>
              <TableContainer
                sx={{
                  maxHeight: 440,
                  borderRadius: "18px",
                  boxShadow: "0px 4px 12px 0px #8E47E640",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}>
                      {columnss.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            color: column.color,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .filter(
                        (row) =>
                          row.Status === "Rejected" || row.Status === "Approved"
                      )
                      .filter((row) =>
                        Object.values(row).some(
                          (value) =>
                            typeof value === "string" &&
                            value
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                        )
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.referenceId}
                          >
                            {/* {columns.map((column) => { */}
                            {columns.map((column, columnIndex) => {
                              // const value = row[column.id];
                              const value =
                                column.id === "SerialNumber"
                                  ? index + 1
                                  : row[column.id as keyof Data];
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    color:
                                      column.id === "Status"
                                        ? getStatusColor(value)
                                        : "inherit",
                                  }}
                                >
                                  {column.id === "referenceId" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      {/* <p onClick={() => handleEditLocation(row.referenceId)}><CreateIcon style={{ color: '#5151ED' }} /></p>
                                                                        <p onClick={() => handleDeleteLocation(row.referenceId)}><DeleteIcon style={{ color: '#DF2525' }} /></p> */}
                                    </div>
                                  ) : column.format &&
                                    typeof value === "number" ? (
                                    column.format(value)
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        )}

        <Modal
          open={openLocation}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
              onClick={handleClose5}
            >
              <CloseIcon />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              <h1>Update Location Data</h1>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Category
                </div>
                {/* <TextField
                  id="filled-basic"
                  variant="filled"
                  name="locationType"
                  value={formvalues.locationType.value}
                  onChange={(e) =>
                    handleChangee("locationType", e.target.value)
                  }
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                    style: {
                      border: "1px solid #8E47E6",
                    },
                  }}
                /> */}
                <TextField
                  id="filled-basic"
                  variant="filled"
                  name="locationType"
                  value={formvalues.locationType.value}
                  error={formvalues.locationType.error}
                  onChange={(e) =>
                    handleChangee("locationType", e.target.value)
                  }
                  helperText={
                    formvalues.locationType.error &&
                    formvalues.locationType.errorMessage
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      // border: "1px solid #8E47E6",
                      // height: "45px",
                      // marginTop: "10px",
                      border: "1px solid #8E47E6",
                      height: "7vh",
                      borderRadius: "12px",
                      paddingBottom: "5px",
                      background: "#FFFFFF",
                      marginTop: "5px",
                      display: "flex",
                      alignItems: "center",
                    },
                    inputProps: {
                        style: {
                          paddingTop: "0", // Remove top padding
                          paddingBottom: "0", // Remove bottom padding if needed
                        },
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Location Name
                </div>
                {/* <TextField
                  id="filled-basic"
                  variant="filled"
                  name="locationName"
                  value={formvalues.locationName.value}
                  onChange={(e) =>
                    handleChangee("locationName", e.target.value)
                  }
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,

                    style: {
                      border: "1px solid #8E47E6",
                    },
                  }}
                /> */}
                <TextField
                  id="filled-basic"
                  variant="filled"
                  name="locationName"
                  value={formvalues.locationName.value}
                  error={formvalues.locationName.error}
                  onChange={(e) =>
                    handleChangee("locationName", e.target.value)
                  }
                  helperText={
                    formvalues.locationName.error &&
                    formvalues.locationName.errorMessage
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      border: "1px solid #8E47E6",
                      height: "7vh",
                      borderRadius: "12px",
                      paddingBottom: "5px",
                      background: "#FFFFFF",
                      marginTop: "5px",
                      display: "flex",
                      alignItems: "center",
                    },
                    inputProps: {
                      style: {
                        paddingTop: "0", // Remove top padding
                        paddingBottom: "0", // Remove bottom padding if needed
                      },
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Location Address
                </div>
                {/* <TextField
                  id="filled-basic"
                  variant="filled"
                  name="address"
                  value={formvalues.address.value}
                  onChange={(e) => handleChangee("address", e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,

                    style: {
                      border: "1px solid #8E47E6",
                    },
                  }}
                /> */}
                <TextField
                  id="filled-basic"
                  variant="filled"
                  name="address"
                  // value={formvalues.address.value}
                  value={
                    formvalues.address.value.length > 40
                      ? formvalues.address.value.slice(0, 37) + "..."
                      : formvalues.address.value
                  }
                  error={formvalues.address.error}
                  onChange={(e) => handleChangee("address", e.target.value)}
                  helperText={
                    formvalues.address.error && formvalues.address.errorMessage
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      border: "1px solid #8E47E6",
                      height: "7vh",
                      borderRadius: "12px",
                      paddingBottom: "5px",
                      background: "#FFFFFF",
                      marginTop: "5px",
                      display: "flex",
                      alignItems: "center",
                    },
                    inputProps: {
                      style: {
                        paddingTop: "0", // Remove top padding
                        paddingBottom: "0", // Remove bottom padding if needed
                      },
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Token
                </div>
                {/* <TextField
                  id="filled-basic"
                  variant="filled"
                  name="token"
                  value={formvalues.token.value}
                  onChange={(e) => handleChangee("token", e.target.value)}
                  InputProps={{
                    disableUnderline: true,

                    style: {
                      border: "1px solid #8E47E6",
                    },
                  }}
                /> */}
                <TextField
                  id="filled-basic"
                  variant="filled"
                  name="token"
                  value={formvalues.token.value}
                  error={formvalues.token.error}
                  onChange={(e) => handleChangee("token", e.target.value)}
                  helperText={
                    formvalues.token.error && formvalues.token.errorMessage
                  }
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      border: "1px solid #8E47E6",
                      height: "7vh",
                      borderRadius: "12px",
                      paddingBottom: "5px",
                      background: "#FFFFFF",
                      marginTop: "5px",
                      display: "flex",
                      alignItems: "center",
                    },
                    inputProps: {
                      style: {
                        paddingTop: "0", // Remove top padding
                        paddingBottom: "0", // Remove bottom padding if needed
                      },
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Created By
                </div>
                {/* <TextField
                  id="filled-basic"
                  variant="filled"
                  name="createdBy"
                  value={formvalues.createdBy.value}
                  // onChange={(e) => handleChangee("createdBy", e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,

                    style: {
                      border: "1px solid #8E47E6",
                    },
                  }}
                /> */}
                <TextField
                  id="filled-basic"
                  variant="filled"
                  name="createdBy"
                  value={formvalues.createdBy.value}
                  error={formvalues.createdBy.error}
                  onChange={(e) => handleChangee("createdBy", e.target.value)}
                  helperText={
                    formvalues.createdBy.error &&
                    formvalues.createdBy.errorMessage
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      border: "1px solid #8E47E6",
                      height: "7vh",
                      borderRadius: "12px",
                      paddingBottom: "5px",
                      background: "#FFFFFF",
                      marginTop: "5px",
                      display: "flex",
                      alignItems: "center",
                    },
                    inputProps: {
                      style: {
                        paddingTop: "0", // Remove top padding
                        paddingBottom: "0", // Remove bottom padding if needed
                      },
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Location Type
                </div>
                {/* <TextField
                  id="filled-basic"
                  variant="filled"
                  name="locationType"
                  value={formvalues.locationType.value}
                  // onChange={(e) => handleChangee("createdBy", e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,

                    style: {
                      border: "1px solid #8E47E6",
                    },
                  }}
                /> */}
                <TextField
                  id="filled-basic"
                  variant="filled"
                  name="locationType"
                  value={formvalues.locationType.value}
                  error={formvalues.locationType.error}
                  onChange={(e) =>
                    handleChangee("locationType", e.target.value)
                  }
                  helperText={
                    formvalues.locationType.error &&
                    formvalues.locationType.errorMessage
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      border: "1px solid #8E47E6",
                      height: "7vh",
                      borderRadius: "12px",
                      paddingBottom: "5px",
                      background: "#FFFFFF",
                      marginTop: "5px",
                      display: "flex",
                      alignItems: "center",
                    },
                    inputProps: {
                      style: {
                        paddingTop: "0", // Remove top padding
                        paddingBottom: "0", // Remove bottom padding if needed
                      },
                    },
                  }}
                  fullWidth
                />
              </Grid>
              {/* <Grid item xs={6}>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Name Location
                </div> */}
              {/* {formvalues.name_location.map((data: any) => (
  <div >
    {data.formvalues.name_location && (
      <div>
        {JSON.parse(data.name_location).map((item: any, index: number) => (
          <div key={index}>
            {Object.entries(item).map(([key, value]: [string, any]) => (
              <p key={key}>{`${key}: ${value}`}</p>
            ))}
          </div>
        ))}
      </div>
    )}
  </div>
))} */}
              {/* {formvalues.name_location.map((item: any, index: number) => (
                 <div key={index}>
                 {Object.entries(item).map(([key, value]: [string, any]) => (
                   <p key={key}>{`${key}: ${value}`}</p>
                 ))}
               </div>
                ))} */}
              {/* {formvalues.name_location.map((item: any, index: number) => (
                  <div key={index}>
                    {Object.values(item).map(
                      (value: any, innerIndex: number) => (
                        <p key={innerIndex}>{value}</p>
                      )
                    )}
                  </div>
                ))}
              </Grid> */}
              {/* <Grid item xs={6}>
                
                <div style={{ fontSize: "16px", fontWeight: "700" }}>
                  Name Location
                </div>
                {formvalues.name_location &&
                  formvalues.name_location.map((item: any, index: number) => (
                    <div key={index}>{Object.values(item).join(" ")}</div>
                  ))}
              </Grid> */}

              <Grid item xs={6}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{ textTransform: "none" }}
                    onClick={handleOpen}
                  >
                    Preview Image
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  gap: "10px",
                }}
              >
                <CustomPrimaryButton
                  style={{}}
                  onClick={() => {
                    setopenDialog(true);
                  }}
                >
                  Approve
                </CustomPrimaryButton>
                <CommonDialog
                  open={openDialog}
                  handleClose={handleCloseDialog}
                  handleConfirm={() => {
                    handleUpdateLocationApproved(referenceId);
                    handleCloseDialog();
                  }}
                  contentText="Are you sure to approve location?"
                />
                <CustomPrimaryButton
                  onClick={() => {
                    setopenDialogRejected(true);
                  }}
                >
                  Reject
                </CustomPrimaryButton>
                <CommonDialog
                  open={openDialogRejected}
                  handleClose={handleCloseDialog}
                  handleConfirm={() => {
                    handleUpdateLocationRejected(referenceId);
                    handleCloseDialog();
                  }}
                  contentText="Are you sure to reject location?"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
    </Box>
  );
}
