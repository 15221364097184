import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from "react-redux";
import { loaderOff, loaderOn } from "../redux/actions";



interface Column {

  id: "User" | "UserName" | "email" | "Date";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

interface BackendRow {
  user_id: number;
  created_on: Date;
  name: string;
  email: string;

}
var num = 1;
const columns: readonly Column[] = [
  { id: "User", label: "User", },
  { id: "UserName", label: "UserName", },
  { id: "email", label: "Email", },
  { id: "Date", label: "Date", },

];

interface Column2 {
  id: "id" | "User" | "Location_Name" | "Location_Address" | "Created_on";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

interface BackendRow2 {
  createdBy: string;
  locationName: string;
  address: string;
  createdOn: Date;

}

const columns1: readonly Column2[] = [
  { id: "id", label: "id", },
  { id: "User", label: "User", },
  { id: "Location_Name", label: "Location_Name", },
  { id: "Location_Address", label: "Location_Address", },
  { id: "Created_on", label: "Created_on", },

];


interface Column3 {
  id: "User" | "Email" | "Action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns3: readonly Column3[] = [
  { id: "User", label: "User", },
  { id: "Email", label: "Email", },

  { id: "Action", label: "Restricted Access", align: "right", minWidth: 0 },
];

interface Data3 {
  user_id: string;
  email: string;


}



export default function EndUser() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState<BackendRow[]>([]);
  const [rows1, setRows1] = useState<BackendRow2[]>([]);
  const [rows2, setRows2] = useState<Data3[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [searchQuery,setSearchQuery]=useState('');
  const [searchQuery1,setSearchQuery1]=useState('');
  const [searchQuery2,setSearchQuery2]=useState('');







  // const fetchuser = () => {
  //   axios.get("https://dev-backoffice.tribe.sn/locations/all")
  //     .then((response) => {
  //       const backendData = response.data;
  //       const frontendData = backendData.map((backendRow: any) => ({
  //         User: backendRow.user_id,
  //         Email: backendRow.email,

  //         status: backendRow.status,
  //       }));
  //       setRows2(frontendData);
  //       console.log(frontendData);
  //       console.log("frontendData");
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // };















  useEffect(() => {

    const token = sessionStorage.getItem("jwtToken");
    fetch("https://dev-backoffice.tribe.sn/users/all", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((backendData: BackendRow[]) => {
        const frontendData: BackendRow[] = backendData.map((backendRow) => ({
          user_id: backendRow.user_id,
          name: backendRow.name,
          email: backendRow.email,
          created_on: backendRow.created_on,

        }));

        setRows(frontendData);
        console.log(frontendData);

      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);


  useEffect(() => {
    const token = sessionStorage.getItem("jwtToken");
    fetch("https://dev-backoffice.tribe.sn/locations/all", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((backendData: BackendRow2[]) => {
        const frontendData: BackendRow2[] = backendData.map((backendRow) => ({
          createdBy: backendRow.createdBy,
          locationName: backendRow.locationName,
          address: backendRow.address,
          createdOn: backendRow.createdOn,

        }));

        setRows1(frontendData);
        console.log(frontendData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("jwtToken");
    fetch("https://dev-backoffice.tribe.sn/users/all", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((backendData: Data3[]) => {
        const frontendData: Data3[] = backendData.map((backendRow) => ({
          user_id: backendRow.user_id,
          email: backendRow.email,


        }));

        setRows2(frontendData);
        console.log(frontendData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleCheckboxChange = (email: string) => {
    setSelectedEmails((prevSelectedEmails) => {
      if (prevSelectedEmails.includes(email)) {
        return prevSelectedEmails.filter((selectedEmail) => selectedEmail !== email);
      } else {
        return [...prevSelectedEmails, email];
      }
    });
  };

  const dispatch = useDispatch();

  const handleApplyClick = async (userId: string) => {
    dispatch(loaderOn())
    const apiEndpoint = `https://dev-backoffice.tribe.sn/users/update/${userId}`;

    try {
      // Make the API call to delete the user with the specified userId
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.put(apiEndpoint, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the frontend state by filtering out the deleted user
      setRows2((prevRows) => prevRows.filter((row) => row.user_id !== userId));

      // Handle the API response as needed
      console.log('API response:', res.data);
      dispatch(loaderOff())
    } catch (error) {
      // Handle errors, e.g., display an error message to the user
      console.error('Error calling API:', error);
      dispatch(loaderOff())
    }
  };




  // const  handleUpdate =  async (selectedEmails:any) =>{
  //  const formData = {
  //     "account_status":"deleted"
  //   }
  //   try{
  //     const res = await axios.put(`https://dev-user.tribe.sn/user/update/${selectedEmails}`,formData)
  //     if(res.data  != null){
  //       console.log("updated sucessfully")
  //     }

  //   }catch(error){
  //     console.log(error);

  //   }
  // }


  const handleCancelClick = () => {
    // Handle the cancel button click to clear selected checkboxes
    setSelectedEmails([]);
  };



  const filteredRows = rows.filter((row) =>
  Object.values(row).some(
  (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
  )
  );
  
  const filteredRows1 = rows1.filter((row) =>
  Object.values(row).some(
  (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery1.toLowerCase())
  )
  );

  const filteredRows2 = rows2.filter((row) =>
  Object.values(row).some(
  (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery2.toLowerCase())
  )
  );
  
  const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
  setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event: { target: { value: string | number; }; }) => {
  setRowsPerPage(+event.target.value);
  setPage(0);
  };

  const slicedRows = filteredRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const slicedRows1 = filteredRows1.slice(page * rowsPerPage, (page + 1) * rowsPerPage);


  const slicedRows2 = filteredRows2.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleButtonClick = () => {
    setPopupVisible(true);
  };
  return (
    <div style={{ padding: "30px 30px 42px 320px" }}>
    

      <span
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{
            fontSize: '17px',
            fontWeight: '700'
          }}>User Generated Data</div>
          {/* <div
            style={{
              height: "40px",
              width: "389px",
            }}
          >
            <input type="text" placeholder="Search..." />
          </div> */}
        </div>
        <div>
          {/* <Button
            style={{
              color: "black",
              backgroundColor: "#D9D9D9",
            }}
          >
            Export{" "}
          </Button> */}
        </div>
      </span>

      <Box
        sx={{
           color: "black",
        
        }}
      >
        <Paper sx={{ overflow: "hidden" }}>
        <div style={{display:'flex',
  justifyContent:'start'
  }}>
  <TextField
  label="Search"
  value={searchQuery}
  onChange={(e)=>setSearchQuery(e.target.value)}
  variant="outlined"
  margin="normal"
  inputProps={{
  style:{
  width:'200px',
  
  
  }
  }}
  
  />
  </div>

          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>



              <TableBody>
                {slicedRows.map((row, index) => (
                  <TableRow key={row.user_id}>
                    {columns.map((column) => (
                      <TableCell key={column.id}>
                        {column.id === "User" ? (index + 1).toString() : null}
                        {column.id === "UserName" ? row.name : null}
                        {column.id === "email" ? row.email : null}
                        {column.id === "Date" ? new Date(row.created_on).toLocaleDateString('en-GB') : null}

                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>


            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <span
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop:"50px"
          }}
        >

          <div style={{
            fontSize: '17px',
            fontWeight: '700'
          }}>Token Receipts</div>
          {/* <div
            style={{
              height: "40px",
              width: "389px",
            }}
          >
            <input type="text" placeholder="Search..." />
          </div> */}
        </div>
        <div>
          {/* <Button
            style={{
              color: "black",
              backgroundColor: "#D9D9D9",
            }}
          >
            Export{" "}
          </Button> */}
        </div>
      </span>
      <Box
        sx={{
    
          color: "black",
          marginBottom:'50px',

        }}
      >
        <div></div>
        <Paper sx={{ overflow: "hidden" }}>
        <div style={{display:'flex',
  justifyContent:'start'
  }}>
  <TextField
  label="Search"
  value={searchQuery1}
  onChange={(e)=>setSearchQuery1(e.target.value)}
  variant="outlined"
  margin="normal"
  inputProps={{
  style:{
  width:'200px',
  
  
  }
  }}
  
  />
  </div>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns1.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {slicedRows1.map((row, index) => (
                  <TableRow key={row.locationName}>
                    {columns1.map((column) => (
                      <TableCell key={column.id}>
                        {column.id === "id" ? (index + 1).toString() : null}
                        {column.id === "User" ? row.createdBy : null}
                        {column.id === "Location_Name" ? row.locationName : null}
                        {column.id === "Location_Address" ? row.address : null}
                        {
                          column.id === "Created_on" ? new Date(row.createdOn).toLocaleDateString('en-GB') : null
                        }                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredRows1.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <span
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop:"50px"
          }}
        >
          <div style={{
            fontSize:'17px',
            fontWeight:'700'
          }}>Access Restriction</div>
          {/* <div
            style={{
              height: "40px",
              width: "389px",
            }}
          >
            <input type="text" placeholder="Search..." />
          </div> */}
        </div>
        <div>
          {/* <Button
            style={{
              color: "black",
              backgroundColor: "#D9D9D9",
            }}
          >
            Export{" "}
          </Button> */}
        </div>
      </span>


      <Box
        sx={{
           color: "black",
        }}
      >
        <div></div>
        <Paper sx={{ overflow: "hidden" }}>
        <div style={{display:'flex',
  justifyContent:'start'
  }}>
  <TextField
  label="Search"
  value={searchQuery2}
  onChange={(e)=>setSearchQuery2(e.target.value)}
  variant="outlined"
  margin="normal"
  inputProps={{
  style:{
  width:'200px',
  
  
  }
  }}
  
  />
  </div>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns3.map((column3) => (
                    <TableCell
                      key={column3.id}
                      align={column3.align}
                      style={{ minWidth: column3.minWidth }}
                    >
                      {column3.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
           
              <TableBody>
                {slicedRows2.map((row, index) => (
                  <TableRow key={row.email}>
                    {columns3.map((column) => (
                      <TableCell key={column.id}>
                        {column.id === "User" ? row.user_id : null}
                        {column.id === "Email" ? row.email : null}
                        {column.id === "Action" ? (
                          <DeleteIcon
                            onClick={() => handleApplyClick(row.user_id)}
                            style={{ cursor: 'pointer' }}
                          />
                        ) : null}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
             
              </TableBody>



            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredRows2.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
         
        </Paper>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {isPopupVisible && (
          <div
            style={{
              height: "100px",
              width: "450px",
              backgroundColor: "lightpink",
              border: "1px solid gray",
              padding: "10px",
              zIndex: "1000",
            }}
          >
            <div style={{}} onClick={() => setPopupVisible(false)}>
              <CloseIcon />{" "}
            </div>
            <div>Restricted access to the selected users</div>
            <hr
              style={{
                borderTop: "2px solid  #black",
              }}
            />
            <div
              style={{
                fontSize: "10px",
              }}
            >
              The following users have found that they are uploading
              inappropriate data which is not relevant, they have repeated it
              many times. Which would affect the organization.{" "}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
