import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Modal,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Link, Route } from "react-router-dom";
import axios from "axios";
import { navigate } from "@reach/router";
import Signin from "./signin";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { loaderOff, loaderOn, openSnackbar } from "../redux/actions";
import Snackbar from "../components/snackbar";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function ForgotPassword() {
  const [formValues, setFormValues] = useState({
    email: "",
    otp: "",
    password: "",
    confirm_password: "",
  });

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (e: any) => {
    e.preventDefault();
  };

  const [apiSuccess, setApiSuccess] = useState(null);
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const dispatch = useDispatch();

  const handleOtp = async () => {
    if (!emailRegex.test(formValues.email)) {
      setEmailError("Please enter a valid email");
      return;
    }
    dispatch(loaderOn());
    try {
      const res = await axios.post(
        "https://dev-admin.tribe.sn/admin/forgot-password",
        null,
        {
          params: {
            email: formValues.email,
          },
        }
      );
      if (formValues.email != "" && res.status === 200) {
        setApiSuccess(res.data);
        dispatch(openSnackbar("OTP sent successfully", "green"));
      } else {
        dispatch(openSnackbar("User not exist", "red"));
      }
      dispatch(loaderOff());
    } catch (error) {
      // Handle the error
      dispatch(loaderOff());
    }
  };

  const handleUpdatePassword = async () => {
    if (
      formValues.otp === "" ||
      formValues.password === "" ||
      formValues.confirm_password === ""
    ) {
      dispatch(openSnackbar("Please fill in all fields", "red"));
      return;
    }

    if (formValues.password !== formValues.confirm_password) {
      setPasswordError("Password mismatch");
      return;
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%^&*])[A-Za-z\d@$!%^&*]{8,}$/;
    if (!passwordRegex.test(formValues.password)) {
      setPasswordError(
        "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one special character and one number."
      );
      return;
    }
    dispatch(loaderOn());
    try {
      const res = await axios.post(
        "https://dev-admin.tribe.sn/admin/change-password",
        null,
        {
          params: {
            otp: formValues.otp,
            password: formValues.password,
            confirm_password: formValues.confirm_password,
            email: formValues.email,
          },
        }
      );
      if (res.status === 200) {
        // handleOpen();
        window.location.href = '/';
        setApiSuccess(null);
        setFormValues({
          email: "",
          otp: "",
          password: "",
          confirm_password: "",
        });
      }
      if (res.status === 201) {
        handleOpenInvalid();
      }
      dispatch(loaderOff());
    } catch (error) {
      console.log("error" + error);
      dispatch(loaderOff());
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "password" || name === "confirm_password") {
      setPasswordError("");
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setEmailError("");
    setPasswordError("");
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#8E47E6",
    color: "#FFFFFF",
    textTransform: "none",
    boxShadow: 24,
    p: 3,
    borderRadius: "12px",
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openInvalid, setOpenInvalid] = React.useState(false);
  const handleOpenInvalid = () => setOpenInvalid(true);
  const handleCloseInvalid = () => setOpenInvalid(false);

  return (
    <Box
      sx={{
        width: "45%",
        height:'50%',
        display: "flex",
        flexDirection: "column",
        marginTop: "8%",
        marginLeft: "25%",
        padding: "10px 20px 20px 20px",
        border: "1px solid #8E47E640",
        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
        borderRadius: "14px",
      }}
    >
      {
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <ClearIcon
              style={{ margin: "-10px -10px 0px 10px", float: "right" }}
              onClick={handleClose}
            />
            <Typography
              variant="h6"
              component="h2"
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              Password Changed Successfully...
            </Typography>
            <Link
              to="/sign_in"
              style={{
                textDecoration: "none",
                color: "#FFFFFF",
                padding: "5px 8px 8px 8px",
                float: "left",
                borderRadius: "5px",
                border: "1px solid #000000",
              }}
            >
              Sign In
            </Link>
          </Box>
        </Modal>
      }
      {
        <Modal open={openInvalid} onClose={handleCloseInvalid}>
          <Box sx={{width:'20%',...style}}>
            <ClearIcon
              style={{ margin: "-10px -10px 0px 10px", float: "right" }}
              onClick={handleCloseInvalid}
            />
            <Typography
              variant="h6"
              component="h2"
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              Invalid User...
            </Typography>
          </Box>
        </Modal>
      }
      <div style={{}}>
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", paddingTop: "20px" }}
        >
          Forgot Password
        </Typography>
        <Typography variant="body1" style={{ paddingTop: "10px" }}>
          Enter your email address below to reset your password.
        </Typography>
        <div>
          <div
            style={{ fontSize: "15px", paddingTop: "10px", fontWeight: "bold" }}
          >
            Email
          </div>
          <TextField
            variant="outlined"
            autoComplete="off"
            style={{ paddingTop: "10px" }}
            name="email"
            value={formValues.email}
            onChange={handleChange}
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "1px solid black",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            InputProps={
              {
                // disableUnderline: true,
              }
            }
          />
          {emailError && <p style={{ color: "red" }}>{emailError}</p>}
        </div>
        {apiSuccess != null && (
          <>
            <div
              style={{
                fontSize: "15px",
                paddingTop: "10px",
                fontWeight: "bold",
              }}
            >
              Otp
            </div>
            <div>
              <TextField
                variant="outlined"
                autoComplete="off"
                style={{ paddingTop: "10px" }}
                name="otp"
                value={formValues.otp}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid black",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              style={{
                fontSize: "15px",
                paddingTop: "10px",
                fontWeight: "bold",
              }}
            >
              New Password
            </div>
            <div>
              <TextField
                variant="outlined"
                autoComplete="off"
                style={{ paddingTop: "10px", width: "230px" }}
                name="password"
                value={formValues.password}
                onChange={handleChange}
                type={showNewPassword ? "text" : "password"}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid black",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        sx={{ color: "#000000" }}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div
              style={{
                fontSize: "15px",
                paddingTop: "10px",
                fontWeight: "bold",
              }}
            >
              Confirm Password
            </div>
            <div>
              <TextField
                variant="outlined"
                autoComplete="off"
                style={{ paddingTop: "10px", width: "230px" }}
                name="confirm_password"
                value={formValues.confirm_password}
                onChange={handleChange}
                type={showConfirmPassword ? "text" : "password"}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid black",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        sx={{ color: "#000000" }}
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {passwordError && (
                <>
                  <p style={{ color: "red" }}>{passwordError}</p>
                </>
              )}
            </div>
          </>
        )}
      </div>
      {apiSuccess != null ? (
        <div style={{ paddingTop: "10px" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#8E47E6",
              textTransform: "none",
              paddingTop: "10px",
              color: "white",
              "&:hover": {
                backgroundColor: "#6a1b9a",
              },
            }}
            onClick={handleUpdatePassword}
          >
             Reset Password
          </Button>
        </div>
      ) : (
        <div style={{ paddingTop: "10px" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#8E47E6",
              textTransform: "none",
              color: "white",
              "&:hover": {
                backgroundColor: "#6a1b9a",
              },
            }}
            onClick={handleOtp}
          >
            Generate otp
          </Button>
        </div>
      )}
      <Typography variant="body2" style={{ paddingTop: "10px" }}>
        Remember your password?{" "}
        <Link to="/sign_in" style={{ textDecoration: "none" }}>
          Sign In
        </Link>
      </Typography>
    </Box>
  );
}
