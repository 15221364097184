// import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import "./App.css";
import Snackbar from "./components/snackbar";
import Signin from "./pages/signin";
import HomePage from "./pages/homepage";
import BonusPage from "./pages/bonuspage";
import NavBar from "./components/navbar";
import EndUser from "./pages/enduser";
import BackOffice from "./pages/backoffice";
import Dashboard from "./pages/dashboard";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Token from "./pages/token";
import Location from "./pages/location";
import Bonus from "./pages/bonus";
import Achievements from "./pages/achievements";
import Gifts from "./pages/gifts";
import User_Location from "./pages/user_location";
import User_token from "./pages/user_token";
import User_Access from "./pages/user_access";
import Profile from "./pages/profile";
import Create_User from "./pages/create_user";
import Create_Role from "./pages/create_role";
import React from "react";
import Location_History from "./pages/location_history";
import Forgotpassword from "./pages/forgotpassword";
import Features from "./pages/features";

function App() {
  const [isAuthenticated, setIsAuthenticated]: any = useState("");

  const isLoading = useSelector((state: any) => state.loading.isLoading);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    setIsAuthenticated(sessionStorage.getItem("isLoggedIn"));
    setOpen(isLoading);
  }, [isLoading]);

  return (
    <div className="App">
      <Router>
        <Box
          sx={{
            fontSize: "14px",
            margin: "0 auto",
            maxWidth: isAuthenticated === "true" ? "1440px" : "100%",
          }}
        >
          {/* <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop> */}
          <Snackbar />
          {isAuthenticated == "true" &&
            window.location.pathname !== "/signin" && <NavBar />}
          <Routes>
            <Route path="/" element={<Signin />} />
            <Route path="/sign_in" element={<Signin />} />
          </Routes>
          <Box marginLeft={"260px"}>
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/homepage" element={<HomePage />} />
              <Route path="/token" element={<Token />} />
              <Route path="/location" element={<Location />} />
              <Route path="/location_history" element={<Location_History />} />
              <Route path="/bonus" element={<Bonus />} />
              <Route path="/achievements" element={<Achievements />} />
              <Route path="/gifts" element={<Gifts />} />
              <Route path="/user_token" element={<User_token />} />
              <Route path="/user_access" element={<User_Access />} />
              <Route path="/user_location" element={<User_Location />} />
              <Route path="/bonuspage" element={<BonusPage />} />
              <Route path="/enduser" element={<EndUser />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/create_user" element={<Create_User />} />
              <Route path="/create_role" element={<Create_Role />} />
              <Route path="/backoffice" element={<BackOffice />} />
              <Route path="/features" element={<Features />} />
            </Routes>
          </Box>
          <Routes>
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          </Routes>
        </Box>
      </Router>
    </div>
  );
}

export default App;
