import {
  Box,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useFeatureForm } from "../custom_forms/AchievementForm";
import { containsOnlyCharacters, numberRegex } from "../CustomRegex/CustomRegex";
import { loaderOff, loaderOn, openSnackbar } from "../redux/actions";
import {
  createFeature,
  deletefeatures,
  getFeature,
  getActiveFeature,
  updateFeature,
  getfeaturebyid,
} from "../utilities/backofficeservice";

import CustomTextField from "../customtextfield/CustomTextField";

import { getUser } from "../utilities/adminservice";
import CommonDialog from "../commondialog/confirm";
import { CustomPrimaryButton } from "../customtextfield/CustomButton";
import { useDispatch } from "react-redux";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid white",
  borderRadius: "18px",
  p: 3,
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Data4 {
  id: number;
  feature_name: string;
  feature_description: string;
}
interface Column4 {
  id: "SerialNumber" | "action" | "feature_name" | "feature_description";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}
const columns4: readonly Column4[] = [
  { id: "SerialNumber", label: "Sl No", color: "#8E47E6" },
  { id: "feature_name", label: "Name", color: "#8E47E6" },
  {
    id: "feature_description",
    label: "Features Description",
    color: "#8E47E6",
  },
  { id: "action", label: "Action", color: "#8E47E6" },
];

interface Data6 {
  id: number;
  feature_name: string;
  feature_description: string;
}
interface Column6 {
  id: "SerialNumber" | "id" | "feature_name" | "feature_description";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}
const columns6: readonly Column6[] = [
  { id: "SerialNumber", label: "Sl No", color: "#8E47E6" },
  { id: "feature_name", label: "Name", color: "#8E47E6" },
  {
    id: "feature_description",
    label: "Feature  Description ",
    color: "#8E47E6",
  },
];

export default function Features() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery3, setSearchQuery3] = useState("");
  const [rows3, setRows3] = useState<Data4[]>([]);
  const [featureId, setFeatureId] = React.useState(null);
  const [rows5, setRows5] = useState<Data6[]>([]);
  const [searchQuery5, setSearchQuery5] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const { formValues, setFormValues } = useFeatureForm();
  const [showContent, setShowContent] = useState(false);
  const [openDialog, setopenDialog] = React.useState(false);
  const [selectedFeatureId, setSelectedFeatureId] = useState<number | null>(
    null
  );
  const handleCloseDialog = () => {
    setopenDialog(false);
    setShowContent(false);
  };

  const handleChangee = (e: { name: string; value: string } | any) => {
    const { name, value } = e.target || e;

    if (
      name === "id" ||
      name === "feature_name" ||
      name === "feature_description"
    ) {
      const isValidValue: boolean = containsOnlyCharacters.test(value);

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: {
          ...prevFormValues[name],
          value,
          error: !isValidValue,
        },
      }));
    } else {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: {
          ...prevFormValues[name],
          value,
          error: value === "" ? true : false,
        },
      }));
    }
  };

  let validateForm = (type: any) => {
    let invalidSubmit = false;
    const updatedFormValues = { ...formValues };
    // Validate other fields
    const formFields = ["feature_name", "feature_description"];
    formFields.forEach((fieldName) => {
      const value = formValues[fieldName].value;
      const isValid = containsOnlyCharacters.test(value);
      updatedFormValues[fieldName] = {
        ...formValues[fieldName],
        error: !isValid,
      };
      invalidSubmit = invalidSubmit || !isValid;
    });
    // Check for required fields
    if (type === "feature-add") {
      formFields.forEach((fieldName) => {
        const value = formValues[fieldName].value;
        if (value === "") {
          updatedFormValues[fieldName] = {
            ...formValues[fieldName],
            error: true,
          };
          invalidSubmit = true;
        }
      });
    }
    // Update the state after the loop
    setFormValues(updatedFormValues);
    return invalidSubmit;
  };

  const filteredRows3 = rows3.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery3.toLowerCase())
    )
  );
  const filteredRows5 = rows5.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery5.toLowerCase())
    )
  );
  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [openFeature, setOpenFeature] = React.useState(false);
  const handleOpenFeature = (featureId: any) => {
    setFeatureId(featureId);
    setOpenFeature(true);
  };

  const handleClose4 = () => {
    setFeatureId(null);
    setOpenFeature(false);
    Object.keys(formValues).forEach((key: any) => {
      formValues[key].value = "";
    });
    setFormValues({
      id: {
        value: "",
        error: false,
        errorMessage: "Please enter feature id",
      },
      feature_name: {
        value: "",
        error: false,
        errorMessage: "Please enter valid feature name",
      },
      feature_description: {
        value: "",
        error: false,
        errorMessage: "Please enter valid feature description",
      },
    });
  };

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => {
    setOpen1(false);
    setFormValues({
      id: {
        value: "",
        error: false,
        errorMessage: "Please enter feature id",
      },
      feature_name: {
        value: "",
        error: false,
        errorMessage: "Please enter valid feature name",
      },
      feature_description: {
        value: "",
        error: false,
        errorMessage: "Please enter valid feature description",
      },
    });
  }

  const dispatch = useDispatch();

  const handleAutoFillFeature = async (featureId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn());
    try {
      const res = await getfeaturebyid(featureId);
      if (res != null) {
        formValues.feature_name.value = res.feature_name;
        formValues.feature_name.error = false;
        formValues.feature_description.value = res.feature_description;
        formValues.feature_description.error = false;
        console.log(res);
        handleOpenFeature(featureId);
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  const handleEditFeature = (featureId: any) => {
    handleAutoFillFeature(featureId);
    console.log(`Edit  ${featureId}`);
  };

  const handleDeleteFeatures = (featureId: any) => {
    // handleDeleteFeatures(featureId);
    setFeatureId(featureId);
    setShowPopup(true);
    console.log(`Delete   ${featureId}`);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleDeleteFeature = async (featureId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    const formData = {
      active_status: "Yes",
    };
    dispatch(loaderOn());
    try {
      const res = await deletefeatures(featureId, formData);
      if (res != null) {
        fetchFeatures();
        togglePopup();
        console.log("feature status updated sucessfully");
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  const fetchFeatures = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn());
    try {
      const response = await getActiveFeature();
      if (response && Array.isArray(response)) {
        const frontendData = response.map((backendRow: any) => ({
          id: backendRow.id,
          feature_name: backendRow.feature_name,
          feature_description: backendRow.feature_description,
        }));

        setRows3(frontendData);
        console.log(frontendData);
        dispatch(loaderOff());
      } else {
        console.error("Invalid or empty response from getAchievementsActive");
      }
    } catch (error) {
      console.error("Error fetching achievements data:", error);
      dispatch(loaderOff());
    }
  };

  const fetchFeatures1 = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn());
    try {
      const response = await getFeature();
      if (response && Array.isArray(response)) {
        const frontendData = response.map((backendRow: any) => ({
          id: backendRow.id,
          feature_name: backendRow.feature_name,
          feature_description: backendRow.feature_description,
        }));
        setRows5(frontendData);
        console.log(frontendData);
        dispatch(loaderOff());
      } else {
        console.error("Invalid or empty response from getAchievementsActive");
      }
    } catch (error) {
      console.error("Error fetching achievements data:", error);
      dispatch(loaderOff());
    }
  };

  const handleAddFeature = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    const formData = {
      feature_name: formValues.feature_name.value,
      feature_description: formValues.feature_description.value,
    };
    dispatch(loaderOn());
    try {
      const res = await createFeature(formData);
      if (res != null) {
        Object.keys(formValues).forEach((key: any) => {
          formValues[key].value = "";
        });
        handleClose1();
        fetchFeatures();
        fetchFeatures1();
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  const handleEdittFeature = async (featureId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    const invalidSubmit = validateForm("feature-add");
    if (!invalidSubmit) {
      const formData = {
        feature_name: formValues.feature_name.value,
        feature_description: formValues.feature_description.value,
      };
      dispatch(loaderOn());
      try {
        const res = await updateFeature(featureId, formData);
        if (res != null) {
          console.log("updated sucessfully");
          Object.keys(formValues).forEach((key: any) => {
            formValues[key].value = "";
          });
          handleClose4();
          fetchFeatures();
          fetchFeatures1();
          dispatch(loaderOff());
        }
      } catch (error) {
        console.log(error);
        dispatch(loaderOff());
      }
    }
  };

  useEffect(() => {
    fetchFeatures();
    fetchFeatures1();
  }, []);

  const CustomTabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTabs-indicator": {
      backgroundColor: "#8E47E6",
    },
  }));

  // Restriction
  const [userId, setUserId] = useState(0);
  let temp: number;
  const [features_read, setFeatureRead] = useState(false);
  const [features_write, setFeatureWrite] = useState(false);

  const getRoleData = async () => {
    const res: any = await getUser();

    if (res && res.role_type) {
      if (
        res.role_type.features_read !== undefined &&
        res.role_type.features_write !== undefined
      ) {
        setFeatureRead(res.role_type.features_read);
        setFeatureWrite(res.role_type.features_write);
      }
    }
  };

  const filteredColumns = features_write ? columns4 : columns4.filter(column => column.id !== 'action');

  const getLoginUserData = async () => {
    const res: any = await getUser();
    if (res != null) {
      temp = res.id;
      setUserId(temp);
    }
    console.log(temp);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getLoginUserData();
      getRoleData();
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ width: "100%", marginTop: "40px" }}>
      <Box>
        <CustomTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Features"
            {...a11yProps(0)}
            style={{
              color: "#8E47E6",
              fontWeight: "600",
              textTransform: "none",
              fontSize: "16px",
            }}
          />
          <Tab
            label="Features History"
            {...a11yProps(1)}
            style={{
              color: "#8E47E6",
              fontWeight: "600",
              textTransform: "none",
              fontSize: "16px",
            }}
          />
        </CustomTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontFamily: "",
                  fontSize: "16px",
                  fontWeight: "700",
                  paddingRight: "43px",
                }}
              >
                Features
              </Typography>
              <span>
                {" "}
                <TextField
                  // label="Search"
                  value={searchQuery3}
                  onChange={(e) => setSearchQuery3(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#140026" }} />
                      </InputAdornment>
                    ),
                    style: {
                      width: "300px",
                      boxShadow: "0px 4px 4px 0px rgba(142, 71, 230, 0.25)",
                      borderRadius: "6px",
                    },
                  }}
                />
              </span>
            </div>
            {features_write === false ? (
              ""
            ) : (
              <div>
                <CustomPrimaryButton
                  onClick={handleOpen1}
                  //   disabled={!feature_read && !feature_write}
                  startIcon={<AddIcon />}
                >
                  Add Feature
                </CustomPrimaryButton>
              </div>
            )}
          </div>

          <Box
            sx={{
              color: "black",
              marginTop: "30px",
            }}
          >
            <Paper sx={{ overflow: "hidden" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                }}
              ></div>
              <TableContainer
                sx={{
                  maxHeight: 440,
                  borderRadius: "18px",
                  boxShadow: "0px 4px 12px 0px #8E47E640",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}>
                      {columns4.map((column4) => (
                        <TableCell
                          key={column4.id}
                          align={column4.align}
                          style={{
                            minWidth: column4.minWidth,
                            color: column4.color,
                          }}
                        >
                          {/* {column4.label} */}
                          {features_write || column4.id !== 'action' ? column4.label : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows3
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.feature_description}
                          >
                            {/* {columns4.map((column4) => { */}
                            {/* const value = row[column4.id]; */}
                            {filteredColumns.map((column4, columnIndex) => {
                              const value =
                                column4.id === "SerialNumber"
                                  ? index + 1
                                  : row[column4.id as keyof Data6];
                              return (
                                <TableCell
                                  key={column4.id}
                                  align={column4.align}
                                >
                                  {column4.id === "action" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      {features_write === true ? (
                                        <p
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleEditFeature(row.id)
                                          }
                                        >
                                          <CreateIcon
                                            style={{ color: "#5151ED" }}
                                          />
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            cursor: "not-allowed",
                                            filter: "blur(1px)",
                                          }}
                                        >
                                          <CreateIcon
                                            style={{ color: "#5151ED" }}
                                          />
                                        </p>
                                      )}
                                      {features_write === true ? (
                                        <p
                                          onClick={() => {
                                            setSelectedFeatureId(row.id);
                                            setopenDialog(true);
                                          }}
                                          // onClick={() =>
                                          //   handleDeleteFeature(row.feature_id)
                                          // }
                                        >
                                          <DeleteIcon
                                            style={{ color: "#DF2525" }}
                                          />
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            cursor: "not-allowed",
                                            filter: "blur(1px)",
                                          }}
                                        >
                                          <DeleteIcon
                                            style={{ color: "#DF2525" }}
                                          />
                                        </p>
                                      )}
                                    </div>
                                  ) : column4.format &&
                                    typeof value === "number" ? (
                                    column4.format(value)
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <CommonDialog
                open={openDialog}
                handleClose={handleCloseDialog}
                handleConfirm={() => {
                  handleDeleteFeature(selectedFeatureId);
                  handleCloseDialog();
                }}
                contentText="Are you sure to delete feature?"
              />
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredRows3.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>

          <Modal
            open={openFeature}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={
                  {
                    // display: "flex",
                    // flexDirection:'row'
                  }
                }
                onClick={handleClose4}
              >
                <div style={{ float: "left" }}>
                  {" "}
                  <h1>Update Feature</h1>
                </div>

                <div style={{ float: "right" }}>
                  <CloseIcon />
                </div>
              </div>

              <Grid container spacing={2} style={{ marginTop: "5px" }}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Feature Names
                    <CustomTextField
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="feature_name"
                    />
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Feature Description
                    <CustomTextField
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="feature_description"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid
                  item
                  lg={6}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <CommonDialog
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    handleConfirm={() => {
                      handleEdittFeature(featureId);
                      handleCloseDialog();
                    }}
                    contentText="Are you sure to update feature?"
                  />

                  <CustomPrimaryButton
                    style={{
                      marginTop: "20px",
                    }}
                    onClick={() => {
                      setopenDialog(true);
                    }}
                  >
                    Update
                  </CustomPrimaryButton>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <Modal
            open={open1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
                onClick={handleClose1}
              >
                <CloseIcon />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "Left",
                }}
              >
                <h1>Add Features</h1>
              </div>
              <Grid container spacing={2} style={{ marginTop: "5px" }}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Feature Name
                    <CustomTextField
                      placeholder="Enter your Feature Name"
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="feature_name"
                    />
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Feature Description
                    <CustomTextField
                      placeholder="Enter Your Feature Description"
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="feature_description"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid
                  item
                  lg={6}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <CommonDialog
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    handleConfirm={() => {
                      handleAddFeature();
                      handleCloseDialog();
                    }}
                    contentText="Are you sure to add feature?"
                  />

                  <CustomPrimaryButton
                    style={{
                      padding: "15px 25px",
                      marginTop: "15px",
                    }}
                    onClick={() => {
                      const invalidSubmit = validateForm("feature-add");
                      if (!invalidSubmit) {
                        setopenDialog(true);
                      }
                    }}
                  >
                    Add
                  </CustomPrimaryButton>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontFamily: "",
                  fontSize: "16px",
                  fontWeight: "700",
                  paddingRight: "43px",
                }}
              >
                Feature History
              </Typography>
              <span>
                <TextField
                  // label="Search"
                  value={searchQuery5}
                  onChange={(e) => setSearchQuery5(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#140026" }} />
                      </InputAdornment>
                    ),
                    style: {
                      width: "300px",
                      boxShadow: "0px 4px 4px 0px rgba(142, 71, 230, 0.25)",
                      borderRadius: "6px",
                    },
                  }}
                />
              </span>
            </div>
          </div>

          <Box
            sx={{
              color: "black",
              marginTop: "30px",
            }}
          >
            <div></div>
            <Paper sx={{ overflow: "hidden" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                }}
              ></div>
              <TableContainer
                sx={{
                  maxHeight: 440,
                  borderRadius: "18px",
                  boxShadow: "0px 4px 12px 0px #8E47E640",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}>
                      {columns6.map((column6) => (
                        <TableCell
                          key={column6.id}
                          align={column6.align}
                          style={{
                            minWidth: column6.minWidth,
                            color: column6.color,
                          }}
                        >
                          {column6.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows5
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.feature_description}
                          >
                            {/* {columns6.map((column6) => {
                              const value = row[column6.id]; */}
                            {columns6.map((column6, columnIndex) => {
                              const value =
                                column6.id === "SerialNumber"
                                  ? index + 1
                                  : row[column6.id as keyof Data6];
                              return (
                                <TableCell
                                  key={column6.id}
                                  align={column6.align}
                                >
                                  {column6.format && typeof value === "number"
                                    ? column6.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredRows5.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Box>
      </CustomTabPanel>
    </Box>
  );
}
