import internalApi from "./internalApi"

export async function createAdmin(data: any) {
    try {
      const response = await internalApi.post(`admin`, data)
      return response.data
    } catch (error) {
      return error
    }
  }

  export async function signInUser(data: any) {
    try {
      const response = await internalApi.post('/admin/sign-in', data)
      if(response.status == 200) {
        console.log(response.data)
        return response.data
      }else {
        return null
      }
     
    } catch (error) {
      return null
    }
  }

  export async function getUser() {
    try {
      const response = await internalApi.get('/admin')
      return response.data
    } catch (error) {
      return error
    }
  }

  export async function createRole(formData:any) {
    try {
      const response = await internalApi.post(`roles`, formData)
      return response.data
    } catch (error) {
      return error
    }
  }
  export async function getRole() {
    try {
      const response = await internalApi.get(`roles`)
      return response.data
    } catch (error) {
      return error
    }
  }

  export async function getRoleById(id:any) {
    try {
      const response = await internalApi.get(`roles/${id}`,id)
      return response.data
    } catch (error) {
      return error
    }
  }