import {
  Box,
  Button,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import { useAchievementForm } from "../custom_forms/AchievementForm";
import {
  containsOnlyCharacters,
  emailRegex,
  numberRegex,
  passwordRegex,
} from "../CustomRegex/CustomRegex";
import { loaderOff, loaderOn, logout, openSnackbar } from "../redux/actions";
import {
  createAchievement,
  getAchievements,
  getAchievementsActive,
  getAllAchievements,
  getactivegifts,
  updateAchievements,
  updateAchievements1,
} from "../utilities/backofficeservice";

import CustomTextField from "../customtextfield/CustomTextField";

import { getUser } from "../utilities/adminservice";

import CommonDialog from "../commondialog/confirm";
import { CustomPrimaryButton } from "../customtextfield/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "../redux/store";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid white",
  borderRadius: "18px",

  // boxShadow: 24,
  p: 3,
};

const overlayStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0)", // Semi-transparent overlay
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 10000,
};

const popupStyle: React.CSSProperties = {
  position: "relative",
  background: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
};

const closeIconStyle: React.CSSProperties = {
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
};
const popupContentStyle: React.CSSProperties = {
  textAlign: "center",
  marginTop: "30px", // Adjust the margin to make space for the close icon
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Column3 {
  id:
    | "Achievement"
    | "Achievement_Description"
    | "Achievement_Criteria"
    | "achievement_id"
    | "level"
    | "gift_name_name"
    | "no_of_tokens";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}
// let gift: string;

const columns3: readonly Column3[] = [
  { id: "Achievement", label: "Achievement", color: "#8E47E6" },
  {
    id: "Achievement_Description",
    label: "Achievement Description",
    color: "#8E47E6",
  },
  {
    id: "Achievement_Criteria",
    label: "Achievement Criteria ",
    color: "#8E47E6",
  },
  {
    id: "level",
    label: "Level",
    color: "#8E47E6",
  },
  {
    id: "gift_name_name",
    label: "Gift Name",
    color: "#8E47E6",
  },
  {
    id: "no_of_tokens",
    label: "Token Count Threshold",
    color: "#8E47E6",
  },
  {
    id: "achievement_id",
    label: "Action",
    color: "#8E47E6",
  },
];

interface Data3 {
  Achievement: string;
  Achievement_Description: string;
  Achievement_Criteria: number;
  achievement_id: number;
  level: string;
  gift_name_name: string;
  no_of_tokens: number;
}

interface Column5 {
  id:
    | "Sl_No"
    | "Achievements"
    | "Achievements_Description"
    | "Achievement_Criteria"
    | "level"
    | "gift_name_name"
    | "no_of_tokens";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}

const columns5: readonly Column5[] = [
  // { id: "Sl_No", label: "Sl. No", color: "#8E47E6" },
  { id: "Achievements", label: "Achievements", color: "#8E47E6" },
  {
    id: "Achievements_Description",
    label: "Achievements Description ",

    format: (value: number) => value.toLocaleString("en-US"),
    color: "#8E47E6",
  },
  {
    id: "Achievement_Criteria",
    label: "Achievement Criteria",

    format: (value: number) => value.toLocaleString("en-US"),
    color: "#8E47E6",
  },
  { id: "level", label: "Level", color: "#8E47E6" },
  { id: "gift_name_name", label: "Gift Name", color: "#8E47E6" },
  {
    id: "no_of_tokens",
    label: "Token Count Threshold",
    color: "#8E47E6",
  },
];

interface Data5 {
  Sl_No: string;
  Achievements: string;
  Achievements_Description: number;
  Achievement_Criteria: number;
  level: string;
  gift_name_name: string;
  no_of_tokens: number;
}
export default function Achievements() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows2, setRows2] = useState<Data3[]>([]);
  const [searchQuery2, setSearchQuery2] = useState("");
  const [searchQuery4, setSearchQuery4] = useState("");

  const [achievementId, setAchievementId] = React.useState(null);
  const [rows4, setRows4] = useState<Data5[]>([]);


  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isTokenExpired = useSelector((state: any) => state.auth.isTokenExpired);
  console.log("syed" + isLoggedIn);
  useEffect(() => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken || isTokenExpired) {
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    } else if (!isLoggedIn) { 
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    }
  }, [isLoggedIn, isTokenExpired]);












  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    Object.keys(formValues).forEach((key: any) => {
      formValues[key].value = "";
    });
    setV1(false)
    setV2(false)
    setSelectedValue("");
    setSelectedValue1("");
    setOpen(false);
    setFormValues({
      achievement_name: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Achievement name",
      },
      achievement_description: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Achievement description",
      },
  
      achievement_criteria: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Achievement criteria",
      },
      level: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Achievement level",
      },
      gift_name: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Gift name",
      },
      no_of_tokens: {
        value: "",
        error: false,
        errorMessage: "Please enter token",
      },
    })
  };
  const [showPopup, setShowPopup] = useState(false);

  const [showContent, setShowContent] = useState(false);
  const [openDialog, setopenDialog] = React.useState(false);
  const [selectedAchievementId, setSelectedAchievementId] = useState<
    number | null
  >(null);
  // const [selectedAchievementId, setSelectedAchievementId] = useState<
  //   number | null
  // >(null);

  const handleCloseDialog = () => {
    setopenDialog(false);
    setShowContent(false);
  };
  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredRows2 = rows2.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery2.toLowerCase())
    )
  );
  const filteredRows4 = rows4.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery4.toLowerCase())
    )
  );

  const { formValues, setFormValues } = useAchievementForm();

  // const handleChangee = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   if (name === "password") {
  //     const password: string = value;
  //     const isValidPassword: boolean = passwordRegex.test(password);
  //     setFormValues({
  //       ...formValues,
  //       [name]: {
  //         ...formValues[name],
  //         value,
  //         error: !isValidPassword,
  //       },
  //     });
  //   } else if (name === "email") {
  //     const email: string = value;
  //     const isValidEmail: boolean = emailRegex.test(email);
  //     setFormValues({
  //       ...formValues,
  //       [name]: {
  //         ...formValues[name],
  //         value,
  //         error: !isValidEmail,
  //       },
  //     });
  //   } else if (name === "achievement_name") {
  //     const achievement_name: string = value;
  //     const isValidAchievementName: boolean =
  //       containsOnlyCharacters.test(achievement_name);
  //     setFormValues({
  //       ...formValues,
  //       [name]: {
  //         ...formValues[name],
  //         value,
  //         error: !isValidAchievementName,
  //       },
  //     });
  //   } else if (name === "achievement_description") {
  //     const achievement_description: string = value;
  //     const isValidAchievementDescription: boolean =
  //       containsOnlyCharacters.test(achievement_description);
  //     setFormValues({
  //       ...formValues,
  //       [name]: {
  //         ...formValues[name],
  //         value,
  //         error: !isValidAchievementDescription,
  //       },
  //     });
  //   } else if (name === "achievement_criteria") {
  //     const achievement_criteria: string = value;
  //     const isValidAchievementCriteria: boolean =
  //       containsOnlyCharacters.test(achievement_criteria);
  //     setFormValues({
  //       ...formValues,
  //       [name]: {
  //         ...formValues[name],
  //         value,
  //         error: !isValidAchievementCriteria,
  //       },
  //     });
  //   } else if (name === "level") {
  //     const isValidLevel: boolean = containsOnlyCharacters.test(value);
  //     setFormValues({
  //       ...formValues,
  //       [name]: {
  //         ...formValues[name],
  //         value,
  //         error: value === "" ? true : false,
  //       },
  //     });
  //   } else {
  //     setFormValues({
  //       ...formValues,
  //       [name]: {
  //         ...formValues[name],
  //         value,
  //         error: value === "" ? true : false,
  //       },
  //     });
  //   }
  // };

  const handleChangee = (e: { name: string; value: string } | any) => {
    // const { name, value } = e.target || e;
    const { name, value } = e.target;

    if (
      name === "achievement_name" ||
      name === "achievement_description" ||
      name === "achievement_criteria" ||
      name === "level" ||
      name === "gift_name"
    ) {
      const isValidValue: boolean = containsOnlyCharacters.test(value);

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: {
          ...prevFormValues[name],
          value,
          // error: !isValidValue,
          error: !value.trim(),
        },
      }));
    } else if (name === "no_of_tokens") {
      const isValidInteger: boolean = /^\d+$/.test(value);

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: {
          ...prevFormValues[name],
          value,
          error: !isValidInteger,
        },
      }));
    } else {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: {
          ...prevFormValues[name],
          value,
          error: value === "" ? true : false,
        },
      }));
    }
  };
  let validateForm = (type: any) => {
    let invalidSubmit = false;
    const updatedFormValues = { ...formValues }; // Create a copy to accumulate changes
  
    // Validate other fields
    const formFields = [
      "achievement_name",
      "achievement_description",
      "achievement_criteria",
      "no_of_tokens",
    ];

     // Check for dropdown values
  const isValidDropdown = selectedValue !== "" && selectedValue1 !== "";
  if (!isValidDropdown) {
    invalidSubmit = true;
  }

    formFields.forEach((fieldName) => {
      const value = formValues[fieldName].value;
      // const isValid = numberRegex.test(value);
      let isValid = true;

      if (fieldName === "no_of_tokens") {
        isValid = /^\d+$/.test(value); // Check if value is an integer
      } else {
        isValid = containsOnlyCharacters.test(value);
      }
  
      updatedFormValues[fieldName] = {
        ...formValues[fieldName],
        error: !isValid,
      };
  
      invalidSubmit = invalidSubmit || !isValid;

      

    });
  
    // Check for required fields
    if (type === "achievement-add") {
      formFields.forEach((fieldName) => {
        const value = formValues[fieldName].value;
        if (value === "" || value === null) {  // Include null in the condition
          updatedFormValues[fieldName] = {
            ...formValues[fieldName],
            error: true,
          };
          invalidSubmit = true;
        }
      });
    }
  
    // Update the state after the loop
    setFormValues(updatedFormValues);
  
    return invalidSubmit;
  };
  

  const [openAchievement, setOpenAchievement] = React.useState(false);

  const handleOpenAchievement = (achievementId: any) => {
    setAchievementId(achievementId);
    setOpenAchievement(true);
  };

  const handleClose3 = () => {
    Object.keys(formValues).forEach((key: any) => {
      formValues[key].value = "";
    });
    setSelectedValue("");
    setSelectedValue1("");
setV1(false)
setV2(false)
    setAchievementId(null);
    setOpenAchievement(false);
    setFormValues({
      achievement_name: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Achievement name",
      },
      achievement_description: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Achievement description",
      },
  
      achievement_criteria: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Achievement criteria",
      },
      level: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Achievement level",
      },
      gift_name: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Gift name",
      },
      no_of_tokens: {
        value: "",
        error: false,
        errorMessage: "Please enter token",
      },
    })
  };

  const dispatch = useDispatch();

  const handleDeleteAchievement = async (achievementId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    console.log(achievementId);
    const formData = {
      delete_status: "Yes",
    };
    dispatch(loaderOn());
    try {
      //  const res = await axios.put(`https://dev-backoffice.tribe.sn/achievement/delete/${achievementId}`, formData)
      const res = await updateAchievements(achievementId, formData);
      if (res != null) {
        fetchAchievements();
        console.log("data updated sucessfully");
        togglePopup();
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  // const [giftName, setGiftName] = useState("");

  const handleEditAchievement = async (achievementId: any) => {
    //const res = await axios.get(`https://dev-backoffice.tribe.sn/achievement/${achievementId}`)
    const res = await getAchievements(achievementId);
    if (res != null) {
      console.log(res);
      formValues.achievement_name.value = res.achievement_name;
      formValues.achievement_name.error = false;
      formValues.achievement_description.value = res.achievement_description;
      formValues.achievement_description.error = false;
      formValues.achievement_criteria.value = res.achievement_criteria;
      formValues.achievement_criteria.error = false;
      formValues.no_of_tokens.value = res.no_of_tokens;
      formValues.no_of_tokens.error = false;
      setSelectedValue(res.level);
      setSelectedValue1(res.gift_name.gift_id);
    }
    console.log(`Edit  ${achievementId}`);
    handleOpenAchievement(achievementId);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const fetchAchievements = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn());
    try {
      const response = await getAchievementsActive();
      const response1 = await getactivegifts();
      if (response != null && response1 != null) {
        const frontendData = response.map((backendRow: any) => ({
          Sl_No: backendRow.achievement_name,
          Achievement: backendRow.achievement_name,
          Achievement_Description: backendRow.achievement_description,
          Achievement_Criteria: backendRow.achievement_criteria,
          achievement_id: backendRow.achievement_id,
          level: "Level " + backendRow.level,
          gift_name_name: backendRow.gift_name?.gift_name || "",
          no_of_tokens: backendRow.no_of_tokens,
        }));
        setRows2(frontendData);
        console.log(frontendData);
        dispatch(loaderOff());
      } else {
        console.error("Invalid or empty response from getAchievementsActive");
      }
    } catch (error) {
      console.error("Error fetching achievements data:", error);
      dispatch(loaderOff());
    }
  };

  const fetchAchievements1 = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn());
    try {
      const response = await getAllAchievements();
      if (response && Array.isArray(response)) {
        const frontendData = response.map((backendRow: any) => ({
          Sl_No: backendRow.achievement_id,
          Achievements: backendRow.achievement_name,
          Achievements_Description: backendRow.achievement_description,
          Achievement_Criteria: backendRow.achievement_criteria,
          status: backendRow.status,
          level: "Level " + backendRow.level,
          gift_name_name: backendRow.gift_name?.gift_name || "",
          no_of_tokens: backendRow.no_of_tokens,
        }));

        setRows4(frontendData);
        console.log(frontendData);
        dispatch(loaderOff());
      } else {
        console.error("Invalid or empty response from getAllAchievements");
      }
    } catch (error) {
      console.error("Error fetching achievements data:", error);
      dispatch(loaderOff());
    }
  };

  const [id, setId] = useState<number | null>(null);

  const [selectedValue1, setSelectedValue1] = useState("");

  const handleChangeee = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue1(event.target.value);
    if (event.target.value == "") {
      setV2(true);
    } else {
      setV2(false);
    }
    // setGiftName(event.target.value);
    const selectedGiftId = event.target.value
      ? parseInt(event.target.value, 10)
      : null;

    console.log("Selected Token ID:", selectedGiftId);
    setId(selectedGiftId);
  };

  const handleAddAchievemnt = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    const formdata = {
      achievement_name: formValues.achievement_name.value,
      achievement_description: formValues.achievement_description.value,
      achievement_criteria: formValues.achievement_criteria.value,
      level: selectedValue,
      bonus_type: selectedValue,
      gift_name: {
        gift_id: id,
      },
      no_of_tokens: formValues.no_of_tokens.value,
    };
    dispatch(loaderOn());
    try {
      // const res = await axios.post("https://dev-backoffice.tribe.sn/achievement", formdata);
      const res = await createAchievement(formdata);
      if (res != null) {
        console.log("Achievements are added  successfully");
        Object.keys(formValues).forEach((key: any) => {
          formValues[key].value = "";
        });
        setSelectedValue("");
        setSelectedValue1("");

        handleClose();
        fetchAchievements();
        fetchAchievements1();
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  const handleEdittAchieve = async (achievementId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    // const invalidSubmit = validateForm("achievement-add");
    // if (!invalidSubmit) {
      const formData = {
        achievement_name: formValues.achievement_name.value,
        achievement_description: formValues.achievement_description.value,
        achievement_criteria: formValues.achievement_criteria.value,
        level: selectedValue,
        bonus_type: selectedValue,
        gift_name: {
          gift_id: selectedValue1 ? selectedValue1 : null,
        },
        no_of_tokens: formValues.no_of_tokens.value,
      };
      dispatch(loaderOn());
      try {
        // const res = await axios.put(`https://dev-backoffice.tribe.sn/achievement/${achievementId}`, form)
        const res = await updateAchievements1(achievementId, formData);
        if (res != null) {
          console.log("sucessfully updated");

          Object.keys(formValues).forEach((key: any) => {
            formValues[key].value = "";
          });
          setSelectedValue("");
          setSelectedValue1("");

          fetchAchievements();
          fetchAchievements1();
          handleClose3();
          dispatch(loaderOff());
        }
      } catch (error) {
        console.log(error);
        dispatch(loaderOff());
      }
    // }
  };

  useEffect(() => {
    fetchAchievements();
    fetchAchievements1();
    fetchCategory();
  }, []);

  const [selectedValue, setSelectedValue] = useState("");
  const [v1, setV1] = useState(false);
  const [v2, setV2] = useState(false);
  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
    if (event.target.value == "") {
      setV1(true);
    } else {
      setV1(false);
    }
    console.log(selectedValue);
  };

  const textFieldStyle: React.CSSProperties = {
    border: "1px solid #8E47E6",
    height: "6vh",
    borderRadius: "12px",
    paddingBottom: "10%",
    background: "#FFFFFF",
    marginTop: "10px",
    // width:'100%'
  };

  const [categories, setCategories] = useState([]);

  const fetchCategory = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get("https://dev-backoffice.tribe.sn/gift", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data != null) {
        console.log(res.data);
        setCategories(res.data);
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  };
  const CustomTabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTabs-indicator": {
      backgroundColor: "#8E47E6", // Set your custom color here
    },
  }));

  // Restriction
  const [userId, setUserId] = useState(0);
  let temp: number;
  const [achievements_read, setAchievementsRead] = useState(false);
  const [achievements_write, setAchievementsWrite] = useState(false);

  const getRoleData = async () => {
    const res: any = await getUser();

    if (res && res.role_type) {
      if (
        res.role_type.bonus_read !== undefined &&
        res.role_type.bonus_write !== undefined
      ) {
        setAchievementsRead(res.role_type.achievements_read);
        setAchievementsWrite(res.role_type.achievements_write);
      }
    }
  };

  const filteredColumns = achievements_write ? columns3 : columns3.filter(column => column.id !== 'achievement_id');

  const getLoginUserData = async () => {
    const res: any = await getUser();
    if (res != null) {
      temp = res.id;
      setUserId(temp);
    }
    console.log(temp);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getLoginUserData();
      getRoleData();
    };
    const getLoginUserData = async () => {
      const res: any = await getUser();
      if (res != null) {
        temp = res.id;
        setUserId(temp);
      }
      console.log(temp);
    };
    fetchData();
  }, []);

  return (
    <Box sx={{ width: "100%", marginTop: "40px" }}>
      <Box sx={{}}>
        <CustomTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Achievements"
            {...a11yProps(0)}
            style={{
              color: "#8E47E6",
              fontWeight: "600",
              textTransform: "none",
              fontSize: "16px",
            }}
          />
          <Tab
            label="Achievements History"
            {...a11yProps(1)}
            style={{
              color: "#8E47E6",
              fontWeight: "600",
              textTransform: "none",
              fontSize: "16px",
            }}
          />
        </CustomTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box
          sx={{
            marginTop: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontFamily: "",
                  fontSize: "16px",
                  fontWeight: "700",
                  paddingRight: "16px",
                }}
              >
                Achievements
              </Typography>
              <span>
                <TextField
                  // label="Search"
                  value={searchQuery2}
                  onChange={(e) => setSearchQuery2(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#140026" }} />
                      </InputAdornment>
                    ),
                    style: {
                      width: "300px",
                      boxShadow: "0px 4px 4px 0px rgba(142, 71, 230, 0.25)",
                      borderRadius: "6px",
                    },
                  }}
                />
              </span>
            </div>
            {/* <div>
              <CustomPrimaryButton
              style={{ background: "#8E47E6", color: "#FFFFFF" ,
             
          }}

                onClick={handleOpen}
                disabled={achievements_write===false}
              // startIcon={<AddIcon />}
              >
                <AddIcon /> Add Achievemnt
              </CustomPrimaryButton>
            </div> */}

            {achievements_write === false ? (
              ""
            ) : (
              <div>
                <CustomPrimaryButton
                  style={{ background: "#8E47E6", color: "#FFFFFF" }}
                  onClick={handleOpen}
                >
                  <AddIcon /> Add Achievemnt
                </CustomPrimaryButton>
              </div>
            )}
          </div>

          <Box
            sx={{
              color: "black",
              marginTop: "30px",
            }}
          >
            <Paper sx={{ overflow: "hidden" }}>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                }}
              ></div> */}
              <TableContainer
                sx={{
                  maxHeight: 440,
                  borderRadius: "18px",
                  boxShadow: "0px 4px 12px 0px #8E47E640",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}>
                      {columns3.map((column3) => (
                        <TableCell
                          key={column3.id}
                          align={column3.align}
                          style={{
                            minWidth: column3.minWidth,
                            color: column3.color,
                          }}
                        >
                          {/* {column3.label} */}
                          {achievements_write || column3.id !== 'achievement_id' ? column3.label : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows2
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.Achievement_Description}
                          >
                            {filteredColumns.map((column3) => {
                              const value = row[column3.id];
                              return (
                                <TableCell
                                  key={column3.id}
                                  align={column3.align}
                                >
                                  {column3.id === "achievement_id" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      {achievements_write === true ? (
                                        <p
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleEditAchievement(
                                              row.achievement_id
                                            )
                                          }
                                        >
                                          <CreateIcon
                                            style={{ color: "#5151ED" }}
                                          />
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            cursor: "not-allowed",
                                            filter: "blur(1px)",
                                          }}
                                        >
                                          <CreateIcon
                                            style={{ color: "#5151ED" }}
                                          />
                                        </p>
                                      )}
                                      {achievements_write === true ? (
                                        <p
                                          style={{ cursor: "pointer" }}
                                          // onClick={() =>
                                          //   handleDeleteAchievement(
                                          //     row.achievement_id
                                          //   )
                                          // }
                                          onClick={() => {
                                            setSelectedAchievementId(
                                              row.achievement_id
                                            );
                                            setopenDialog(true);
                                          }}
                                        >
                                          <DeleteIcon
                                            style={{ color: "#DF2525" }}
                                          />
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            cursor: "not-allowed",
                                            filter: "blur(1px)",
                                          }}
                                        >
                                          <DeleteIcon
                                            style={{ color: "#DF2525" }}
                                          />
                                        </p>
                                      )}
                                    </div>
                                  ) : column3.format &&
                                    typeof value === "number" ? (
                                    column3.format(value)
                                  ) : column3.id === "level" ? (
                                    <div>{row.level}</div>
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  <CommonDialog
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    handleConfirm={() => {
                      handleDeleteAchievement(selectedAchievementId);
                      handleCloseDialog();
                    }}
                    contentText="Are you sure to delete achievement?"
                  />
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredRows2.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>

          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div >
                <div style={{ float: "left" }}>
                  {" "}
                  <h1>Add Achievemnt</h1>
                </div>

                <div style={{ float: "right" }}>
                  <CloseIcon onClick={handleClose} />
                </div>
              </div>

              <Grid container spacing={2} style={{ marginTop: "8px" }}>
                <Grid item lg={6}>
                  {" "}
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Achievemnt Name
                    <CustomTextField
                      placeholder="Enter Your  Achievemnt Name"
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="achievement_name"
                    />
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Achievemnt Description
                    <CustomTextField
                      placeholder="Enter Your  Achievemnt Description"
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="achievement_description"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "8px" }}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Achievemnt Criteria
                    <CustomTextField
                      placeholder="Enter Your Achievemnt Criteria"
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="achievement_criteria"
                    />
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Level
                    <Select
                      displayEmpty
                      value={selectedValue}
                      onChange={handleSelectChange}
                      style={{
                        border: "1px solid #8E47E6",
                        height: "7vh",
                        borderRadius: "12px",
                        background: "#FFFFFF",
                        marginTop: "10px",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            color: "#000000",
                            background: "#FFFFFF",
                            borderRadius: "14px",
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none", 
                        }
                      }}
                    >
                      <MenuItem value="" disabled>
                        <ListItemText
                          primary={selectedValue === "" ? "Select a Level" : ""}
                          style={{ color: "rgba(0, 0, 0, 0.5)" }}
                        />{" "}
                      </MenuItem>
                      <MenuItem value={"2"}>Level 2</MenuItem>
                      <MenuItem value={"3"}>Level 3</MenuItem>
                      <MenuItem value={"4"}>Level 4</MenuItem>
                      <MenuItem value={"5"}>Level 5</MenuItem>
                      <MenuItem value={"6"}>Level 6</MenuItem>
                      <MenuItem value={"7"}>Level 7</MenuItem>
                    </Select>
                    {v1 != false && (
                      <p
                        style={{
                          color: "#d32f2f",
                          fontSize: "12.7px",
                          fontWeight: 500,
                          margin: "0px 0px 0px 15px",
                        }}
                      >
                        Please select level
                      </p>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "8px" }}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Gifts
                    <Select
                      displayEmpty
                      value={selectedValue1}
                      onChange={(e: any) => handleChangeee(e)}
                      style={{
                        border: "1px solid #8E47E6",
                        height: "7vh",
                        borderRadius: "12px",
                        background: "#FFFFFF",
                        marginTop: "10px",
                        // width: "25vw",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            color: "#000000",
                            background: "#FFFFFF",
                            borderRadius: "14px",
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none", 
                        }
                      }}
                    >
                      <MenuItem value="" disabled>
                        <ListItemText
                          primary={selectedValue1 === "" ? "Select a Gift" : ""}
                          style={{ color: "rgba(0, 0, 0, 0.5)" }}
                        />
                      </MenuItem>
                      {categories.map((category: any) => (
                        <MenuItem
                          key={category.gift_id}
                          value={category.gift_id}
                        >
                          {category.gift_name}
                        </MenuItem>
                      ))}
                    </Select>
                    {v2 != false && (
                      <p
                        style={{
                          color: "#d32f2f",
                          fontSize: "12.7px",
                          fontWeight: 500,
                          margin: "0px 0px 0px 15px",
                        }}
                      >
                        Please select gifts
                      </p>
                    )}
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    No of Tokens
                    <CustomTextField
                      placeholder="Enter Your Number of Tokens"
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="no_of_tokens"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "20px 0px 0px 10px",
                }}
              >
                <Grid
                  item
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CommonDialog
                      open={openDialog}
                      handleClose={handleCloseDialog}
                      handleConfirm={() => {
                        handleAddAchievemnt();
                        handleCloseDialog();
                      }}
                      contentText="Are you sure to add achievement?"
                    />
                    <CustomPrimaryButton
                      style={{
                        padding: "15px 25px",
                      }}
                      onClick={() => {
                        const invalidSubmit = validateForm("achievement-add");
                        if (selectedValue == "") {
                          setV1(true);
                        } else {
                          setV1(false);
                        }

                        if (selectedValue1 == "") {
                          setV2(true);
                        } else {
                          setV2(false);
                        }
                        if (!invalidSubmit) {
                          setopenDialog(true);
                        }
                      }}
                    >
                      Add
                    </CustomPrimaryButton>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <Modal
            open={openAchievement}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div onClick={handleClose}>
                <div style={{ float: "left" }}>
                  {" "}
                  <h1>Update Achievements</h1>
                </div>

                <div style={{ float: "right" }} onClick={handleClose3}>
                  <CloseIcon />
                </div>
              </div>

              <Grid container spacing={2} style={{}}>
                <Grid item lg={6}>
                  {" "}
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Achievemnt Name
                    <CustomTextField
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="achievement_name"
                    />
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Achievemnt Description
                    <CustomTextField
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="achievement_description"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "5px" }}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Achievemnt Criteria
                    <CustomTextField
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="achievement_criteria"
                    />
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Level
                    <Select
                      value={selectedValue}
                      onChange={handleSelectChange}
                      style={{
                        border: "1px solid #8E47E6",
                        height: "7vh",
                        borderRadius: "12px",
                        background: "#FFFFFF",
                        marginTop: "5px",
                        // width: "25vw",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            color: "#000000",
                            background: "#FFFFFF",
                            borderRadius: "14px",
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none", 
                        }
                      }}
                    >
                      <MenuItem value={"2"}>Level 2</MenuItem>
                      <MenuItem value={"3"}>Level 3</MenuItem>
                      <MenuItem value={"4"}>Level 4</MenuItem>
                      <MenuItem value={"5"}>Level 5</MenuItem>
                      <MenuItem value={"6"}>Level 6</MenuItem>
                      <MenuItem value={"7"}>Level 7</MenuItem>
                    </Select>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "5px" }}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Gifts
                    <Select
                      value={selectedValue1}
                      onChange={(e: any) => handleChangeee(e)}
                      style={{
                        border: "1px solid #8E47E6",
                        height: "7vh",
                        borderRadius: "12px",
                        background: "#FFFFFF",
                        marginTop: "5px",
                        // width: "25vw",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            color: "#000000",
                            background: "#FFFFFF",
                            borderRadius: "14px",
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none", 
                        }
                      }}
                    >
                      {categories.map((category: any) => (
                        <MenuItem
                          key={category.gift_id}
                          value={category.gift_id}
                        >
                          {category.gift_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    No of Tokens
                    <CustomTextField
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="no_of_tokens"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid
                item
                lg={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "15px 0px 10px 0px",
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    display: "flex",
                    flexDirection: "column",
                    // marginTop: "5px",
                  }}
                >
                  <CommonDialog
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    handleConfirm={() => {
                      handleEdittAchieve(achievementId);
                      handleCloseDialog();
                    }}
                    contentText="Are you sure to update achievement?"
                  />
                  <CustomPrimaryButton
                    onClick={() => {
                      const invalidSubmit = validateForm("achievement-add");
                      if(!invalidSubmit){
                      setopenDialog(true);
                      }
                    }}
                  >
                    Update
                  </CustomPrimaryButton>
                </div>
              </Grid>
            </Box>
          </Modal>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box marginTop={"20px"}>
          <div
            style={{
              display: "flex",
              justifyContent: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontFamily: "",
                  fontStyle: "16px",
                  fontWeight: "700",
                  paddingRight: "16px",
                }}
              >
                Achievement History
              </p>
              <span>
                <TextField
                  // label="Search"
                  value={searchQuery4}
                  onChange={(e) => setSearchQuery4(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#140026" }} />
                      </InputAdornment>
                    ),
                    style: {
                      width: "300px",
                      boxShadow: "0px 4px 4px 0px rgba(142, 71, 230, 0.25)",
                      borderRadius: "6px",
                    },
                  }}
                />
              </span>
            </div>
            <span>
              {/* <Button
                style={{
                  color: "black",
                  backgroundColor: "#8E47E6",
                }}
                endIcon={<DownloadIcon />}
              >
                Export{" "}
              </Button> */}
            </span>
          </div>
          <Box
            sx={{
              color: "black",
              marginTop: "20px",
            }}
          >
            <div></div>
            <Paper sx={{ overflow: "hidden" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                }}
              ></div>
              <TableContainer
                sx={{
                  maxHeight: 440,
                  borderRadius: "18px",
                  boxShadow: "0px 4px 12px 0px #8E47E640",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}>
                      {columns5.map((column5) => (
                        <TableCell
                          key={column5.id}
                          align={column5.align}
                          style={{
                            minWidth: column5.minWidth,
                            color: column5.color,
                          }}
                        >
                          {column5.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows4
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.Achievements_Description}
                          >
                            {columns5.map((column5) => {
                              const value = row[column5.id];
                              return (
                                <TableCell
                                  key={column5.id}
                                  align={column5.align}
                                >
                                  {column5.format && typeof value === "number"
                                    ? column5.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredRows4.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Box>
      </CustomTabPanel>
    </Box>
  );
}
