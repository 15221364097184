import { Box, Grid, Typography } from "@mui/material";
import L from "leaflet";
import React, { useEffect, useRef, useState } from "react";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import axios from "axios";
import { getToken } from "../utilities/locationService";
import { getAllLocation, getAllUser } from "../utilities/backofficeservice";
import { useNavigate } from "react-router-dom";
import { loaderOff, loaderOn, logout, openSnackbar } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import store from "../redux/store";

const data1 = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

// const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };

export default function Dashboard() {
  const mapRef = useRef<L.Map | null>(null);
  let Length = 0;
  const [len, setLen] = useState(0);
  const [length, setLength] = useState(0);
  let TotalToken = 0;

  const [pending, setPending] = useState(0);
  const [approved, setApproved] = useState(0);
  const [rejected, setRejected] = useState(0);
  const [token, setToken] = useState(0);
  const [dataCollected, setDataCollected] = useState(null);
  const [email, setEmail] = useState("");
  const [data, setData]: any = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isTokenExpired = useSelector((state: any) => state.auth.isTokenExpired);
  console.log("syed" + isLoggedIn);
  // useEffect(() => {
  //   if (isLoggedIn) {
  //     console.log("user logged in successfully", isLoggedIn);
  //   } else {
  //     store.dispatch(logout());
  //     sessionStorage.removeItem("jwtToken");
  //     sessionStorage.removeItem("isLoggedIn");
  //     sessionStorage.removeItem("isSubcribed");
  //     dispatch(
  //       openSnackbar("Your session has expired. Please log in again.", "red")
  //     );
  //     navigate("/sign_in");
  //   }
  // }, [isLoggedIn]);

  useEffect(() => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken || isTokenExpired) {
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    } else if (!isLoggedIn) {
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    }
  }, [isLoggedIn, isTokenExpired]);

  const fetchDataCollected = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    console.log(jwtToken);
    console.log("sgdgasjgdjasgdjajdasgdjhadhjgdghjdgajdajdhjsgd");

    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    dispatch(loaderOn());
    try {
      const res = await getToken();
      if (res != null) {
        console.log("Data Collected:", res);
        setDataCollected(res);
        const data = [
          {
            name: "Jan",
            uv: res["0"],
          },
          {
            name: "Feb",
            uv: res["1"],
          },
          {
            name: "Mar",
            uv: res["2"],
          },
          {
            name: "Apr",
            uv: res["3"],
          },
          {
            name: "May",
            uv: res["4"],
          },
          {
            name: "Jun",
            uv: res["5"],
          },
          {
            name: "Jul",
            uv: res["6"],
          },
          {
            name: "Aug",
            uv: res["7"],
          },
          {
            name: "Sep",
            uv: res["8"],
          },
          {
            name: "Oct",
            uv: res["9"],
          },
          {
            name: "Nov",
            uv: res["10"],
          },
          {
            name: "Dec",
            uv: res["11"],
          },
        ];
        setData(data);
        dispatch(loaderOff());
      }
    } catch (error) {
      console.error("Failed to fetch data collected", error);
      dispatch(loaderOff());
    }
  };

  const fetchLocationRejected = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    dispatch(loaderOn());
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get("https://dev-location.tribe.sn/location/getrejected", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data != null) {
        console.log(res.data);
        Length = res.data.length;
        setRejected(Length);
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  const fetchLocationApproved = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    dispatch(loaderOn());
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get("https://dev-location.tribe.sn/location/getapproved", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data != null) {
        console.log(res.data);
        Length = res.data.length;
        setApproved(Length);
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  const fetchLocationPending = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    dispatch(loaderOn());
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get("https://dev-location.tribe.sn/location/getpending", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data != null) {
        console.log(res.data);
        Length = res.data.length;
        setPending(Length);
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };
  useEffect(() => {
    fetchLocationPending();
    fetchLocationApproved();
    fetchLocationRejected();
  }, []);

  const handleMapDragEnd = () => {
    const map = mapRef.current;
    if (map) {
      // Perform actions after the map has been dragged
      const center = map.getCenter();
      const zoom = map.getZoom();
      console.log("Map dragged to:", center, "Zoom level:", zoom);
    }
  };

  // const position = [14.329837, -14.449860];

  interface Position {
    latitude: number;
    longitude: number;
  }

  const [positions, setPositions] = useState<Position[]>([]);
  useEffect(() => {
    const token = sessionStorage.getItem("jwtToken");
    fetch("https://dev-location.tribe.sn/location/getall", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setPositions(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const locationIcon = new L.Icon({
    iconUrl: "/assets/static/images/pin.png",
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const fetchData = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    dispatch(loaderOn());
    try {
      const res = await getAllLocation();
      if (res != null) {
        console.log("Fetched data:", res);
        Length = res.length;
        setLen(Length);
        console.log("Length of the array:", res.length);
        dispatch(loaderOff());
      }
    } catch (error) {
      console.error("Failed to fetch data", error);
      dispatch(loaderOff());
    }
  };

  const fetchInteraction = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    dispatch(loaderOn());
    try {
      const res = await getAllUser();
      if (res != null) {
        console.log(res);
        Length = res.length;
        setLength(Length);
        dispatch(loaderOff());
      }
    } catch (error) {
      console.error(error);
      dispatch(loaderOff());
    }
  };

  const fetchTokenDistributed = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    dispatch(loaderOn());
    try {
      // const res = await axios.get("https://dev-location.tribe.sn/location/token/getall");
      const res = await getAllLocation();
      if (res != null) {
        console.log(res);
        TotalToken = res;
        setToken(TotalToken);
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  useEffect(() => {
    fetchDataCollected();
    fetchData();
    fetchInteraction();
    fetchTokenDistributed();
  }, []);

  return (
    <div style={{ paddingTop: "30px" }}>
      <Grid container spacing={2}>
        <Grid item lg={3} style={{ backgroundColor: "" }}>
          <div>
            <Typography
              style={{
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontWeight: 700,
                marginRight: "10px",
                paddingLeft: "5px",
                textAlign: "left",
              }}
            >
              Statistics
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // justifyContent:'space-between',
              paddingTop: "10px",
              // height:'100%'
              gap: "40px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#DEC9FE",
                // width:'120px',
                marginRight: "10px",
                paddingLeft: "5px",
                textAlign: "left",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/location")}
            >
              <h1
                style={{
                  marginBottom: "4px",
                  fontSize: "13px",
                  fontFamily: "400",
                }}
              >
                Data Collected
              </h1>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                }}
              >
                {len}
              </p>
            </Box>
            <Box
              sx={{
                backgroundColor: "#DEC9FE",
                // width:'120px',
                marginRight: "10px",
                paddingLeft: "5px",
                textAlign: "left",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/user_access")}
            >
              <h1
                style={{
                  marginBottom: "4px",
                  fontSize: "13px",
                  fontFamily: "400",
                }}
              >
                User Interactions
              </h1>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                }}
              >
                {length}
              </p>
            </Box>
            <Box
              sx={{
                backgroundColor: "#DEC9FE",
                // width:'120px',
                marginRight: "10px",
                paddingLeft: "5px",
                textAlign: "left",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/location_history")}
            >
              <h1
                style={{
                  marginBottom: "4px",
                  fontSize: "13px",
                  fontFamily: "400",
                }}
              >
                Locations Approved
              </h1>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                }}
              >
                {approved}
              </p>
            </Box>
            <Box
              sx={{
                backgroundColor: "#DEC9FE",
                // width:'120px',
                marginRight: "10px",
                paddingLeft: "5px",
                textAlign: "left",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/location")}
            >
              <h1
                style={{
                  marginBottom: "4px",
                  fontSize: "13px",
                  fontFamily: "400",
                }}
              >
                Location Pending{" "}
              </h1>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                }}
              >
                {pending}
              </p>
            </Box>
            <Box
              sx={{
                backgroundColor: "#DEC9FE",
                // width:'120px',
                marginRight: "10px",
                paddingLeft: "5px",
                textAlign: "left",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/location_history")}
            >
              <h1
                style={{
                  marginBottom: "4px",
                  fontSize: "13px",
                  fontFamily: "400",
                }}
              >
                Location Rejected
              </h1>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                }}
              >
                {rejected}
              </p>
            </Box>
          </div>
        </Grid>
        <Grid item lg={7} style={{ backgroundColor: " " }}>
          <div>
            <Typography
              style={{
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontWeight: 700,
                float: "left",
              }}
            >
              Freshness
            </Typography>
          </div>
          <div>
            {/* <MapContainer
                  center={position as L.LatLngExpression}
                  zoom={5}
                  style={{ height: 400, width: 800 ,top:10}}
                >
                  <TileLayer
                    attribution='&copy;   contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                </MapContainer> */}
            <MapContainer
              center={[0, 0]}
              zoom={5}
              style={{ height: 400, width: 800, top: 10 }}
            >
              <TileLayer
                attribution="&copy; contributors"
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              {positions.map((position, index) => (
                <Marker
                  key={index}
                  position={[position.latitude, position.longitude]}
                  icon={locationIcon}
                >
                  <Popup>
                    Latitude: {position.latitude} <br />
                    Longitude: {position.longitude}
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          </div>
          <Grid container spacing={2}>
            <Grid item lg={9}>
              <Box>
                <h1
                  style={{
                    marginBottom: "24px",
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    fontWeight: "700",
                    paddingTop: "30px",
                  }}
                >
                  Location Data
                </h1>
                <div
                  style={{
                    height: "400px",
                  }}
                >
                  <ResponsiveContainer>
                    {/* <LineChart
                  // width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 30, // Increase the bottom margin to make space for legends
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend
                    align="center" // You can adjust the alignment as needed
                    verticalAlign="bottom" // Place the legend below the chart
                    height={30} // Adjust the height of the legend container
                  />
                  <Line type="monotone" dataKey="uv" stroke="red" name="Location Uploaded" />
                </LineChart> */}
                    <BarChart
                      width={730}
                      height={250}
                      data={data}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 30,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="uv"
                        fill="#D2122E"
                        name="Location Uploaded"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </Box>
            </Grid>
            <Grid item lg={3}>
              <div>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "40px",
                    fontFamily: "",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Locations
                </div> */}
                <div
                  style={{
                    height: "253px",
                    // width: "300px",
                    backgroundColor: "white",
                    paddingTop: "40px",
                  }}
                >
                  {/* <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data1}
            cx="50%"
            cy="50%"
            labelLine={false}
            // label={renderCustomizedLabel}
            outerRadius={78}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry: any, index: any) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer> */}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
