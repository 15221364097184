// redux/actions.ts

export const login = (user: string) => ({
  type: 'LOGIN' as const,
  payload: user,
})

export const logout = () => ({
  type: 'LOGOUT' as const,
})

export const loaderOn = () => ({
  type: 'SET_LOADING' as const,
})

export const loaderOff = () => ({
  type: 'SET_LOADING_OFF' as const,
})

export const openSnackbar = (message: any, color: any) => ({
  type: 'OPEN_SNACKBAR',
  payload: message,
  color: color,
})

export const closeSnackbar = () => ({
  type: 'CLOSE_SNACKBAR',
})
