import { Box, Button, Grid, TextField, useMediaQuery } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInUser } from "../utilities/adminservice";
import { useDispatch } from "react-redux";
import { loaderOff, loaderOn, login, openSnackbar } from "../redux/actions";
import { getAuthToken } from "../utilities/TokenUtility";
import { useSignInForm } from "../custom_forms/SignInForm";
import { emailRegex, passwordRegex } from "../CustomRegex/CustomRegex";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from 'react-router-dom';
import store from "../redux/store";
export default function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  const { formValues, setFormValues } = useSignInForm();
  const [showPassword, setShowPassword] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "password") {
      const password: string = value;
      const isValidPassword: boolean = passwordRegex.test(password);
      setFormValues({
        ...formValues,
        [name]: {
          ...formValues[name],
          value,
          error: !isValidPassword,
        },
      });
    } else if (name === "email") {
      const email: string = value;
      const isValidEmail: boolean = emailRegex.test(email);
      setFormValues({
        ...formValues,
        [name]: {
          ...formValues[name],
          value,
          error: !isValidEmail,
        },
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: {
          ...formValues[name],
          value,
          error: value === "" ? true : false,
        },
      });
    }
  };
  const validateForm = (type: any) => {
    let invalidSubmit = false;
    const email: string = formValues.email.value;
    const isValidEmail: boolean = emailRegex.test(email);
    setFormValues({
      ...formValues,
      ["email"]: {
        ...formValues["email"],
        error: !isValidEmail,
      },
    });
    invalidSubmit = !isValidEmail;
    const password: string = formValues.password.value;
    const isValidPassword: boolean = passwordRegex.test(password);
    setFormValues({
      ...formValues,
      ["password"]: {
        ...formValues["password"],
        error: true,
      },
    });
    invalidSubmit = !isValidPassword;
    const formFields = Object.keys(formValues);
    let newFormValues = { ...formValues };
    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formValues[currentField].value;
      if (type == "sign-in") {
        if (currentValue === "") {
          newFormValues = {
            ...newFormValues,
            [currentField]: {
              ...newFormValues[currentField],
              error: true,
            },
          };
        }
      }
    }
    setFormValues(newFormValues);
    Object.values(newFormValues).every((number: any) => {
      if (number.error) {
        invalidSubmit = number.error;
        dispatch(loaderOff());
        return false;
      }
    });
    dispatch(loaderOff());
    return invalidSubmit;
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
 
  const handleMouseDownPassword = (e: any) => {
    e.preventDefault();
  };
  const handleLogin = (token: any) => {
    const userData: string = token;
    sessionStorage.setItem("jwtToken", userData);
    sessionStorage.setItem("isLoggedIn", "true");
    store.dispatch(login(userData));
   };
 
  const handleSignIn = async () => {
    const invalidSubmit = validateForm("sign-in");
    if (!invalidSubmit) {
      const formData = new FormData();
      formData.append("email", formValues.email.value);
      formData.append("password", formValues.password.value);
      dispatch(loaderOn());
      try {
        const res:any = await signInUser(formData);
        if (res != 'Invalid Username and Password' && res != null) {
          dispatch(loaderOff());
          handleLogin(res);
          navigate("/dashboard");
          console.log("syed hhow are you");
          console.log(sessionStorage);
          console.log("syed hhow are you");

        } else {
          dispatch(loaderOff());
          dispatch(openSnackbar("Invalid Username or Password..!!", "red"));
        }
      } catch (error) {
        dispatch(loaderOff());
        console.log("Login failed" + error);
      }
    }
  };
 
  const fetchToken = async () => {
    dispatch(loaderOn())
    try {
      const token = getAuthToken();
      console.log(token);
      const res = await axios.get("https://dev-admin.tribe.sn/admin");
      if (res.data != null) {
        console.log("admin data received!!!");
        console.log(res.data);
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  };

  // useEffect(() => {
  //   // Dispatch the action to turn off the loader when the component mounts
  //   dispatch(loaderOff());
  // }, [dispatch]);
 
  useEffect(() => {
    fetchToken();
  }, []);
  const matchesUp = useMediaQuery('(min-width:1200px)')
  return (
    <div>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          height: matchesUp ? '100vh' : '100%',
          overflowY: 'hidden',
          
        }}
      >
        <Grid item lg={4} sx={{ padding: "10px 20px 20px 20px" ,border: '1px solid #8E47E640',  boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',borderRadius:'14px'  }}>
          <Grid
            sx={{
              // height: "94.34px",
              // width: "60px",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="assets/static/svg/applogo.svg" />
          </Grid>
 
          <Grid
            sx={{
              paddingTop: "40px",
              fontWeight: 700,
              fontSize: "32px",
              color: "#8E47E6",
              fontFamily: "Open Sans",
              lineHeight: "43.58px",
              textTransform: "none",
              textAlign: "left",
            }}
          >
            We are Tribe
          </Grid>
          <div
            style={{
              paddingTop: "15px",
              fontWeight: 400,
              fontFamily: "Poppins",
              fontSize: "16px",
              lineHeight: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
 
          <div
            style={
              {
                // display: "flex",
                // flexDirection: "column",
                // justifyContent: "center",
                // alignItems: "center",
                // backgroundColor: "green",
              }
            }
          >
            <div
              style={{
                paddingBottom: "10px",
                fontSize: "16px",
                fontFamily: "H 2 Bold",
                fontWeight: "700",
                textAlign: "left",
              }}
            >
              Email
            </div>
            <TextField
              fullWidth
              name="email"
              type="email"
              autoComplete="off"
              onChange={(e: any) => handleChange(e)}
              hiddenLabel
              value={formValues.email.value}
              error={formValues.email.error}
              helperText={
                formValues.email.error && formValues.email.errorMessage
              }
              id="filled-hidden-label-small"
              variant="filled"
              // size="small"
              InputProps={{
                placeholder: "Enter your email",
                disableUnderline: true,
                style: {
                  borderRadius:'8px',
                 
                  border: "1px solid #8E47E6",
                  height: "50px",
                
                },
              }}
            />
 
            <div
              style={{
                
                 padding:'10px 0px 10px 0px',
                fontSize: "16px",
                fontFamily: "H 2 Bold",
                fontWeight: "700",
                textAlign: "left",
              }}
            >
              Password
            </div>
            <TextField
              fullWidth
              name="password"
              type={showPassword ? "text" : "password"}
              // type='password'
              autoComplete="off"
              onChange={(e: any) => handleChange(e)}
              hiddenLabel
              value={formValues.password.value}
              error={formValues.password.error}
              helperText={
                formValues.password.error && formValues.password.errorMessage
              }
              id="filled-hidden-label-small"
              variant="filled"
              // size="small"
              InputProps={{
                placeholder: "Enter your password",
                disableUnderline: true,
                style: {
                  borderRadius:'8px',
                  height: "50px",
                  border: "1px solid #8E47E6",
                  
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      sx={{ color: "#000000" }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          
            <div
              style={{
               
                display: "flex",
                justifyContent: "right",
                color: "red",
               padding:'10px 0px 15px 0px',
                fontSize: "16px",
                fontFamily: "H 2 Bold",
                fontWeight: "700",
              }}
            >
         <Link to='/forgotpassword' style={{textDecoration:'none'}}>Forgot Password ?</Link> 
            </div>
         
            <Button
              fullWidth
              sx={{
                // marginTop:'10px',
                padding: "12px 5px",
                background: "#8E47E6",
                color: "white",
                // width: "250px",
                textTransform: "none",
                FontFamily: "Open Sans",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "21.79px",
                height:'50px',
                '&:hover':{
                  background: "#8E47E6",
                color: "white",
                }
              }}
              onClick={() => {
                handleSignIn();
              }}
            >
              Sign In
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
 
