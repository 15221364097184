import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import axios from "axios";
import DownloadIcon from '@mui/icons-material/Download';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from "react-redux";
import { loaderOff, loaderOn } from "../redux/actions";


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,

  bgcolor: 'background.paper',
  border: '2px solid white',

  // boxShadow: 24,
  p: 4,
};


const style1 = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,

  bgcolor: 'background.paper',
  border: '2px solid white',

  // boxShadow: 24,
  p: 4,
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Column {
  id: "Bonus" | "Bonus_Description" | "Bonus_Criteria" | "bonus_id";
  label: string;
  minWidth?: number;
  align?: "left";
  format?: (value: number) => string;
  color?: string;
}

const columns: readonly Column[] = [
  { id: "Bonus", label: "Bonus", color: "#8E47E6" },
  { id: "Bonus_Description", label: "Bonus Description", color: "#8E47E6" },
  {
    id: "Bonus_Criteria",
    label: "Bonus Criteria",
    format: (value: number) => value.toLocaleString("en-US"), color: "#8E47E6"
  },
  { id: "bonus_id", label: "Action", color: "#8E47E6" },
];

interface Data {
  Bonus: string;
  Bonus_Description: string;
  Bonus_Criteria: number;
  bonus_id: number;
}




interface Column2 {
  id: "Bonus" | "Bonus_Description" | "Bonus_Criteria";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;

}

const columns2: readonly Column2[] = [
  { id: "Bonus", label: "Bonus", color: "#8E47E6" },
  { id: "Bonus_Description", label: "Bonus Description", color: "#8E47E6" },
  {
    id: "Bonus_Criteria",
    label: "Bonus Criteria",
    format: (value: number) => value.toLocaleString("en-US"), color: "#8E47E6",
  },

];

interface Data2 {
  Bonus: string;
  Bonus_Description: string;
  Bonus_Criteria: number;

}





interface Column3 {
  id:
  | "Achievement"
  | "Achievement_Description"
  | "Achievement_Criteria"
  | "achievement_id";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}

const columns3: readonly Column3[] = [
  { id: "Achievement", label: "Achievement", color: "#8E47E6" },
  {
    id: "Achievement_Description",
    label: "Achievement Description",
    color: "#8E47E6"
  },
  {
    id: "Achievement_Criteria",
    label: "Achievement Criteria ",
    color: "#8E47E6"
  },

  {
    id: "achievement_id",
    label: "Action",
    color: "#8E47E6"
  },
];

interface Data3 {
  Achievement: string;
  Achievement_Description: string;
  Achievement_Criteria: number;
  achievement_id: number;
}



interface Column4 {
  id: "Gift" | "Gift_Description" | "Gift_Criteria" | "gift_id";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}

const columns4: readonly Column4[] = [
  { id: "Gift", label: "Gift", color: "#8E47E6" },
  {
    id: "Gift_Description",
    label: "Gift Description",
    color: "#8E47E6"
  },
  {
    id: "Gift_Criteria",
    label: "Gift Criteria",
    format: (value: number) => value.toLocaleString("en-US"), color: "#8E47E6"
  },

  {
    id: "gift_id",
    label: "Action",
    format: (value: number) => value.toFixed(2), color: "#8E47E6"
  },
];

interface Data4 {
  Gift: string;
  Gift_Description: string;
  Gift_Criteria: number;
  gift_id: number;
}


interface Column5 {
  id:
  | "Sl_No"
  | "Achievements"
  | "Achievements_Description"
  | "Achievement_Criteria"
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}

const columns5: readonly Column5[] = [
  { id: "Sl_No", label: "Sl. No", color: "#8E47E6" },
  { id: "Achievements", label: "Achievements", color: "#8E47E6" },
  {
    id: "Achievements_Description",
    label: "Achievements Description ",

    format: (value: number) => value.toLocaleString("en-US"), color: "#8E47E6"
  },
  {
    id: "Achievement_Criteria",
    label: "Achievement Criteria",

    format: (value: number) => value.toLocaleString("en-US"), color: "#8E47E6"
  },

];

interface Data5 {
  Sl_No: string;
  Achievements: string;
  Achievements_Description: number;
  Achievement_Criteria: number;

}


interface Column6 {
  id: "Sl_No" | "Gifts" | "Gift_Description" | "Gift_Criteria";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}

const columns6: readonly Column6[] = [
  { id: "Sl_No", label: "Sl. No", color: "#8E47E6" },
  { id: "Gifts", label: "Gifts", color: "#8E47E6" },
  {
    id: "Gift_Description",
    label: "Gift  Description ",

    format: (value: number) => value.toLocaleString("en-US"), color: "#8E47E6"
  },
  {
    id: "Gift_Criteria",
    label: "Gift Criteria",

    format: (value: number) => value.toLocaleString("en-US"), color: "#8E47E6"
  },

];

interface Data6 {
  Sl_No: string;
  Gifts: string;
  Gift_Description: number;
  Gift_Criteria: number;

}






export default function BonusPage() {
  const [value, setValue] = React.useState(0);
  const [rows, setRows] = useState<Data[]>([]);
  const [rows1, setRows1] = useState<Data2[]>([]);
  const [rows2, setRows2] = useState<Data3[]>([]);
  const [rows3, setRows3] = useState<Data4[]>([]);
  const [rows4, setRows4] = useState<Data5[]>([]);
  const [rows5, setRows5] = useState<Data6[]>([]);
  const [bonusId, setBonusId] = React.useState(null);
  const [achievementId, setAchievementId] = React.useState(null);
  const [giftId, setGiftId] = React.useState(null);
   const [searchQuery,setSearchQuery]=useState('');
   const [searchQuery1,setSearchQuery1]=useState('');
   const [searchQuery2,setSearchQuery2]=useState('');
   const [searchQuery3,setSearchQuery3]=useState('');
   const [searchQuery4,setSearchQuery4]=useState('');
   const [searchQuery5,setSearchQuery5]=useState('');




  const [formvalues, setFormvalues]: any = useState({

    bonus_name: {
      name: '',
      value: '',
      error: false
    },

    bonus_description: {
      name: '',
      value: '',
      error: false

    },

    bonus_criteria: {

      name: '',
      value: '',
      error: false

    },

    achievement_name: {
      name: '',
      value: '',
      error: false

    },
    achievement_description: {
      name: '',
      value: '',
      error: false

    },

    achievement_criteria: {
      name: '',
      value: '',
      error: false
    },

    gift_name: {
      name: '',
      value: '',
      error: false
    },

    gift_description: {
      name: '',
      value: '',
      error: false
    },

    gift_criteria: {
      name: '',
      value: '',
      error: false
    }



  })

  const handleChangee = (name: string | number, newValue: any) => {
    setFormvalues((prevState: { [x: string]: any; }) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: newValue,
      },
    }));
  };


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = (bonusId: any) => {
    setBonusId(bonusId);
    setOpen2(true);
  };

  const handleClose2 = () => {
    setBonusId(null);
    setOpen2(false);
  };

  const [openAchievement, setOpenAchievement] = React.useState(false);
  const handleOpenAchievement = (achievementId: any) => {
    setAchievementId(achievementId);
    setOpenAchievement(true);
  };

  const handleClose3 = () => {
    setAchievementId(null);
    setOpenAchievement(false);
  };


  const [openGift, setOpenGift] = React.useState(false);
  const handleOpenGift = (giftId: any) => {
    setGiftId(giftId);
    setOpenGift(true);
  };

  const handleClose4 = () => {
    setGiftId(null);
    setOpenGift(false);
  };

  const dispatch = useDispatch();

  const handleDeletee = async (bonusId: any) => {

    const formData = {
      "delete_status": 'Yes'
    }
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.put(`https://dev-backoffice.tribe.sn/bonus/delete/${bonusId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (res.data != null) {
        console.log("updated sucessfully")

        fetchBonus1();
        dispatch(loaderOff())

      }

    } catch (error) {
      console.log(error);
      dispatch(loaderOff())

    }
  }

  const handleEditt = async (bonusId: any) => {
    const formData = {
      bonus_name: formvalues.bonus_name.value,
      bonus_description: formvalues.bonus_description.value,
      bonus_criteria: formvalues.bonus_criteria.value

    }
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.put(`https://dev-backoffice.tribe.sn/bonus/${bonusId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log(bonusId);
      if (res.data != null) {
        console.log("the data edited sucessfully");
        handleClose2();
        fetchBonus1();
        fetchBonus();
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())

    }

  }


  const handleEdit = async (bonusId: any) => {
dispatch(loaderOn())
    try{
      const token = sessionStorage.getItem("jwtToken");
    const res = await axios.get(`https://dev-backoffice.tribe.sn/bonus/${bonusId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (res.data != null) {
      console.log(res.data);
      formvalues.bonus_name.value = res.data.bonus_name
      formvalues.bonus_criteria.value = res.data.bonus_criteria
      formvalues.bonus_description.value = res.data.bonus_description
      dispatch(loaderOff())
    }
    console.log(`Edit ${bonusId}`);
    handleOpen2(bonusId);
  }catch{
    dispatch(loaderOff())
  }
  };


  const handleDelete = (bonusId: any) => {
    handleDeletee(bonusId);
    console.log(`Delete   ${bonusId}`);

  };

  const handleDeleteAchievementt = async (achievementId: any) => {

    const formData = {
      "delete_status": "Yes"
    }
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.put(`https://dev-backoffice.tribe.sn/achievement/delete/${achievementId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (res.data != null) {
        fetchAchievements();
        console.log("data updated sucessfully")
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }


  }

  const handleEdittAchieve = async (achievementId: any) => {
    const fromData = {
      achievement_name: formvalues.achievement_name.value,
      achievement_description: formvalues.achievement_description.value,
      achievement_criteria: formvalues.achievement_criteria.value
    }
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.put(`https://dev-backoffice.tribe.sn/achievement/${achievementId}`, fromData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (res.data != null) {
        console.log("sucessfully updated")
        fetchAchievements();
        fetchAchievements1();
        handleClose3();
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }

  }



  const handleEditAchievement = async (achievementId: any) => {
    dispatch(loaderOn())
try{
  const token = sessionStorage.getItem("jwtToken");
    const res = await axios.get(`https://dev-backoffice.tribe.sn/achievement/${achievementId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (res.data != null) {
      formvalues.achievement_name.value = res.data.achievement_name
      formvalues.achievement_description.value = res.data.achievement_description
      formvalues.achievement_criteria.value = res.data.achievement_criteria
      console.log(res.data);
      dispatch(loaderOff())

    }
    console.log(`Edit  ${achievementId}`);
    handleOpenAchievement(achievementId);
  }catch{
    dispatch(loaderOff())
  }

  }

  const handleDeleteAchievement = (achievementId: any) => {
    handleDeleteAchievementt(achievementId);
    console.log(`Delete   ${achievementId}`);

  };

  const handleEdittGift = async (giftId: any) => {
    const formData = {
      gift_name: formvalues.gift_name.value,
      gift_description: formvalues.gift_description.value,
      gift_criteria: formvalues.gift_criteria.value
    }
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.put(`https://dev-backoffice.tribe.sn/gift/${giftId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (res.data != null) {
        console.log("updated sucessfully");
        handleClose4();
        fetchGifts();
        fetchGifts1();

        dispatch(loaderOff())
      }

    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }

  }
  const handleAutoFillGift = async (giftId: any) => {
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get(`https://dev-backoffice.tribe.sn/gift/${giftId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (res.data != null) {
        formvalues.gift_name.value = res.data.gift_name
        formvalues.gift_description.value = res.data.gift_description
        formvalues.gift_criteria.value = res.data.gift_criteria
        console.log(res.data);
        handleOpenGift(giftId);
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }

  }



  const handleEditGift = (giftId: any) => {
    handleAutoFillGift(giftId)
    console.log(`Edit  ${giftId}`);
  }

  const handleDeleteGift = (giftId: any) => {
    handleDeleteGiftt(giftId);
    console.log(`Delete   ${giftId}`);
  };

  const handleDeleteGiftt = async (giftId: any) => {
    const formData = {
      "delete_status": "Yes"
    }
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.put(`https://dev-backoffice.tribe.sn/gift/delete/${giftId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (res.data != null) {
        fetchGifts();

        console.log("gift status updated sucessfully")
        dispatch(loaderOff())
      }

    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }

  }






  const handleAddBonus = async () => {
    const formData = {
      bonus_name: formvalues.bonus_name.value,
      bonus_description: formvalues.bonus_description.value,
      bonus_criteria: formvalues.bonus_criteria.value
    }
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.post("https://dev-backoffice.tribe.sn/bonus", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data != null) {
        console.log("Bonus Added Successfully")
        handleClose();
        fetchBonus();
        fetchBonus1();
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  }

  const handleAddGift = async () => {

    const formData = {
      gift_name: formvalues.gift_name.value,
      gift_description: formvalues.gift_description.value,
      gift_criteria: formvalues.gift_criteria.value
    }
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.post("https://dev-backoffice.tribe.sn/gift", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data != null) {
        console.log("Gifts Created successfully")
        handleClose1();
        fetchGifts();
        fetchGifts1();
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  }



  const handleAddAchievemnt = async () => {
    const formdata = {
      achievement_name: formvalues.achievement_name.value,
      achievement_description: formvalues.achievement_description.value,
      achievement_criteria: formvalues.achievement_criteria.value
    }
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.post("https://dev-backoffice.tribe.sn/achievement", formdata, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data != null) {
        console.log("Achievements are added  successfully")
        handleClose();
        fetchAchievements();
        fetchAchievements1();
        dispatch(loaderOff())

      }

    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  }





  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const filteredRows = rows.filter((row) =>
  Object.values(row).some(
  (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
  )
  );
  
  const filteredRows1 = rows1.filter((row) =>
  Object.values(row).some(
  (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery1.toLowerCase())
  )
  );

  const filteredRows2 = rows2.filter((row) =>
  Object.values(row).some(
  (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery2.toLowerCase())
  )
  );

  const filteredRows3 = rows3.filter((row) =>
  Object.values(row).some(
  (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery3.toLowerCase())
  )
  );

  const filteredRows4 = rows4.filter((row) =>
  Object.values(row).some(
  (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery4.toLowerCase())
  )
  );

  const filteredRows5 = rows5.filter((row) =>
  Object.values(row).some(
  (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery5.toLowerCase())
  )
  );
  
  const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
  setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event: { target: { value: string | number; }; }) => {
  setRowsPerPage(+event.target.value);
  setPage(0);
  };


  const fetchBonus1 = () => {
    const token = sessionStorage.getItem("jwtToken");
    axios.get("https://dev-backoffice.tribe.sn/bonus/active", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const backendData = response.data;
        const frontendData = backendData.map((backendRow: any) => ({
          Bonus: backendRow.bonus_name,
          Bonus_Description: backendRow.bonus_description,
          Bonus_Criteria: backendRow.bonus_criteria,
          bonus_id: backendRow.bonus_id,
        }));
        setRows(frontendData);
        console.log(frontendData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };





  const fetchBonus = () => {
    const token = sessionStorage.getItem("jwtToken");
    axios.get("https://dev-backoffice.tribe.sn/bonus", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const backendData = response.data;
        const frontendData = backendData.map((backendRow: any) => ({
          Bonus: backendRow.bonus_name,
          Bonus_Description: backendRow.bonus_description,
          Bonus_Criteria: backendRow.bonus_criteria,
          bonus_id: backendRow.bonus_id,
        }));

        setRows1(frontendData);
        console.log(frontendData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };


  const fetchAchievements = () => {
    const token = sessionStorage.getItem("jwtToken");
    axios.get("https://dev-backoffice.tribe.sn/achievement/active", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const backendData = response.data;
        const frontendData = backendData.map((backendRow: any) => ({
          Sl_No: backendRow.achievement_name,
          Achievement: backendRow.achievement_name,
          Achievement_Description: backendRow.achievement_description,
          Achievement_Criteria: backendRow.achievement_criteria,
          achievement_id: backendRow.achievement_id,
        }));

        setRows2(frontendData);
        console.log(frontendData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const fetchAchievements1 = () => {
    const token = sessionStorage.getItem("jwtToken");
    axios.get("https://dev-backoffice.tribe.sn/achievement", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const backendData = response.data;
        const frontendData = backendData.map((backendRow: any) => ({
          Sl_No: backendRow.achievement_id,
          Achievements: backendRow.achievement_name,
          Achievements_Description: backendRow.achievement_description,
          Achievement_Criteria: backendRow.achievement_criteria,
          status: backendRow.status,
        }));


        setRows4(frontendData);
        console.log(frontendData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };



  const fetchGifts = () => {
    const token = sessionStorage.getItem("jwtToken");
    axios.get("https://dev-backoffice.tribe.sn/gift/active", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const backendData = response.data;
        const frontendData = backendData.map((backendRow: any) => ({
          Gift: backendRow.gift_name,
          Gift_Description: backendRow.gift_description,
          Gift_Criteria: backendRow.gift_criteria,
          gift_id: backendRow.gift_id,
        }));





        setRows3(frontendData);
        console.log(frontendData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };


  const fetchGifts1 = () => {
    const token = sessionStorage.getItem("jwtToken");
    axios.get("https://dev-backoffice.tribe.sn/gift", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const backendData = response.data;
        const frontendData = backendData.map((backendRow: any) => ({
          Sl_No: backendRow.gift_id,
          Gifts: backendRow.gift_name,
          Gift_Description: backendRow.gift_description,
          Gift_Criteria: backendRow.gift_criteria,
          status: backendRow.status,
        }));
        setRows5(frontendData);
        console.log(frontendData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  useEffect(() => {
    fetchBonus();
    fetchBonus1();
    fetchAchievements();
    fetchGifts();
    fetchAchievements1();
    fetchGifts1();

  }, []);
  return (
    <div>
      <div style={{ padding: "53px 700px 52px 320px " }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "white" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Bonus" {...a11yProps(0)} />
              <Tab label="Achievements & Gifts" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
      </div>
      {/* <CustomTabPanel value={value} index={0}>
        Item One
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel> */}

      <div>
        <CustomTabPanel value={value} index={0}>
          <div
            style={{
              padding: "52px 18px 55px 320px",
            }}
          >
            <span style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ fontFamily: '', fontSize: '16px', fontWeight: '700' }}>
                  Bonus
                </div>
                {/* <div
                  style={{
                    height: "40px",
                    width: "389px",
                  }}
                >
                  <input type="text" placeholder="Search..." />
                </div> */}


              </div>


              <div style={{ display: "flex", flexDirection: 'row' }}>
                <div style={{ paddingRight: '10px' }}>
                  <Button
                    style={{
                      color: "black",
                      backgroundColor: "#8E47E6",

                    }}
                    onClick={handleOpen}
                    startIcon={<AddIcon />}
                  >
                    Add Bonus
                  </Button>
                </div>
                <Modal
                  open={open}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div style={{
                      display: "flex",
                      justifyContent: 'end'
                    }}
                      onClick={handleClose}>
                      <CloseIcon />

                    </div>
                    <div style={{
                      display: "flex",
                      justifyContent: "center"
                    }}>

                      <h1 >
                        Add Bonus
                      </h1>

                    </div>

                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <div style={{
                          fontSize: '16px',
                          fontWeight: '700',

                        }}>Bonus Name</div>
                        <TextField id="filled-basic" variant="filled"
                          name="bonus_name"
                          value={formvalues.bonus_name.value}
                          onChange={(e) => handleChangee("bonus_name", e.target.value)}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                              border: '1px solid #8E47E6',

                            }
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: '700',
                          }}>Bonus Description</div>
                        <TextField id="filled-basic" variant="filled"
                          name="bonus_description"
                          value={formvalues.bonus_description.value}
                          onChange={(e) => handleChangee("bonus_description", e.target.value)}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                              border: '1px solid #8E47E6',

                            }
                          }} />
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: '700',
                          }}>Bonus Criteria</div>
                        <TextField id="filled-basic" variant="filled"
                          name="bonus_criteria"
                          value={formvalues.bonus_criteria.value}
                          onChange={(e) => handleChangee("bonus_criteria", e.target.value)}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                              border: '1px solid #8E47E6',

                            }
                          }} />
                      </Grid>
                      <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'end'
                      }}>

                        <Button style={{
                          width: '220px',
                          backgroundColor: '#8E47E6',
                          color: "black",
                          fontSize: '16px',
                          fontWeight: '700',
                        }}
                          onClick={handleAddBonus}
                        >

                          Add Bonus
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>




                <Modal
                  open={open2}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style1}>
                    <div style={{
                      display: "flex",
                      justifyContent: 'end'
                    }}
                      onClick={handleClose2}>
                      <CloseIcon />

                    </div>
                    <div style={{
                      display: "flex",
                      justifyContent: "center"
                    }}>

                      <h1 >
                        Update Bonus
                      </h1>

                    </div>

                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <div style={{
                          fontSize: '16px',
                          fontWeight: '700',

                        }}>Bonus Name</div>
                        <TextField id="filled-basic" variant="filled"
                          name="bonus_name"
                          value={formvalues.bonus_name.value}
                          onChange={(e) => handleChangee("bonus_name", e.target.value)}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                              border: '1px solid #8E47E6',

                            }
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: '700',
                          }}>Bonus Description</div>
                        <TextField id="filled-basic" variant="filled"
                          name="bonus_description"
                          value={formvalues.bonus_description.value}
                          onChange={(e) => handleChangee("bonus_description", e.target.value)}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                              border: '1px solid #8E47E6',

                            }
                          }} />
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: '700',
                          }}>Bonus Criteria</div>
                        <TextField id="filled-basic" variant="filled"
                          name="bonus_criteria"
                          value={formvalues.bonus_criteria.value}
                          onChange={(e) => handleChangee("bonus_criteria", e.target.value)}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                              border: '1px solid #8E47E6',

                            }
                          }} />
                      </Grid>
                      <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'end'
                      }}>

                        <Button style={{
                          width: '220px',
                          backgroundColor: '#8E47E6',
                          color: "black",
                          fontSize: '16px',
                          fontWeight: '700',
                        }}
                          onClick={() => handleEditt(bonusId)}                        >

                          Add Bonus
                        </Button>
                      </Grid>
                    </Grid>



                  </Box>
                </Modal>




                {/* <Button
                  style={{
                    color: "black",
                    backgroundColor: "#8E47E6",
                  }}
                  endIcon={<DownloadIcon />}
                >
                  Export{" "}
                </Button> */}
              </div>
            </span>

            <Box
              sx={{
                color: "black",
                paddingTop: "25px",
                marginBottom:'50px'
              }}
            >
              <Paper sx={{ overflow: "hidden" }}>

              <div style={{display:'flex',
  justifyContent:'start'
  }}>
  <TextField
  label="Search"
  value={searchQuery}
  onChange={(e)=>setSearchQuery(e.target.value)}
  variant="outlined"
  margin="normal"
  inputProps={{
  style:{
  width:'200px',
  
  
  }
  }}
  
  />
  </div>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth, color: column.color }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.Bonus_Description}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {column.id === "bonus_id" ? (
                                      <div style={{ display: "flex", flexDirection: 'row' }}>
                                        <p onClick={() => handleEdit(row.bonus_id)}><CreateIcon /></p>
                                        <p onClick={() => handleDelete(row.bonus_id)}><DeleteIcon /></p>
                                      </div>
                                    ) : (
                                      column.format && typeof value === "number" ? (
                                        column.format(value)
                                      ) : (
                                        value
                                      )
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredRows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{
                  fontFamily: '',
                  fontSize: '16px',
                  fontWeight: '700',
                }}>Bonus History</div>
                <div>
                  {/* <div
                    style={{
                      height: "40px",
                      width: "389px",
                    }}
                  >
                    <input type="text" placeholder="Search..." />
                  </div> */}
                </div>
              </div>

              <div>
                {/* <Button
                  style={{
                    color: "black",
                    backgroundColor: "#8E47E6",
                  }}
                  endIcon={<DownloadIcon />}
                >
                  Export{" "}
                </Button> */}
              </div>
            </span>

            <Box
              sx={{
                color: "black",
              }}
            >
              <div></div>
              <Paper sx={{ overflow: "hidden" }}>
              <div style={{display:'flex',
  justifyContent:'start'
  }}>
  <TextField
  label="Search"
  value={searchQuery1}
  onChange={(e)=>setSearchQuery1(e.target.value)}
  variant="outlined"
  margin="normal"
  inputProps={{
  style:{
  width:'200px',
  
  
  }
  }}
  
  />
  </div>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns2.map((column2) => (
                          <TableCell
                            key={column2.id}
                            align={column2.align}
                            style={{ minWidth: column2.minWidth, color: column2.color }}
                          >
                            {column2.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRows1
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.Bonus}
                            >
                              {columns2.map((column2) => {
                                const value = row[column2.id];
                                return (
                                  <TableCell
                                    key={column2.id}
                                    align={column2.align}
                                  >
                                    {column2.format && typeof value === "number"
                                      ? column2.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredRows1.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </div>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <div
            style={{
              padding: "52px 18px 55px 320px",
            }}
          >
            <span style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>

                <div
                  style={{
                    fontFamily: '',
                    fontSize: '16px',
                    fontWeight: '700'
                  }}
                >
                  Achievements
                </div>
                {/* <div
                  style={{
                    height: "40px",
                    width: "389px",
                  }}
                >
                  <input type="text" placeholder="Search..." />
                </div> */}

              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'row'
              }}>
                <div style={{ paddingRight: '15px' }}>
                  <Button
                    style={{
                      color: "black",
                      backgroundColor: "#8E47E6",

                    }}
                    onClick={handleOpen}
                    startIcon={<AddIcon />}
                  >
                    Add Achievemnt
                  </Button>
                </div>

                <Modal
                  open={open}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style1}>
                    <div style={{
                      display: "flex",
                      justifyContent: 'end'
                    }}
                      onClick={handleClose}>
                      <CloseIcon />
                    </div>
                    <div style={{
                      display: "flex",
                      justifyContent: "center"
                    }}>
                      <h1 >
                        Add Achievemnt
                      </h1>
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <div style={{
                          fontSize: '16px',
                          fontWeight: '700',

                        }}>Achievemnt Name</div>
                        <TextField id="filled-basic" variant="filled"
                          name="achievement_name"
                          value={formvalues.achievement_name.value}
                          onChange={(e) => handleChangee("achievement_name", e.target.value)}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                              border: '1px solid #8E47E6',

                            }
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: '700',
                           
                          }}>Achievemnt Description</div>
                        <TextField id="filled-basic" variant="filled"
                          name="achievement_description"
                          value={formvalues.achievement_description.value}
                          onChange={(e) => handleChangee("achievement_description", e.target.value)}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                              border: '1px solid #8E47E6',
                              
                            }
                          }} />
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: '700',
                            
                          }}>Achievemnt Criteria</div>
                        <TextField id="filled-basic" variant="filled"
                          name="achievement_criteria"
                          value={formvalues.achievement_criteria.value}
                          onChange={(e) => handleChangee("achievement_criteria", e.target.value)}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                            border: '1px solid #8E47E6',
                             }
                          }} />

                      </Grid>
                      <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'end'
                      }}>

                        <Button style={{
                          width: '250px',
                          backgroundColor: '#8E47E6',
                          color: "black",
                          fontSize: '16px',
                          fontWeight: '700'
                        }}
                          onClick={handleAddAchievemnt}
                        >

                          Add Achievemnt
                        </Button>
                      </Grid>
                    </Grid>



                  </Box>
                </Modal>


                <div>
                  {/* <Button
                    style={{
                      color: "black",
                      backgroundColor: "#8E47E6",
                    }}
                    endIcon={<DownloadIcon />}
                  >
                    Export{" "}
                  </Button> */}
                </div>
              </div>
            </span>

            <Box
              sx={{
                
                color: "black",
                marginBottom:'50px'
              }}
            >
              <div></div>
              <Paper sx={{ overflow: "hidden" }}>
              <div style={{display:'flex',
  justifyContent:'start'
  }}>
  <TextField
  label="Search"
  value={searchQuery2}
  onChange={(e)=>setSearchQuery2(e.target.value)}
  variant="outlined"
  margin="normal"
  inputProps={{
  style:{
  width:'200px',
  
  
  }
  }}
  
  />
  </div>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns3.map((column3) => (
                          <TableCell
                            key={column3.id}
                            align={column3.align}
                            style={{ minWidth: column3.minWidth, color: column3.color }}
                          >
                            {column3.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRows2
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.Achievement_Description}
                            >
                              {columns3.map((column3) => {
                                const value = row[column3.id];
                                return (
                                  <TableCell key={column3.id} align={column3.align}>
                                    {column3.id === "achievement_id" ? (
                                      <div style={{ display: "flex", flexDirection: 'row' }}>
                                        <p onClick={() => handleEditAchievement(row.achievement_id)}><CreateIcon /></p>
                                        <p onClick={() => handleDeleteAchievement(row.achievement_id)}><DeleteIcon /></p>
                                      </div>
                                    ) : (
                                      column3.format && typeof value === "number" ? (
                                        column3.format(value)
                                      ) : (
                                        value
                                      )
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredRows2.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>

            <span style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{
                  fontFamily: '',
                  fontSize: '16px',
                  fontWeight: '700',
                }}>Gifts</div>

                {/* <div
                  style={{
                    height: "40px",
                    width: "389px",
                  }}
                >
                  <input type="text" placeholder="Search..." />
                </div> */}

                {/* <div>
                  <Button
                    style={{
                      color: "black",
                      backgroundColor: "#D9D9D9",
                    }}
                  >
                    Export{" "}
                  </Button>
                </div> */}
              </div>

              {/* <div>
                <Button
                  style={{
                    color: "black",
                    backgroundColor: "#D9D9D9",
                  }}
                  startIcon={<CardGiftcardIcon />}
                >
                  Set a Gift
                </Button>
              </div> */}

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ paddingRight: '15px' }}>
                  <Button
                    style={{
                      color: "black",
                      backgroundColor: "#8E47E6",
                    }}
                    onClick={handleOpen1}
                    startIcon={<CardGiftcardIcon />}
                  >
                    Set a Gift
                  </Button>
                </div>
                <Modal
                  open={openAchievement}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style1}>
                    <div style={{
                      display: "flex",
                      justifyContent: 'end'
                    }}
                      onClick={handleClose3}>
                      <CloseIcon />

                    </div>
                    <div style={{
                      display: "flex",
                      justifyContent: "center"
                    }}>

                      <h1 >
                        Update Achievements
                      </h1>
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <div style={{
                          fontSize: '16px',
                          fontWeight: '700',

                        }}>Achievement Name</div>
                        <TextField id="filled-basic" variant="filled"
                          name="achievement_name"
                          value={formvalues.achievement_name.value}
                          onChange={(e) => handleChangee("achievement_name", e.target.value)}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                               border: '1px solid #8E47E6',
                             
                            }
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: '700',
                           }}>Achievemnt Description</div>
                        <TextField id="filled-basic" variant="filled"
                          name="achievement_description"
                          value={formvalues.achievement_description.value}
                          onChange={(e) => handleChangee("achievement_description", e.target.value)}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                                border: '1px solid #8E47E6',
                              
                            }
                          }} />
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: '700',
                           }}>Achievemnt Criteria</div>
                        <TextField id="filled-basic" variant="filled"
                          name="achievement_criteria"
                          value={formvalues.achievement_criteria.value}
                          onChange={(e) => handleChangee("achievement_criteria", e.target.value)}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                                border: '1px solid #8E47E6',
                              
                            }
                          }} />

                      </Grid>
                      <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'end'
                      }}>

                        <Button style={{
                          width: '250px',
                          backgroundColor: '#8E47E6',
                          color: "black",
                          fontSize: '16px',
                          fontWeight: '700'
                        }}
                          onClick={() => handleEdittAchieve(achievementId)}
                        >

                          {/* syed// */}

                          Update
                        </Button>
                      </Grid>
                    </Grid>



                  </Box>
                </Modal>

                <Modal
                  open={openGift}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style1}>
                    <div style={{
                      display: "flex",
                      justifyContent: 'end'
                    }}
                      onClick={handleClose4}>
                      <CloseIcon />

                    </div>
                    <div style={{
                      display: "flex",
                      justifyContent: "center"
                    }}>

                      <h1 >
                        Update Gift
                      </h1>
                    </div>

                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                      <div style={{
                        fontSize: '16px',
                        fontWeight: '700',

                      }}>Gift Name</div>
                      <TextField id="filled-basic" variant="filled"
                        name="gift_name"
                        value={formvalues.gift_name.value}
                        onChange={(e) => handleChangee("gift_name", e.target.value)}
                        InputProps={{
                          disableUnderline: true,

                          style: {
                        border: '1px solid #8E47E6',
                        
                          }
                        }} />
                      </Grid>
                      <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: '16px',
                          fontWeight: '700',
                         }}>Gift Description</div>
                      <TextField id="filled-basic" variant="filled"
                        name="gift_description"
                        value={formvalues.gift_description.value}
                        onChange={(e) => handleChangee("gift_description", e.target.value)}
                        InputProps={{
                          disableUnderline: true,

                          style: {
                              border: '1px solid #8E47E6',
                           }
                        }} />
                      </Grid>
                      <Grid item xs={12}>
                      <div
                        style={{
                          fontSize: '16px',
                          fontWeight: '700',
                         }}>Gift Criteria</div>
                      <TextField id="filled-basic" variant="filled"
                        name="gift_criteria"
                        value={formvalues.gift_criteria.value}
                        onChange={(e) => handleChangee("gift_criteria", e.target.value)}
                        InputProps={{
                          disableUnderline: true,

                          style: {
                             border: '1px solid #8E47E6',
                            
                          }
                        }} />

                      </Grid>
                      <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'end'
                      }}>
                       <Button style={{
                        width: '250px',
                        backgroundColor: '#8E47E6',
                        color: "black",
                        fontSize: '16px',
                        fontWeight: '700'
                      }}
                        onClick={() => handleEdittGift(giftId)}
                      >

                        {/* syed// */}

                        Update
                      </Button>
                      </Grid>
                    </Grid>


                     

                  </Box>
                </Modal>

                <Modal
                  open={open1}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style1}>
                    <div style={{
                      display: "flex",
                      justifyContent: 'end'
                    }}
                      onClick={handleClose1}>
                      <CloseIcon />

                    </div>
                    <div style={{
                      display: "flex",
                      justifyContent: "center"
                    }}>

                      <h1 >
                        Add Gifts
                      </h1>

                    </div>

                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <div style={{
                          fontSize: '16px',
                          fontWeight: '700',

                        }}>Gift Name</div>
                        <TextField id="filled-basic" variant="filled"
                          name="gift_name"
                          value={formvalues.gift_name.value}
                          onChange={(e) => handleChangee("gift_name", e.target.value)}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                                border: '1px solid #8E47E6',
                             }
                          }} />
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: '700',
                           }}>Gift Description</div>
                        <TextField id="filled-basic" variant="filled"
                          name="gift_description"
                          value={formvalues.gift_description.value}
                          onChange={(e) => handleChangee("gift_description", e.target.value)}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                                border: '1px solid #8E47E6',
                           
                            }
                          }} />
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: '700',
                           }}>Gift Criteria</div>
                        <TextField id="filled-basic" variant="filled"
                          name="gift_criteria"
                          value={formvalues.gift_criteria.value}
                          onChange={(e) => handleChangee("gift_criteria", e.target.value)}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                          border: '1px solid #8E47E6',
                        
                            }
                          }} />

                      </Grid>
                      <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'end'
                      }}>

                        <Button style={{
                          width: '250px',
                          backgroundColor: '#8E47E6',
                          color: "black",
                          fontSize: '16px',
                          fontWeight: '700'
                        }}
                          onClick={handleAddGift}
                        >

                          Add Gift
                        </Button>
                      </Grid>
                    </Grid>





                  </Box>
                </Modal>


                <div>
                  {/* <Button
                    style={{
                      color: "black",
                      backgroundColor: "#8E47E6",
                    }}
                    endIcon={<DownloadIcon />}
                  >
                    Export{" "}
                  </Button> */}
                </div>
              </div>
            </span>

            <Box
              sx={{
                color: "black",
               }}
            >
              <div></div>
              <Paper sx={{ overflow: "hidden" }}>
              <div style={{display:'flex',
  justifyContent:'start'
  }}>
  <TextField
  label="Search"
  value={searchQuery3}
  onChange={(e)=>setSearchQuery3(e.target.value)}
  variant="outlined"
  margin="normal"
  inputProps={{
  style:{
  width:'200px',
  
  
  }
  }}
  
  />
  </div>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns4.map((column4) => (
                          <TableCell
                            key={column4.id}
                            align={column4.align}
                            style={{ minWidth: column4.minWidth, color: column4.color }}
                          >
                            {column4.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRows3
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.Gift_Description}
                            >
                              {columns4.map((column4) => {
                                const value = row[column4.id];
                                return (
                                  <TableCell key={column4.id} align={column4.align}>
                                    {column4.id === "gift_id" ? (
                                      <div style={{ display: "flex", flexDirection: 'row' }}>
                                        <p onClick={() => handleEditGift(row.gift_id)}><CreateIcon /></p>
                                        <p onClick={() => handleDeleteGift(row.gift_id)}><DeleteIcon /></p>
                                      </div>
                                    ) : (
                                      column4.format && typeof value === "number" ? (
                                        column4.format(value)
                                      ) : (
                                        value
                                      )
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredRows3.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>

            <span style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "row",paddingTop:"50px" }}>
                <div style={{ fontFamily: '', fontStyle: '16px', fontWeight: '700' }}>Achievement History</div>

                {/* <div
                  style={{
                    height: "40px",
                    width: "389px",
                  }}
                >
                  <input type="text" placeholder="Search..." />
                </div> */}
              </div>

              <div>
                {/* <Button
                  style={{
                    color: "black",
                    backgroundColor: "#8E47E6",
                  }}
                  endIcon={<DownloadIcon />}
                >
                  Export{" "}
                </Button> */}
              </div>
            </span>

            <Box
              sx={{
                // height: "550px",
                marginBottom:'50px',
                color: "black",
              }}
            >
              <div></div>
              <Paper sx={{ overflow: "hidden" }}>
              <div style={{display:'flex',
  justifyContent:'start'
  }}>
  <TextField
  label="Search"
  value={searchQuery4}
  onChange={(e)=>setSearchQuery4(e.target.value)}
  variant="outlined"
  margin="normal"
  inputProps={{
  style:{
  width:'200px',
  
  
  }
  }}
  
  />
  </div>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns5.map((column5) => (
                          <TableCell
                            key={column5.id}
                            align={column5.align}
                            style={{ minWidth: column5.minWidth, color: column5.color }}
                          >
                            {column5.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRows4
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.Achievements_Description}
                            >
                              {columns5.map((column5) => {
                                const value = row[column5.id];
                                return (
                                  <TableCell
                                    key={column5.id}
                                    align={column5.align}
                                  >
                                    {column5.format && typeof value === "number"
                                      ? column5.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredRows4.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>

            <span style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ fontFamily: '', fontSize: '16px', fontWeight: '700' }}>Gift History</div>

                {/* <div
                  style={{
                    height: "40px",
                    width: "389px",
                  }}
                >
                  <input type="text" placeholder="Search..." />
                </div> */}
              </div>

              <div>
                {/* <Button
                  style={{
                    color: "black",
                    backgroundColor: "#8E47E6",
                  }}
                  endIcon={<DownloadIcon />}
                >
                  Export{" "}
                </Button> */}
              </div>
            </span>

            <Box
              sx={{
                height: "550px",
                color: "black",
              }}
            >
              <div></div>
              <Paper sx={{ overflow: "hidden" }}>
              <div style={{display:'flex',
  justifyContent:'start'
  }}>
  <TextField
  label="Search"
  value={searchQuery5}
  onChange={(e)=>setSearchQuery5(e.target.value)}
  variant="outlined"
  margin="normal"
  inputProps={{
  style:{
  width:'200px',
  
  
  }
  }}
  
  />
  </div>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns6.map((column6) => (
                          <TableCell
                            key={column6.id}
                            align={column6.align}
                            style={{ minWidth: column6.minWidth, color: column6.color }}
                          >
                            {column6.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRows5
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.Gift_Description}
                            >
                              {columns6.map((column6) => {
                                const value = row[column6.id];
                                return (
                                  <TableCell
                                    key={column6.id}
                                    align={column6.align}
                                  >
                                    {column6.format && typeof value === "number"
                                      ? column6.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredRows5.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </div>
        </CustomTabPanel>
      </div>
    </div>
  );
}
