import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { useBonusForm } from "../custom_forms/BonusForm";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { loaderOff, loaderOn, logout, openSnackbar } from "../redux/actions";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  containsOnlyCharacters,
  emailRegex,
  passwordRegex,
} from "../CustomRegex/CustomRegex";
import {
  addBonus,
  getActiveBonus,
  getActiveToken,
  getBonus,
  getBonusId,
  getToken,
  updateBonus,
  updateBonusDelete,
} from "../utilities/backofficeservice";
import CustomTextField from "../customtextfield/CustomTextField";
import { getUser } from "../utilities/adminservice";
import CommonDialog from "../commondialog/confirm";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { CustomPrimaryButton } from "../customtextfield/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "../redux/store";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid white",
  borderRadius: "18px",
  // boxShadow: 24,
  p: 3,
};

const overlayStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0)", // Semi-transparent overlay
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 10000,
};
const dateTimePickerStyle = {
  width: "220px",
  border: "1px solid #8E47E6",
  borderRadius: "8px",
  marginTop: "10px",
  outline: "none",
};
const popupStyle: React.CSSProperties = {
  background: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
  position: "relative", // Ensure relative positioning for close icon
};

const closeIconStyle: React.CSSProperties = {
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
};
const popupContentStyle: React.CSSProperties = {
  textAlign: "center",
  marginTop: "30px", // Adjust the margin to make space for the close icon
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// | "bonus_id"
interface Column {
  id:
    | "Bonus"
    | "Bonus_Description"
    | "Bonus_Criteria"
    | "start_date"
    | "end_date"
    | "bonus_id"
    | "no_of_tokens"
    | "token_category_details"
    | "bonus_type"
    | "win_id";
  label: string;
  minWidth?: number;
  align?: "left";
  format?: (value: number) => string;
  color?: string;
}

const columns: readonly Column[] = [
  { id: "Bonus", label: "Bonus", color: "#8E47E6" },
  { id: "Bonus_Description", label: "Bonus Description", color: "#8E47E6" },
  {
    id: "Bonus_Criteria",
    label: "Bonus Criteria",
    format: (value: number) => value.toLocaleString("en-US"),
    color: "#8E47E6",
  },
  { id: "bonus_type", label: "Bonus Type", color: "#8E47E6" },
  { id: "start_date", label: "Start Date", color: "#8E47E6" },
  { id: "end_date", label: "End Date", color: "#8E47E6" },
  { id: "no_of_tokens", label: "Token No.", color: "#8E47E6" },
  { id: "token_category_details", label: "Details", color: "#8E47E6" },
  { id: "win_id", label: "Winners Id", color: "#8E47E6" },
  { id: "bonus_id", label: "Action", color: "#8E47E6" },
];

interface Data {
  Bonus: string;
  Bonus_Description: string;
  Bonus_Criteria: string;
  start_date: string;
  end_date: string;
  bonus_id: number;
  no_of_tokens: number;
  // token_id: number;
  token_category_details: string;
  bonus_type: string;
  win_id: number;
}

interface Column2 {
  id:
    | "Bonus"
    | "Bonus_Description"
    | "Bonus_Criteria"
    | "start_date"
    | "end_date"
    | "no_of_tokens"
    | "token_category_details"
    | "win_id"
    | "bonus_type";

  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}

const columns2: readonly Column2[] = [
  { id: "Bonus", label: "Bonus", color: "#8E47E6" },
  { id: "Bonus_Description", label: "Bonus Description", color: "#8E47E6" },
  {
    id: "Bonus_Criteria",
    label: "Bonus Criteria",
    format: (value: number) => value.toLocaleString("en-US"),
    color: "#8E47E6",
  },
  { id: "bonus_type", label: "Bonus Type", color: "#8E47E6" },
  { id: "start_date", label: "Start Date", color: "#8E47E6" },
  { id: "end_date", label: "End Date", color: "#8E47E6" },
  { id: "no_of_tokens", label: "Token No.", color: "#8E47E6" },
  { id: "token_category_details", label: "Details", color: "#8E47E6" },

  { id: "win_id", label: "Winners Id", color: "#8E47E6" },
];

interface Data2 {
  Bonus: string;
  Bonus_Description: string;
  Bonus_Criteria: string;
  start_date: string;
  end_date: string;
  no_of_tokens: number;
  token_category_details: string;
  win_id: number;
  bonus_type: string;
}

interface Category {
  token_id: number;
  category: string;
  token_category_details: string;
  discovery: number;
  collector: number;
}

export default function Bonus() {
  const [value, setValue] = React.useState(0);
  const [rows, setRows] = useState<Data[]>([]);
  const [bonusId, setBonusId] = React.useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [rows1, setRows1] = useState<Data2[]>([]);
  const [open, setOpen] = React.useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [categories, setCategories] = useState([]);
  const [id, setId] = useState<number | null>(null);
  const [selectedBonusId, setSelectedBonusId] = useState<any | null>(null);
  const [showContent, setShowContent] = useState(false);
  const [openDialog, setopenDialog] = React.useState(false);

  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isTokenExpired = useSelector((state: any) => state.auth.isTokenExpired);
  console.log("syed" + isLoggedIn);
  useEffect(() => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken || isTokenExpired) {
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    } else if (!isLoggedIn) {
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    }
  }, [isLoggedIn, isTokenExpired]);

  const handleCloseDialog = () => {
    setopenDialog(false);
    setShowContent(false);
  };
  // const handleChangeee = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const selectedTokenId = event.target.value
  //     ? parseInt(event.target.value, 10)
  //     : null;

  //   console.log("Selected Token ID:", selectedTokenId);
  //   setId(selectedTokenId);
  // };
  const [selectedValue1, setSelectedValue1] = useState("");

  const handleChangeee = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setV1(false)
    setSelectedValue1(event.target.value);
    if (event.target.value == "") {
      setV1(true);
    } else {
      setV1(false);
    }
    const selectedTokenId = event.target.value
      ? parseInt(event.target.value, 10)
      : null;

    console.log("Selected Token ID:", selectedTokenId);
    setId(selectedTokenId);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setV1(false);
    setV2(false);
    setOpen(false);
    setSelectedValue1("");
    setSelectedValue("");
    setFormValues({
      bonus_name: {
        value: "",
        error: false,
        errorMessage: "Please enter valid bonus name",
      },
      bonus_description: {
        value: "",
        error: false,
        errorMessage: "Please enter valid bonus description",
      },
      bonus_criteria: {
        value: "",
        error: false,
        errorMessage: "Please enter valid bonus criteria",
      },
      start_date: {
        value: "",
        error: false,
        errorMessage: "Please enter valid start date",
      },
      end_date: {
        value: "",
        error: false,
        errorMessage: "Please enter valid end date",
      },
      no_of_tokens: {
        value: "",
        error: false,
        errorMessage: "Please enter valid tokens",
      },
      bonus_type: {
        value: "",
        error: false,
        errorMessage: "Please enter valid tokens",
      },
      token_category_details: {
        value: "",
        error: false,
        errorMessage: "Please enter valid tokens",
      },
    });
  };

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = (bonusId: any) => {
    setBonusId(bonusId);
    setOpen2(true);
  };

  const handleClose2 = () => {
    setBonusId(null);
    setOpen2(false);

    Object.keys(formValues).forEach((key: any) => {
      formValues[key].value = "";
    });
    setSelectedValue1("");
    setSelectedValue("");
    setV1(false);
    setV2(false);
    setFormValues({
      bonus_name: {
        value: "",
        error: false,
        errorMessage: "Please enter valid bonus name",
      },
      bonus_description: {
        value: "",
        error: false,
        errorMessage: "Please enter valid bonus description",
      },
      bonus_criteria: {
        value: "",
        error: false,
        errorMessage: "Please enter valid bonus criteria",
      },
      start_date: {
        value: "",
        error: false,
        errorMessage: "Please enter valid start date",
      },
      end_date: {
        value: "",
        error: false,
        errorMessage: "Please enter valid end date",
      },
      no_of_tokens: {
        value: "",
        error: false,
        errorMessage: "Please enter valid tokens",
      },
      bonus_type: {
        value: "",
        error: false,
        errorMessage: "Please enter valid tokens",
      },
      token_category_details: {
        value: "",
        error: false,
        errorMessage: "Please enter valid tokens",
      },
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const filteredRows = rows.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  const filteredRows1 = rows1.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery1.toLowerCase())
    )
  );

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { formValues, setFormValues } = useBonusForm();

  // const handleChangee = (e: React.ChangeEvent<HTMLInputElement>) => {
  const handleChangee = (e: { name: string; value: string } | any) => {
    const { name, value } = e.target || e;

    if (
      name === "bonus_name" ||
      name === "bonus_description" ||
      name === "bonus_criteria" ||
      name === "start_date" ||
      name === "end_date" ||
      name === "bonus_type" ||
      name === "token_category_details" ||
      name === "token_category"
    ) {
      const isValidValue: boolean = containsOnlyCharacters.test(value);

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: {
          ...prevFormValues[name],
          value,
          error: !isValidValue,
        },
      }));
    } else if (name === "no_of_tokens") {
      const isValidInteger: boolean = /^\d+$/.test(value);

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: {
          ...prevFormValues[name],
          value,
          error: !isValidInteger,
        },
      }));
    } else {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: {
          ...prevFormValues[name],
          value,
          error: value === "" ? true : false,
        },
      }));
    }
  };

  // let validateForm = (type: any) => {
  //   let invalidSubmit = false;

  //   // Validate other fields
  //   const formFields = ["bonus_name", "bonus_description", "bonus_criteria"];
  //   formFields.forEach((fieldName) => {
  //     const value = formValues[fieldName].value;
  //     const isValid = containsOnlyCharacters.test(value);

  //     setFormValues({
  //       ...formValues,
  //       [fieldName]: {
  //         ...formValues[fieldName],
  //         error: !isValid,
  //       },
  //     });

  //     invalidSubmit = !isValid;
  //   });

  //   // Check for required fields
  //   if (type === "bonus-add") {
  //     formFields.forEach((fieldName) => {
  //       const value = formValues[fieldName].value;
  //       if (value === "") {
  //         setFormValues({
  //           ...formValues,
  //           [fieldName]: {
  //             ...formValues[fieldName],
  //             error: true,
  //           },
  //         });
  //         invalidSubmit = true;
  //       }
  //     });
  //   }

  //   return invalidSubmit;
  // };
  let validateForm = (type: any) => {
    let invalidSubmit = false;
    const updatedFormValues = { ...formValues }; // Create a copy to accumulate changes

    // Validate other fields
    const formFields = [
      "bonus_name",
      "bonus_description",
      "bonus_criteria",
      "no_of_tokens",
    ];

    formFields.forEach((fieldName) => {
      const value = formValues[fieldName].value;
      // const isValid = containsOnlyCharacters.test(value);
      let isValid = true;

      if (fieldName === "no_of_tokens") {
        isValid = /^\d+$/.test(value); // Check if value is an integer
      } else {
        isValid = containsOnlyCharacters.test(value);
      }

      updatedFormValues[fieldName] = {
        ...formValues[fieldName],
        error: !isValid,
      };

      invalidSubmit = invalidSubmit || !isValid;
    });

    // Check for required fields
    if (type === "bonus-add") {
      formFields.forEach((fieldName) => {
        const value = formValues[fieldName].value;

        if (value === "") {
          updatedFormValues[fieldName] = {
            ...formValues[fieldName],
            error: true,
          };
          invalidSubmit = true;
        }
      });

      // Check for start date
      if (formValues["start_date"].value === "") {
        updatedFormValues["start_date"] = {
          ...formValues["start_date"],
          error: true,
        };
        invalidSubmit = true;
      }
    }

    // Check for end date
    if (formValues["end_date"].value === "") {
      updatedFormValues["end_date"] = {
        ...formValues["end_date"],
        error: true,
      };
      invalidSubmit = true;
    }

    // Check for dropdown values
    const isValidDropdown = selectedValue !== "" && selectedValue1 !== "";
    if (!isValidDropdown) {
      invalidSubmit = true;
    }
    // Update the state after the loop
    setFormValues(updatedFormValues);

    return invalidSubmit;
  };

  const dispatch = useDispatch();

  const handleAddBonus = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    const formatDateString = (dateString: any) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    };
    const formData = {
      bonus_name: formValues.bonus_name.value,
      bonus_description: formValues.bonus_description.value,
      bonus_criteria: formValues.bonus_criteria.value,
      start_date: formatDateString(formValues.start_date.value),
      end_date: formatDateString(formValues.end_date.value),
      no_of_tokens: formValues.no_of_tokens.value,
      bonus_type: selectedValue,
      token_category: {
        token_id: id,
      },
    };

    const notificationData = {
      data: {
        a: "1",
      },
    };

    dispatch(loaderOn());
    try {
      const res = await addBonus(formData);
      if (res != null) {
        console.log("Bonus Added Successfully");

        try {
          await axios.post(
            "https://dev-user.tribe.sn/send-notification/bonus",
            notificationData
          );
        } catch (error) {
          console.log(error);
        }
        Object.keys(formValues).forEach((key: any) => {
          formValues[key].value = "";
        });

        setSelectedValue1("");
        setSelectedValue("");
        handleClose();
        fetchBonus();
        fetchBonus1();
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };
  const [emailModal, setEmailModal] = useState(false);
  const handleOpenEmail = () => setEmailModal(true);
  const handleCloseEmail = () => setEmailModal(false);
  const [renderEmail, setRenderEmail] = useState([]);
  const [Enroll, setEnroll] = useState(0);
  const EnrollId = Enroll;
  const ShowEnroll = async (bonusId: any) => {
    console.log(`View   ${bonusId}`);
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res: any = await axios.get(
        `https://dev-backoffice.tribe.sn/user-bonus-enrollment/bonus/${bonusId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resData = res.data;

      if (res.status === 200) {
        const token = sessionStorage.getItem("jwtToken");
        const EmailData: any = await axios.post(
          `https://dev-user.tribe.sn/user/get-emails`,
          resData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setRenderEmail(EmailData.data);
        console.log(renderEmail);
        if (EmailData.status === 200) {
          handleOpenEmail();
          console.log(emailModal);
        }
      } else {
        console.log("amit");
      }
    } catch (error) {
      console.error("enroll error", error);
    }
  };
  const [tempArray, setTempArray] = useState([]);
  const [tempid, setTempId] = useState([]);
  const getTokenData = async () => {
    const res = await axios.get("https://dev-backoffice.tribe.sn/token");
    console.log(res.data);
  };

  const fetchBonus = async () => {
    //     axios.get("https://dev-backoffice.tribe.sn/bonus")
    //         .then((response) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    const response = await getBonus();

    const backendData = response;
    const frontendData = backendData.map((backendRow: any) => ({
      Bonus: backendRow.bonus_name,
      Bonus_Description: backendRow.bonus_description,
      Bonus_Criteria: backendRow.bonus_criteria,
      bonus_id: backendRow.bonus_id,
      // start_date: backendRow.start_date,
      // end_date: backendRow.end_date,
      start_date: formatDate(backendRow.start_date),
      end_date: formatDate(backendRow.end_date),
      no_of_tokens: backendRow.no_of_tokens,
      token_category_details: backendRow.token_category?.details || "",
      bonus_type: backendRow.bonus_type,
      win_id: backendRow.winnerId,
    }));
    // backendData.map((data: any) => {console.log('details',data.token_category.details)})
    setTempId(frontendData.bonus_id);
    setRows1(frontendData);
    console.log("frontend Data", frontendData);
    //  console.log('test test')

    // .catch((error) => {
    //     console.error('Error fetching data:', error);
    // });
  };

  // const [tokenId, setTokenId] = useState(null);

  // const fetchTokenId = async () => {
  //   try {
  //     const response = await getToken(id);
  //     if (response != null) {
  //       const backendData = response;
  //       const frontendData = backendData.map((backendRow: any) => ({
  //         token_id: backendRow.token_id,
  //       }));

  //       setTokenId(frontendData);
  //       console.log(frontendData);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  // const fetchBonus1 = async () => {
  //   // axios.get("https://dev-backoffice.tribe.sn/bonus/active")
  //   //     .then((response) => {

  //   const response = await getActiveBonus();
  //   if (response != null) {
  //     const backendData = response;
  //     const frontendData = backendData.map((backendRow: any) => ({
  //       Bonus: backendRow.bonus_name,
  //       Bonus_Description: backendRow.bonus_description,
  //       Bonus_Criteria: backendRow.bonus_criteria,
  //       start_date: backendRow.start_date,
  //       end_date: backendRow.end_date,
  //       bonus_id: backendRow.bonus_id,
  //       no_of_tokens: backendRow.no_of_tokens,
  //     }));
  //     setRows(frontendData);
  //     console.log(frontendData);
  //   }
  //   // .catch((error) => {
  //   //     console.error('Error fetching data:', error);
  //   // });
  // };
  const formatDate = (originalTime: string) => {
    const date = new Date(originalTime);
    return date.toLocaleString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      // second: "2-digit",
    });
  };

  const fetchBonus1 = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    dispatch(loaderOn());
    try {
      const response = await getActiveBonus();
      const response1 = await getActiveToken();

      if (response != null && response1 != null) {
        const backendData = response.map((backendRow: any) => ({
          Bonus: backendRow.bonus_name,
          Bonus_Description: backendRow.bonus_description,
          Bonus_Criteria: backendRow.bonus_criteria,
          // start_date: backendRow.start_date,
          // end_date: backendRow.end_date,
          start_date: formatDate(backendRow.start_date),
          end_date: formatDate(backendRow.end_date),
          bonus_id: backendRow.bonus_id,
          no_of_tokens: backendRow.no_of_tokens,
          token_category_details: backendRow.token_category?.details || "",
          bonus_type: backendRow.bonus_type,
          win_id: backendRow.winnerId,
        }));
        const backendData1 = response1.map((backendRow: any) => ({
          details: backendRow.details,
        }));

        const combinedData = backendData.map((item: any, index: any) => ({
          ...item,
          //  details: backendData1[index]?.details || '',
          details:
            item.bonus_id === backendData1[index]?.details
              ? backendData1[index]?.details || ""
              : "",
        }));
        setRows(combinedData);
        console.log(combinedData);
        console.log("backend data", backendData);
        console.log("test");
        //  backendData.map((tokendata: any) => {console.log('details',tokendata.token_category_details)})
        dispatch(loaderOff());
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch(loaderOff());
    }
  };

  const handleEditt = async (bonusId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    // const invalidSubmit = validateForm("bonus-add");
    // if (!invalidSubmit) {
    const formData = {
      bonus_name: formValues.bonus_name.value,
      bonus_description: formValues.bonus_description.value,
      bonus_criteria: formValues.bonus_criteria.value,
      no_of_tokens: formValues.no_of_tokens.value,
      start_date: formValues.start_date.value,
      end_date: formValues.end_date.value,
      bonus_type: selectedValue,
      // token_category: formValues.token_category.value,
      // token_category_details: formValues.details.value,
      token_category: {
        token_id: selectedValue1 ? selectedValue1 : null,
      },
    };
    dispatch(loaderOn());
    try {
      // const res = await axios.put(`https://dev-backoffice.tribe.sn/bonus/${bonusId}`, formData)
      // console.log(bonusId);
      const res = await updateBonus(bonusId, formData);
      if (res != null) {
        console.log("the data edited sucessfully");
        Object.keys(formValues).forEach((key: any) => {
          formValues[key].value = "";
        });
        setSelectedValue1("");
        setSelectedValue("");
        setSelectedValue1(res.token_category.token_id);
        setSelectedValue(res.bonus_type);
        handleClose2();
        fetchBonus1();
        fetchBonus();
        dispatch(loaderOff());
        setFormValues({
          bonus_name: {
            value: "",
            error: false,
            errorMessage: "Please enter valid bonus name",
          },
          bonus_description: {
            value: "",
            error: false,
            errorMessage: "Please enter valid bonus description",
          },
          bonus_criteria: {
            value: "",
            error: false,
            errorMessage: "Please enter valid bonus criteria",
          },
          start_date: {
            value: "",
            error: false,
            errorMessage: "Please enter vald start date",
          },
          end_date: {
            value: "",
            error: false,
            errorMessage: "Please enter valid end date",
          },
          no_of_tokens: {
            value: "",
            error: false,
            errorMessage: "Please enter valid tokens",
          },
          bonus_type: {
            value: "",
            error: false,
            errorMessage: "Please enter valid tokens",
          },
          token_category_details: {
            value: "",
            error: false,
            errorMessage: "Please enter valid tokens",
          },
        });
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
    // }
  };

  const fetchCategory = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    dispatch(loaderOn());
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get("https://dev-backoffice.tribe.sn/token", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data != null) {
        console.log(res.data);
        setCategories(res.data);
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  useEffect(() => {
    // getTokenData();
    fetchBonus();
    fetchBonus1();
    // fetchTokenId();
    fetchCategory();
  }, [tempid]);

  const handleEdit = async (bonusId: any) => {
    // const res = await axios.get(`https://dev-backoffice.tribe.sn/bonus/${bonusId}`)
    const res = await getBonusId(bonusId);
    if (res != null) {
      console.log(res);
      formValues.bonus_name.value = res.bonus_name;
      formValues.bonus_name.error = false;
      formValues.bonus_criteria.value = res.bonus_criteria;
      formValues.bonus_criteria.error = false;
      formValues.bonus_description.value = res.bonus_description;
      formValues.bonus_description.error = false;
      formValues.no_of_tokens.value = res.no_of_tokens;
      formValues.no_of_tokens.error = false;
      formValues.start_date.value = res.start_date;
      formValues.start_date.error = false;
      formValues.end_date.value = res.end_date;
      formValues.end_date.error = false;
      // formValues.token_category_details.value = res.token_category.details;
      setSelectedValue1(res.token_category.token_id);
      setSelectedValue(res.bonus_type);
      setEnroll(bonusId);
      ShowEnroll(EnrollId);
    }
    console.log(`Edit ${bonusId}`);
    handleOpen2(bonusId);
  };

  const handleDeletee = (bonusId: any) => {
    // handleDeletee(bonusId);
    setBonusId(bonusId);
    setShowPopup(true);
    console.log(`Delete   ${bonusId}`);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleDelete = async (bonusId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    const formData = {
      delete_status: "Yes",
    };
    dispatch(loaderOn());
    try {
      // const res = await axios.put(`https://dev-backoffice.tribe.sn/bonus/delete/${bonusId}`, formData)
      const res = await updateBonusDelete(bonusId, formData);
      if (res != null) {
        console.log("updated sucessfully");
        fetchBonus1();
        togglePopup();
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  interface YourComponentProps {
    formValues: any; // Adjust the type based on your form data structure
    handleChanges: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }

  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
    if (event.target.value == "") {
      setV2(true);
    } else {
      setV2(false);
    }
    console.log(selectedValue);
  };

  // Restriction
  const [userId, setUserId] = useState(0);
  let temp: number;
  const [bonus_read, setBonusRead] = React.useState(false);
  const [bonus_write, setBonusWrite] = React.useState(false);

  const getRoleData = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    dispatch(loaderOn());
    try {
      const res: any = await getUser();

      // Check if res.role_type is defined before accessing its properties
      if (res && res.role_type) {
        if (
          res.role_type.bonus_read !== undefined &&
          res.role_type.bonus_write !== undefined
        ) {
          setBonusRead(res.role_type.bonus_read);
          setBonusWrite(res.role_type.bonus_write);
        } else {
          // Handle the case where bonus_read or bonus_write is undefined
          console.error("Bonus data is undefined");
        }
        dispatch(loaderOff());
      } else {
        // Handle the case where role_type is undefined
        console.error("Role type data is undefined");
      }
    } catch (error: any) {
      // Handle any errors that occurred during the API request
      if (error.response && error.response.status === 404) {
        // Handle 404 error (resource not found) gracefully
        console.error("Resource not found:", error.message);
      } else {
        // Handle other errors
        console.error("Error fetching user data:", error.message);
      }
      dispatch(loaderOff());
    }
  };

  const filteredColumns = bonus_write
    ? columns
    : columns.filter((column) => column.id !== "bonus_id");

  const getLoginUserData = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    dispatch(loaderOn());
    try {
      const res: any = await getUser();

      if (res !== null && res.id !== undefined) {
        const temp = res.id;
        setUserId(temp);
        console.log(temp);
        dispatch(loaderOff());
      } else {
        console.error("User data or user ID is undefined");
        // Handle the case where user data or user ID is undefined
      }
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        console.error("User not found. Handle this case accordingly.");
        // Handle the case where the user is not found
      } else {
        console.error("Error fetching user data:", error.message);
        // Handle other errors
      }
      dispatch(loaderOff());
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getLoginUserData();
      getRoleData();
    };

    fetchData();
  }, []);

  const [v1, setV1] = useState(false);
  const [v2, setV2] = useState(false);
  const [v3, setV3] = useState(false);
  const [v4, setV4] = useState(false);

  const startDate = dayjs(formValues.start_date.value);
  console.log("hiiiiiiiii", startDate);
  const endDate = dayjs(formValues.end_date.value);
  const CustomTabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTabs-indicator": {
      backgroundColor: "#8E47E6", // Set your custom color here
    },
  }));

  return (
    <Box
      sx={{
        marginTop: "20px",
      }}
    >
      {/* <Modal
        open={emailModal}
        onClose={handleCloseEmail}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid white",
            borderRadius: "10px",
            p: 4,
          }}
        >
          <Typography variant="h6" id="modal-modal-title">
            Users Enrolled
          </Typography>
          <div id="modal-modal-description">
            {Object.entries(renderEmail).map(([key, value]) => (
              <div key={key}>
                <div style={{ color: "blue" }}>{(value as string) || ""}</div>
              </div>
            ))}
          </div>
          <IconButton
            style={{ position: "absolute", top: 2, left: 160 }}
            onClick={handleCloseEmail}
            aria-label="delete"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal> */}
      <Box sx={{ width: "100%" }}>
        <Box sx={{}}>
          <CustomTabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Bonus"
              {...a11yProps(0)}
              style={{
                color: "#8E47E6",
                fontWeight: "600",
                textTransform: "none",
                fontSize: "16px",
              }}
            />
            <Tab
              label="Bonus History"
              {...a11yProps(1)}
              style={{
                color: "#8E47E6",
                fontWeight: "600",
                textTransform: "none",
                fontSize: "16px",
              }}
            />
          </CustomTabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                // paddingBottom: "30px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "",
                    fontSize: "16px",
                    fontWeight: "700",
                    paddingRight: "40px",
                  }}
                >
                  Bonus
                </Typography>
                <span>
                  <TextField
                    label="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    inputProps={{
                      style: {
                        width: "200px",
                        boxShadow: "0px 4px 12px 0px #8E47E640",
                      },
                    }}
                  />
                </span>
              </div>
              {/* <div>
                <CustomPrimaryButton
                  onClick={handleOpen}
                  disabled={bonus_write===false}
                  startIcon={<AddIcon />}
                >
                  Add Bonus
                </CustomPrimaryButton>
              </div> */}
              {bonus_write === false ? (
                ""
              ) : (
                <div>
                  <CustomPrimaryButton
                    onClick={handleOpen}
                    startIcon={<AddIcon />}
                  >
                    Add Bonus
                  </CustomPrimaryButton>
                </div>
              )}

              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div
                    style={
                      {
                        // display: "flex",
                        // flexDirection:'row'
                      }
                    }
                  >
                    <div style={{ float: "left" }}>
                      {" "}
                      <h1>Add Bonus</h1>
                    </div>

                    <div style={{ float: "right" }}>
                      <CloseIcon onClick={handleClose} />
                    </div>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        Bonus Name
                        <CustomTextField
                          placeholder="Enter Your  Bonus Name"
                          formValues={formValues}
                          handleChange={(e: any) => handleChangee(e)}
                          fieldName="bonus_name"
                        />
                      </div>

                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: "10px",
                        }}
                      >
                        Bonus Description
                        <CustomTextField
                          placeholder="Enter Your  Bonus Description"
                          formValues={formValues}
                          handleChange={(e: any) => handleChangee(e)}
                          fieldName="bonus_description"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        Bonus Criteria
                        <CustomTextField
                          placeholder="Enter Your  Bonus Criteria"
                          formValues={formValues}
                          handleChange={(e: any) => handleChangee(e)}
                          fieldName="bonus_criteria"
                        />
                      </div>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: "10px",
                        }}
                      >
                        Token Category
                        <Select
                          displayEmpty
                          value={selectedValue1}
                          onChange={(e: any) => handleChangeee(e)}
                          style={{
                            border: "1px solid #8E47E6",
                            height: "7vh",
                            borderRadius: "12px",
                            background: "#FFFFFF",
                            marginTop: "10px",
                            // width: "25vw",
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                color: "#000000",
                                background: "#FFFFFF",
                                borderRadius: "14px",
                              },
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        >
                          <MenuItem value="" disabled>
                            <ListItemText
                              primary={
                                selectedValue1 === ""
                                  ? "Select a token category"
                                  : ""
                              }
                              style={{ color: "rgba(0, 0, 0, 0.5)" }}
                            />
                          </MenuItem>
                          {categories.map((category: any) => (
                            <MenuItem
                              key={category.token_id}
                              value={category.token_id}
                            >
                              {category.details}
                            </MenuItem>
                          ))}
                        </Select>
                        {v1 != false && (
                          <p
                            style={{
                              color: "#d32f2f",
                              fontSize: "12.7px",
                              fontWeight: 500,
                              margin: "0px 0px 0px 15px",
                            }}
                          >
                            Please enter token category
                          </p>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span style={{ paddingBottom: "10px" }}>
                          Start Date
                        </span>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div
                            style={{
                              width: "100%",
                              border: "1px solid #8E47E6",
                              borderRadius: "8px",
                            }}
                          >
                            <DateTimePicker
                              name="start_date"
                              value={startDate}
                              onChange={(value: any) => {
                                handleChangee({ name: "start_date", value });
                                if (value !== null) {
                                  setFormValues((prevFormValues) => ({
                                    ...prevFormValues,
                                    start_date: {
                                      ...prevFormValues.start_date,
                                      error: false,
                                    },
                                  }));
                                }
                              }}
                              sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "none",
                                  },
                                "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline ":
                                  {
                                    border: "none !important",
                                  },
                              }}
                            />
                          </div>
                        </LocalizationProvider>
                        {formValues["start_date"].error && (
                          <span
                            style={{
                              color: "#d32f2f",
                              fontSize: "12.8px",
                              fontWeight: 500,
                              margin: "0px 0px 0px 15px",
                            }}
                          >
                            {formValues["start_date"].errorMessage}
                          </span>
                        )}
                      </div>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: "10px",
                        }}
                      >
                        No Of Tokens
                        <CustomTextField
                          placeholder="Enter Your  Number of Tokens"
                          formValues={formValues}
                          handleChange={(e: any) => handleChangee(e)}
                          fieldName="no_of_tokens"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span style={{ paddingBottom: "10px" }}>End Date</span>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div
                            style={{
                              width: "100%",
                              border: "1px solid #8E47E6",
                              borderRadius: "8px",
                            }}
                          >
                            <DateTimePicker
                              name="end_date"
                              value={endDate}
                              onChange={(value: any) => {
                                handleChangee({ name: "end_date", value });
                                if (value !== null) {
                                  setFormValues((prevFormValues) => ({
                                    ...prevFormValues,
                                    end_date: {
                                      ...prevFormValues.end_date,
                                      error: false,
                                    },
                                  }));
                                }
                              }}
                              sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "none",
                                  },
                                "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline ":
                                  {
                                    border: "none !important",
                                  },
                              }}
                            />
                          </div>
                        </LocalizationProvider>
                        {formValues["end_date"].error && (
                          <span
                            style={{
                              color: "#d32f2f",
                              fontSize: "12.8px",
                              fontWeight: 500,
                              margin: "0px 0px 0px 15px",
                            }}
                          >
                            {formValues["end_date"].errorMessage}
                          </span>
                        )}
                      </div>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: "10px",
                        }}
                      >
                        Bonus Type
                        <Select
                          displayEmpty
                          value={selectedValue}
                          onChange={handleSelectChange}
                          style={{
                            border: "1px solid #8E47E6",
                            height: "7vh",
                            borderRadius: "12px",
                            background: "#FFFFFF",
                            marginTop: "10px",
                            width: "100%",
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                color: "#000000",
                                background: "#FFFFFF",
                                borderRadius: "14px",
                              },
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        >
                          <MenuItem value="" disabled>
                            <ListItemText
                              primary={
                                selectedValue === ""
                                  ? "Select a bonus type"
                                  : ""
                              }
                              style={{ color: "rgba(0, 0, 0, 0.5)" }}
                            />
                          </MenuItem>
                          <MenuItem value={"One-Day"}>One-Day Bonus</MenuItem>
                          <MenuItem value={"Weekly"}>Weekly Bonus</MenuItem>
                        </Select>
                        {v2 != false && (
                          <p
                            style={{
                              color: "#d32f2f",
                              fontSize: "12.7px",
                              fontWeight: 500,
                              margin: "0px 0px 0px 15px",
                            }}
                          >
                            Please enter bonus type
                          </p>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      style={{
                        // display:'flex',
                        // flexDirection:'row',
                        // justifyContent:'flex-end',
                        float: "right",
                        width: "100%",
                      }}
                    >
                      <CommonDialog
                        open={openDialog}
                        handleClose={handleCloseDialog}
                        handleConfirm={() => {
                          handleAddBonus();
                          handleCloseDialog();
                        }}
                        contentText="Do you want to add a Bonus?"
                      />
                      <CustomPrimaryButton
                        style={{
                          padding: "15px 25px",
                          float: "right",
                        }}
                        // onClick={handleAddBonus}
                        onClick={() => {
                          const invalidSubmit = validateForm("bonus-add");
                          if (selectedValue1 == "") {
                            setV1(true);
                          } else {
                            setV1(false);
                          }

                          if (selectedValue == "") {
                            setV2(true);
                          } else {
                            setV2(false);
                          }
                          if (!invalidSubmit) {
                            setopenDialog(true);
                          }
                        }}
                      >
                        Add
                      </CustomPrimaryButton>
                    </Grid>
                  </Grid>
                  {/* <Grid container spacing={1}>
                    <Grid style={{
                      display:'flex',
                      flexDirection:'row',
                      justifyContent:'space-between',
                      width: '100%',
                    }}>
                      
                    </Grid>
                    <Grid  lg={12} style={{
                      display:'flex',
                      flexDirection:'row',
                   
                     
                    }}>
                     
                    </Grid>
                    <Grid style={{
                      display:'flex',
                      flexDirection:'row',
                      
                      width: '100%',
                    }}>
                     
                    </Grid>
                    <Grid style={{
                      display:'flex',
                      flexDirection:'row',
                      justifyContent:'space-between',
                      width: '100%',
                    }}>
                      
                    </Grid>
                    <Grid style={{
                      display:'flex',
                      flexDirection:'row',
                      justifyContent:'space-between',
                      width: '100%',
                    }}>
                      
                    </Grid>
                  </Grid> */}
                </Box>
              </Modal>

              <Modal
                open={open2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div onClick={handleClose2}>
                    <div style={{ float: "left" }}>
                      {" "}
                      <h1>Update Bonus</h1>
                    </div>

                    <div style={{ float: "right" }}>
                      <CloseIcon />
                    </div>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        Bonus Name
                        <CustomTextField
                          formValues={formValues}
                          handleChange={(e: any) => handleChangee(e)}
                          fieldName="bonus_name"
                        />
                      </div>

                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: "5px",
                        }}
                      >
                        Bonus Description
                        <CustomTextField
                          formValues={formValues}
                          handleChange={(e: any) => handleChangee(e)}
                          fieldName="bonus_description"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        Bonus Criteria
                        <CustomTextField
                          formValues={formValues}
                          handleChange={(e: any) => handleChangee(e)}
                          fieldName="bonus_criteria"
                        />
                      </div>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: "5px",
                        }}
                      >
                        Token Category
                        <Select
                          value={selectedValue1}
                          onChange={(e: any) => handleChangeee(e)}
                          style={{
                            border: "1px solid #8E47E6",
                            height: "7vh",
                            borderRadius: "12px",
                            background: "#FFFFFF",
                            marginTop: "10px",
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                color: "#000000",
                                background: "#FFFFFF",
                                borderRadius: "14px",
                              },
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        >
                          {categories.map((category: any) => (
                            <MenuItem
                              key={category.token_id}
                              value={category.token_id}
                            >
                              {category.details}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span style={{ paddingBottom: "5px" }}>Start Date</span>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div
                            style={{
                              width: "100%",
                              border: "1px solid #8E47E6",
                              borderRadius: "8px",
                            }}
                          >
                            <DateTimePicker
                              name="start_date"
                              value={startDate}
                              onChange={(value: any) => {
                                handleChangee({ name: "start_date", value });
                                if (value !== null) {
                                  setFormValues((prevFormValues) => ({
                                    ...prevFormValues,
                                    start_date: {
                                      ...prevFormValues.start_date,
                                      error: false,
                                    },
                                  }));
                                }
                              }}
                              sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "none",
                                  },
                                "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline ":
                                  {
                                    border: "none !important",
                                  },
                              }}
                            />
                          </div>
                        </LocalizationProvider>
                        {formValues["start_date"].error && (
                          <span
                            style={{
                              color: "#d32f2f",
                              fontSize: "12.8px",
                              fontWeight: 500,
                              margin: "0px 0px 0px 15px",
                            }}
                          >
                            {formValues["start_date"].errorMessage}
                          </span>
                        )}
                      </div>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: "5px",
                        }}
                      >
                        No Of Tokens
                        <CustomTextField
                          formValues={formValues}
                          handleChange={(e: any) => handleChangee(e)}
                          fieldName="no_of_tokens"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span style={{ paddingBottom: "5px" }}>End Date</span>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div
                            style={{
                              width: "100%",
                              border: "1px solid #8E47E6",
                              borderRadius: "8px",
                            }}
                          >
                            <DateTimePicker
                              name="end_date"
                              value={endDate}
                              onChange={(value: any) => {
                                handleChangee({ name: "end_date", value });
                                if (value !== null) {
                                  setFormValues((prevFormValues) => ({
                                    ...prevFormValues,
                                    end_date: {
                                      ...prevFormValues.end_date,
                                      error: false,
                                    },
                                  }));
                                }
                              }}
                              sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "none",
                                  },
                                "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline ":
                                  {
                                    border: "none !important",
                                  },
                              }}
                            />
                          </div>
                        </LocalizationProvider>
                        {formValues["end_date"].error && (
                          <span
                            style={{
                              color: "#d32f2f",
                              fontSize: "12.8px",
                              fontWeight: 500,
                              margin: "0px 0px 0px 15px",
                            }}
                          >
                            {formValues["end_date"].errorMessage}
                          </span>
                        )}
                      </div>

                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: "5px",
                        }}
                      >
                        Bonus Type
                        <Select
                          value={selectedValue}
                          onChange={handleSelectChange}
                          style={{
                            border: "1px solid #8E47E6",
                            height: "7vh",
                            borderRadius: "12px",
                            background: "#FFFFFF",
                            marginTop: "10px",
                            width: "100%",
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                color: "#000000",
                                background: "#FFFFFF",
                                borderRadius: "14px",
                              },
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        >
                          <MenuItem value={"One-Day"}>One-Day Bonus</MenuItem>
                          <MenuItem value={"Weekly"}>Weekly Bonus</MenuItem>
                        </Select>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          display: "flex",
                          flexDirection: "column",
                          paddingBottom: "10px",
                        }}
                      >
                        Users Enroll
                      </div>
                      <div
                        style={{
                          border: "1px solid #8E47E6",
                          height: "10vh",
                          borderRadius: "12px",
                          background: "#FFFFFF",
                          paddingLeft: "5px",
                          paddingTop: "5px",
                          overflow: "auto",
                        }}
                      >
                        {Object.entries(renderEmail).map(([key, value]) => (
                          <div key={key}>
                            <div style={{ color: "#000" }}>
                              {(value as string) || ""}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Grid>

                    <Grid
                      item
                      style={{
                        // display:'flex',
                        // flexDirection:'row',
                        // justifyContent:'flex-end',
                        float: "right",
                        width: "100%",
                      }}
                    >
                      <CommonDialog
                        open={openDialog}
                        handleClose={handleCloseDialog}
                        handleConfirm={() => {
                          handleEditt(bonusId);
                          handleCloseDialog();
                        }}
                        contentText="Do you want to update the bonus?"
                      />
                      <CustomPrimaryButton
                        style={{
                          float: "right",
                        }}
                        onClick={() => {
                          const invalidSubmit = validateForm("bonus-add");
                          if (!invalidSubmit) {
                            setopenDialog(true);
                          }
                        }}
                      >
                        Update
                      </CustomPrimaryButton>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
            </div>

            <Box
              sx={{
                color: "black",
                marginTop: "30px",
              }}
            >
              <Paper sx={{ overflow: "hidden" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  {/* <TextField
                    label="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    inputProps={{
                      style: {
                        width: "200px",
                      },
                    }}
                  /> */}
                </div>
                <TableContainer
                  sx={{
                    maxHeight: 440,
                    borderRadius: "18px",
                    boxShadow: "0px 4px 12px 0px #8E47E640",
                  }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow
                        sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                      >
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              color: column.color,
                            }}
                          >
                            {/* {column.label} */}
                            {bonus_write || column.id !== "bonus_id"
                              ? column.label
                              : null}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.Bonus_Description}
                            >
                              {filteredColumns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.id === "win_id" && value === 0 ? (
                                      <span>No Winner</span>
                                    ) : (
                                      <>
                                        {column.id === "win_id" ? (
                                          "TRB " + value
                                        ) : column.id === "bonus_id" ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            {bonus_write === true ? (
                                              <p
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  handleEdit(row.bonus_id)
                                                }
                                              >
                                                <CreateIcon
                                                  style={{ color: "#5151ED" }}
                                                />
                                              </p>
                                            ) : (
                                              <p
                                                style={{
                                                  cursor: "not-allowed",
                                                  filter: "blur(1px)",
                                                }}
                                              >
                                                <CreateIcon
                                                  style={{ color: "#5151ED" }}
                                                />
                                              </p>
                                            )}
                                            {bonus_write === true ? (
                                              <p
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  setopenDialog(true);
                                                  setSelectedBonusId(
                                                    row.bonus_id
                                                  );
                                                }}
                                                // onClick={() =>
                                                //     handleDelete(row.bonus_id)
                                                //   }
                                              >
                                                <DeleteIcon
                                                  style={{ color: "#DF2525" }}
                                                />
                                              </p>
                                            ) : (
                                              <p
                                                style={{
                                                  cursor: "not-allowed",
                                                  filter: "blur(1px)",
                                                }}
                                              >
                                                <DeleteIcon
                                                  style={{ color: "#DF2525" }}
                                                />
                                              </p>
                                            )}
                                          </div>
                                        ) : column.format &&
                                          typeof value === "number" ? (
                                          column.format(value)
                                        ) : (
                                          value
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <CommonDialog
                  open={openDialog}
                  handleClose={handleCloseDialog}
                  handleConfirm={() => {
                    handleDelete(selectedBonusId);
                    handleCloseDialog();
                  }}
                  contentText="Are you sure to delete the bonus?"
                />
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredRows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box>
            <div
              style={{
                display: "flex",
                justifyContent: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "",
                    fontSize: "16px",
                    fontWeight: "700",
                    paddingRight: "40px",
                  }}
                >
                  Bonus History
                </Typography>
                <span>
                  <TextField
                    label="Search"
                    value={searchQuery1}
                    onChange={(e) => setSearchQuery1(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    inputProps={{
                      style: {
                        width: "200px",
                        boxShadow: "0px 4px 12px 0px #8E47E640",
                      },
                    }}
                  />
                </span>
              </div>
              <span>
                {/* <Button
                                    style={{
                                        color: "black",
                                        backgroundColor: "#8E47E6",
                                    }}
                                    endIcon={<DownloadIcon />}
                                >
                                    Export{" "}
                                </Button> */}
              </span>
            </div>

            <Box
              sx={{
                color: "black",
                marginTop: "20px",
              }}
            >
              <div></div>
              <Paper sx={{ overflow: "hidden" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  {/* <TextField
                    label="Search"
                    value={searchQuery1}
                    onChange={(e) => setSearchQuery1(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    inputProps={{
                      style: {
                        width: "200px",
                      },
                    }}
                  /> */}
                </div>
                <TableContainer
                  sx={{
                    maxHeight: 440,
                    borderRadius: "18px",
                    boxShadow: "0px 4px 12px 0px #8E47E640",
                  }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow
                        sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                      >
                        {columns2.map((column2) => (
                          <TableCell
                            key={column2.id}
                            align={column2.align}
                            style={{
                              minWidth: column2.minWidth,
                              color: column2.color,
                            }}
                          >
                            {column2.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRows1
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.Bonus}
                            >
                              {columns2.map((column2) => {
                                const value = row[column2.id];
                                return (
                                  <TableCell
                                    key={column2.id}
                                    align={column2.align}
                                  >
                                    {column2.format && typeof value === "number"
                                      ? column2.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredRows1.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
