import { styled } from '@mui/material/styles'
import Link, { LinkProps } from '@mui/material/Link'
import Button, { ButtonProps } from '@mui/material/Button'

export const CustomPrimaryButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
height: '38.86px',
fontSize: ' 16px',
fontWeight: '600',
lineHeight: '16px',
border:'1px solid #8E47E6',
borderRadius: '13px',
textTransform: 'none',
// fontFamily: 'sans serif',
 backgroundColor: '#8E47E6',
padding:
'11px 19px 11px 19px',
gap: '9px',
'&:hover': {
backgroundColor: '#8E47E6',
color:'#FFFFFF'
},
}))