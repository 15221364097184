import { Password } from "@mui/icons-material";
import { Box, Grid, Paper, TextField, styled } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { createAdmin, getUser } from "../utilities/adminservice";
// import { loaderOff, loaderOn, login, openSnackbar } from '../redux/actions'

import { loaderOff, loaderOn, login, logout, openSnackbar } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getAuthToken } from "../utilities/TokenUtility";
import { CustomPrimaryButton } from "../customtextfield/CustomButton";
import { updateuser } from "../utilities/backofficeservice";
import { containsOnlyCharacters } from "../CustomRegex/CustomRegex";
import { useNavigate } from "react-router-dom";
import store from "../redux/store";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Profile() {
  const [len, setLen] = useState(0);
  let Length = 0;
  let TotalTokens = 0;
  const [token, setToken] = useState(0);
  const [pending, setPending] = useState(0);
  const [approved, setApproved] = useState(0);
  const [rejected, setRejected] = useState(0);

  const [Id, setId] = useState("");

  const [FirstName, setFirstName] = useState("");

  const [Email, setEmail] = useState("");

  const [Domain, setDomin] = useState("");
  const [Role, setRole] = useState("");


  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isTokenExpired = useSelector((state: any) => state.auth.isTokenExpired);
  console.log("syed" + isLoggedIn);
  useEffect(() => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken || isTokenExpired) {
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    } else if (!isLoggedIn) { 
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    }
  }, [isLoggedIn, isTokenExpired]);












  const [formvalues, setFormvalues]: any = useState({
    first_name: {
      name: "",
      value: "",
      error: false,
    },
    last_name: {
      name: "",
      value: "",
      error: false,
    },
    email: {
      name: "",
      value: "",
      error: false,
    },
    password: {
      name: "",
      value: "",
      error: false,
    },
    confirm_password: {
      name: "",
      value: "",
      error: false,
    },
    given_User_Name: {
      name: "",
      value: "",
      error: false,
    },
    domain: {
      name: "",
      value: "",
      error: false,
    },
    role: {
      name: "",
      value: "",
      error: false,
    },
    id: {
        name: "",
        value: "",
        error: false,
      },
  });
  const handleChange = (name: string | number, newValue: any) => {
    setFormvalues((prevState: { [x: string]: any }) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: newValue,
      },
    }));
  };

  const fetchToken = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn())
    try {
      const token = getAuthToken();
      const response = await getUser();
      if (response != null) {
        setId(response.id);
        setFirstName(response.first_name);
        setEmail(response.email);
        setDomin(response.domain);
        setRole(response.role_type.role_name);
        console.log(Id);
        console.log(FirstName);
        console.log(Email);
        console.log(Domain);
        console.log(Role);
        console.log(response);
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  };

  useEffect(() => {
    fetchToken();
  }, [0]);


const validateForm = (formValues: any) => {
    let invalid = false;
    Object.keys(formValues).forEach((key) => {
      if (formValues[key].error || formValues[key].value === "") {
        invalid = true;
      }
    });
    return invalid;
  };

const dispatch = useDispatch()

const handleEditUser = async (userId: any) => {
  const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    const invalidSubmit = validateForm("update-user");
    if (!invalidSubmit) {
        const intValue = parseInt(Id, 10);
      const formData = {
       first_name : FirstName,
       domain : Domain,
       role : Role,
    //    id : intValue,
      };
      dispatch(loaderOn())
      try {
        const token = sessionStorage.getItem("jwtToken");
        const res = axios.put(`https://dev-admin.tribe.sn/admin/update/${Id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res != null) {
          console.log("the data edited sucessfully");
          Object.keys(formvalues).forEach((key: any) => {
            formvalues[key].value = "";
          });
          dispatch(loaderOff())
        }
        dispatch(openSnackbar('admin details updated successfully', 'green'))
      } catch (error) {
        console.log(error);
        dispatch(loaderOff())
      }
    }
  };



  const [userId, setUserId] = React.useState(null);
  
  const textfeild = {
    border: "1px solid #8E47E6",
    height: "6vh",
    width: "200px",
    borderRadius: "12px",
    // paddingBottom: "10%",
    paddingBottom: "15px",
    background: "#FFFFFF",
    // marginTop: "10px",
    fontSize: "16px",
    fontFamily: "sans-serif",
    fontWeight: "400",
  };
  const label = {
    fontSize: "17px",
    fontWeight: "bold",
    textAlign: "left",
    padding: "0px 0px 10px 0px",
  };
  return (
    <Box marginTop={"30px"}>
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          padding: "10px 0px 10px 0px",
        }}
      >
        <p style={{ fontSize: "17px", fontWeight: "700" }}>Admin Details</p>
      </div>
      <div
        style={{
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
          padding: "45px 0px 45px 45px",
          borderRadius: "18px",
          border: "1px solid #8E47E640",
          marginRight: "15px",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={4}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  textAlign: "left",
                  padding: "0px 0px 10px 0px",
                }}
              >
                Name
              </div>
              <TextField
                id="filled-basic"
                variant="filled"
                value={FirstName}
                InputProps={{
                  //   readOnly: true,
                  disableUnderline: true,
                  style: textfeild,
                }}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  textAlign: "left",
                  padding: "0px 0px 10px 0px",
                }}
              >
                Employer ID
              </div>

              <TextField
                id="filled-basic"
                variant="filled"
                value={Id}
                InputProps={{
                  disableUnderline: true,
                readOnly: true,
                  style: textfeild,
                }}
                onChange={(e) => setId(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  textAlign: "left",
                  padding: "0px 0px 10px 0px",
                }}
              >
                Mail ID
              </div>
              <TextField
                id="filled-basic"
                variant="filled"
                value={Email}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                  style: textfeild,
                }}
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  textAlign: "left",
                  padding: "0px 0px 10px 0px",
                }}
              >
                Domain
              </div>
              <TextField
                id="filled-basic"
                variant="filled"
                value={Domain}
                InputProps={{
                  //   readOnly: true,
                  disableUnderline: true,
                  style: textfeild,
                }}
                onChange={(e) => setDomin(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  textAlign: "left",
                  padding: "0px 0px 10px 0px",
                }}
              >
                Role
              </div>
              <TextField
                id="filled-basic"
                variant="filled"
                value={Role}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                  style: textfeild,
                }}
                onChange={(e) => setRole(e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={4}>
                        <div
                            style={{

                                fontSize: '17px',
                                fontWeight: 'bold'
                            }}
                        >
                            Access
                        </div>
                        <TextField id="filled-basic" variant="filled"
                            InputProps={{
                                disableUnderline: true,

                                style: {
                                    height: '40px', width: '180px', border: '1px solid #8E47E6',
                                    fontSize: '16px', fontFamily: 'sans-serif', fontWeight: '600',
                                    paddingBottom: '15px'
                                }
                            }} />
                    </Grid> */}
            <Grid>
              <CustomPrimaryButton
                style={{
                  marginTop: "50px",
                }}
                onClick={() => {
                  handleEditUser(userId);
                }}
              >
                Update
              </CustomPrimaryButton>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Box>
  );
}
