import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Slide,
  DialogProps,
} from '@mui/material'

type CommonDialogProps = {
  open: boolean
  handleClose: () => void
  handleConfirm: () => void
  contentText: string
} & DialogProps

const Transition = React.forwardRef(function Transition(
  props: any,
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CommonDialog: React.FC<CommonDialogProps> = ({
  open,
  handleClose,
  handleConfirm,
  contentText,
  ...otherProps
}) => {
  const [isHoveredAgree, setIsHoveredAgree] = useState(false)
  const [isHoveredDisagree, setIsHoveredDisAgree] = useState(false)
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      {...otherProps}
    >
      <DialogContent style={{ background: '#8E47E6' }}>
        <DialogContentText
          id="alert-dialog-slide-description"
          style={{ color: '#FFFFFF' }}
        >
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ background: '#8E47E6' }}>
        <Button
          onClick={handleClose}
          onMouseEnter={() => setIsHoveredDisAgree(true)}
          onMouseLeave={() => setIsHoveredDisAgree(false)}
          style={{
            color: '#FFFFFF',
            textTransform: 'none',
            // background: isHoveredDisagree ? '#000000' : '',
            border: '2px solid white',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          onMouseEnter={() => setIsHoveredAgree(true)}
          onMouseLeave={() => setIsHoveredAgree(false)}
          style={{
            color: '#FFFFFF',
            textTransform: 'none',
            // background: isHoveredAgree ? '#000000' : '',
            border: '2px solid white',
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CommonDialog
