import axios from 'axios'
import { getAuthToken } from './TokenUtility'
const userApi = axios.create({
  baseURL: 'https://dev-user.tribe.sn',
})
userApi.interceptors.request.use((config: any) => {
  const token = getAuthToken()
  if (token) {
    config = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    }
  }
  return config
})
export default userApi
