import { useState } from "react";
type FormValues = {
  [key: string]: {
    value: string;
    error: boolean;
    errorMessage: any;
  };
};

export function useBonusForm() {
  const [formValues, setFormValues] = useState<FormValues>({
    bonus_name: {
      value: "",
      error: false,
      errorMessage: "Please enter valid bonus name",
    },
    bonus_description: {
      value: "",
      error: false,
      errorMessage: "Please enter valid bonus description",
    },
    bonus_criteria: {
      value: "",
      error: false,
      errorMessage: "Please enter valid bonus criteria",
    },
    start_date: {
      value: "",
      error: false,
      errorMessage: "Please enter valid start date",
    },
    end_date: {
      value: "",
      error: false,
      errorMessage: "Please enter valid end date",
    },
    no_of_tokens: {
      value: "",
      error: false,
      errorMessage: "Please enter valid tokens",
    },
    bonus_type: {
      value: "",
      error: false,
      errorMessage: "Please enter valid tokens",
    },
    token_category_details: {
      value: "",
      error: false,
      errorMessage: "Please enter valid tokens",
    },
  });
  return { formValues, setFormValues }; 
}