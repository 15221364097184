import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useGiftform } from "../custom_forms/AchievementForm";
import {
  containsOnlyCharacters,
  emailRegex,
  numberRegex,
  passwordRegex,
} from "../CustomRegex/CustomRegex";
import { loaderOff, loaderOn, logout, openSnackbar } from "../redux/actions";
import {
  creategift,
  getactivegifts,
  getgiftbyid,
  getgifts,
  updategift,
  updategifts,
} from "../utilities/backofficeservice";

import CustomTextField from "../customtextfield/CustomTextField";

import { getUser } from "../utilities/adminservice";
import CommonDialog from "../commondialog/confirm";
import { CustomPrimaryButton } from "../customtextfield/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "../redux/store";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,

  bgcolor: "background.paper",
  border: "2px solid white",
  borderRadius: "18px",

  // boxShadow: 24,
  p: 3,
};

const overlayStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0)", // Semi-transparent overlay
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 10000,
};

const popupStyle: React.CSSProperties = {
  background: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
  position: "relative", // Ensure relative positioning for close icon
};

const closeIconStyle: React.CSSProperties = {
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
};
const popupContentStyle: React.CSSProperties = {
  textAlign: "center",
  marginTop: "30px", // Adjust the margin to make space for the close icon
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Column4 {
  id:
    | "Gift"
    | "Gift_Description"
    | "Gift_Criteria"
    | "gift_id"
    | "no_of_tokens";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}

const columns4: readonly Column4[] = [
  { id: "Gift", label: "Gift", color: "#8E47E6" },
  {
    id: "Gift_Description",
    label: "Gift Description",
    color: "#8E47E6",
  },
  {
    id: "Gift_Criteria",
    label: "Gift Criteria",
    format: (value: number) => value.toLocaleString("en-US"),
    color: "#8E47E6",
  },
  {
    id: "no_of_tokens",
    label: "No of Tokens",
    color: "#8E47E6",
  },
  {
    id: "gift_id",
    label: "Action",
    format: (value: number) => value.toFixed(2),
    color: "#8E47E6",
  },
];

interface Data4 {
  Gift: string;
  Gift_Description: string;
  Gift_Criteria: number;
  gift_id: number;
  no_of_tokens: number;
}
// | "Gift_Criteria"
interface Column6 {
  id: "Sl_No" | "Gifts" | "Gift_Description" | "Gift_Criteria" | "no_of_tokens";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}

const columns6: readonly Column6[] = [
  // { id: "Sl_No", label: "Sl. No", color: "#8E47E6" },
  { id: "Gifts", label: "Gifts", color: "#8E47E6" },
  {
    id: "Gift_Description",
    label: "Gift  Description ",

    format: (value: number) => value.toLocaleString("en-US"),
    color: "#8E47E6",
  },
  {
    id: "Gift_Criteria",
    label: "Gift Criteria",

    format: (value: number) => value.toLocaleString("en-US"),
    color: "#8E47E6",
  },
  { id: "no_of_tokens", label: "No of Tokens", color: "#8E47E6" },
];

interface Data6 {
  Sl_No: string;
  Gifts: string;
  Gift_Description: number;
  Gift_Criteria: number;
  no_of_tokens: number;
}

export default function Gifts() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery3, setSearchQuery3] = useState("");
  const [rows3, setRows3] = useState<Data4[]>([]);
  const [giftId, setGiftId] = React.useState(null);
  const [rows5, setRows5] = useState<Data6[]>([]);
  const [searchQuery5, setSearchQuery5] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const { formValues, setFormValues } = useGiftform();
  const [showContent, setShowContent] = useState(false);
  const [openDialog, setopenDialog] = React.useState(false);
  const [selectedGiftId, setSelectedGiftId] = useState<number | null>(null);


  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isTokenExpired = useSelector((state: any) => state.auth.isTokenExpired);
  console.log("syed" + isLoggedIn);
  useEffect(() => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken || isTokenExpired) {
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    } else if (!isLoggedIn) { 
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    }
  }, [isLoggedIn, isTokenExpired]);







  
  const handleCloseDialog = () => {
    setopenDialog(false);
    setShowContent(false);
  };

  const handleChangee = (e: { name: string; value: string } | any) => {
    const { name, value } = e.target || e;

    if (
      name === "gift_name" ||
      name === "gift_description" ||
      name === "gift_criteria"
    ) {
      const isValidValue: boolean = containsOnlyCharacters.test(value);

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: {
          ...prevFormValues[name],
          value,
          error: !isValidValue,
        },
      }));
    } else if (name === "no_of_tokens") {
      const isValidInteger: boolean = /^\d+$/.test(value);

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: {
          ...prevFormValues[name],
          value,
          error: !isValidInteger,
        },
      }));
    } else {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: {
          ...prevFormValues[name],
          value,
          error: value === "" ? true : false,
        },
      }));
    }
  };

  let validateForm = (type: any) => {
    let invalidSubmit = false;
    const updatedFormValues = { ...formValues }; // Create a copy to accumulate changes

    // Validate other fields
    const formFields = [
      "gift_name",
      "gift_description",
      "gift_criteria",
      "no_of_tokens",
    ];
    formFields.forEach((fieldName) => {
      const value = formValues[fieldName].value;
      let isValid = numberRegex.test(value);

      if (fieldName === "no_of_tokens") {
        // Validate for integer type
        isValid = /^\d+$/.test(value);
      } 
      else {
        // Validate other fields with only characters
        isValid = containsOnlyCharacters.test(value);
      }

      updatedFormValues[fieldName] = {
        ...formValues[fieldName],
        error: !isValid,
      };

      invalidSubmit = invalidSubmit || !isValid;
    });

    // Check for required fields
    if (type === "gift-add") {
      formFields.forEach((fieldName) => {
        const value = formValues[fieldName].value;
        if (value === "") {
          updatedFormValues[fieldName] = {
            ...formValues[fieldName],
            error: true,
          };
          invalidSubmit = true;
        }
      });
    }

    // Update the state after the loop
    setFormValues(updatedFormValues);

    return invalidSubmit;
  };

  const filteredRows3 = rows3.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery3.toLowerCase())
    )
  );
  const filteredRows5 = rows5.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery5.toLowerCase())
    )
  );
  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [openGift, setOpenGift] = React.useState(false);
  const handleOpenGift = (giftId: any) => {
    setGiftId(giftId);
    setOpenGift(true);
  };

  const handleClose4 = () => {
    setGiftId(null);
    setOpenGift(false);
    Object.keys(formValues).forEach((key: any) => {
      formValues[key].value = "";
    });
    setFormValues({
      gift_name: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Gift name",
      },
      gift_description: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Gift description",
      },
  
      gift_criteria: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Gift criteria",
      },
      no_of_tokens: {
        value: "",
        error: false,
        errorMessage: "Please enter valid No. of Tokens",
      },
    })
  };

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => {
    setOpen1(false)
    setFormValues({
      gift_name: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Gift name",
      },
      gift_description: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Gift description",
      },
  
      gift_criteria: {
        value: "",
        error: false,
        errorMessage: "Please enter valid Gift criteria",
      },
      no_of_tokens: {
        value: "",
        error: false,
        errorMessage: "Please enter valid No. of Tokens",
      },
    })
  };


  const dispatch = useDispatch();

  const handleAutoFillGift = async (giftId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn())
    try {
      // const res = await axios.get(`https://dev-backoffice.tribe.sn/gift/${giftId}`)
      const res = await getgiftbyid(giftId);
      if (res != null) {
        formValues.gift_name.value = res.gift_name;
        formValues.gift_name.error = false;
        formValues.gift_description.value = res.gift_description;
        formValues.gift_description.error = false;
        formValues.gift_criteria.value = res.gift_criteria;
        formValues.gift_criteria.error = false;
        formValues.no_of_tokens.value = res.no_of_tokens;
        formValues.no_of_tokens.error = false;
        console.log(res);
        handleOpenGift(giftId);
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  };

  const handleEditGift = (giftId: any) => {
    handleAutoFillGift(giftId);
    console.log(`Edit  ${giftId}`);
  };

  const handleDeleteGiftt = (giftId: any) => {
    // handleDeleteGiftt(giftId);
    setGiftId(giftId);
    setShowPopup(true);
    console.log(`Delete   ${giftId}`);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleDeleteGift = async (giftId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    const formData = {
      delete_status: "Yes",
    };
    dispatch(loaderOn())
    try {
      // const res = await axios.put(`https://dev-backoffice.tribe.sn/gift/delete/${giftId}`, formData)
      const res = await updategifts(giftId, formData);
      if (res != null) {
        fetchGifts();
        togglePopup();
        console.log("gift status updated sucessfully");
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  };

  // const fetchGifts = async() => {
  //     // axios.get("https://dev-backoffice.tribe.sn/gift/active")
  //     //     .then((response) => {
  //         const response=await getactivegifts();

  //             const backendData: any[] = response.data;
  //             const frontendData = backendData.map((backendRow: any) => ({
  //                 Gift: backendRow.gift_name,
  //                 Gift_Description: backendRow.gift_description,
  //                 Gift_Criteria: backendRow.gift_criteria,
  //                 gift_id: backendRow.gift_id,
  //             }));

  //             setRows3(frontendData);
  //             console.log(frontendData);

  // };

  const fetchGifts = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn())
    try {
      const response = await getactivegifts();
      if (response && Array.isArray(response)) {
        const frontendData = response.map((backendRow: any) => ({
          Gift: backendRow.gift_name,
          Gift_Description: backendRow.gift_description,
          Gift_Criteria: backendRow.gift_criteria,
          gift_id: backendRow.gift_id,
          no_of_tokens: backendRow.no_of_tokens,
        }));

        setRows3(frontendData);
        console.log(frontendData);
        dispatch(loaderOff())
      } else {
        console.error("Invalid or empty response from getAchievementsActive");
      }
    } catch (error) {
      console.error("Error fetching achievements data:", error);
      dispatch(loaderOff())
    }
  };

  // const fetchGifts1 = async() => {
  //     // axios.get("https://dev-backoffice.tribe.sn/gift")
  //     //     .then((response) => {
  //         const response=await getgifts()
  //             const backendData: any[] = response.data;
  //             const frontendData = backendData.map((backendRow: any) => ({
  //                 Sl_No: backendRow.gift_id,
  //                 Gifts: backendRow.gift_name,
  //                 Gift_Description: backendRow.gift_description,
  //                 Gift_Criteria: backendRow.gift_criteria,
  //                 status: backendRow.status,
  //             }));
  //             setRows5(frontendData);
  //             console.log(frontendData);

  // };

  const fetchGifts1 = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn())
    try {
      const response = await getgifts();
      if (response && Array.isArray(response)) {
        const frontendData = response.map((backendRow: any) => ({
          Sl_No: backendRow.gift_id,
          Gifts: backendRow.gift_name,
          Gift_Description: backendRow.gift_description,
          Gift_Criteria: backendRow.gift_criteria,
          status: backendRow.status,
          no_of_tokens: backendRow.no_of_tokens,
        }));
        setRows5(frontendData);
        console.log(frontendData);
        dispatch(loaderOff())
      } else {
        console.error("Invalid or empty response from getAchievementsActive");
      }
    } catch (error) {
      console.error("Error fetching achievements data:", error);
      dispatch(loaderOff())
    }
  };

  const handleAddGift = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    const formData = {
      gift_name: formValues.gift_name.value,
      gift_description: formValues.gift_description.value,
      gift_criteria: formValues.gift_criteria.value,
      no_of_tokens: formValues.no_of_tokens.value,
    };
    dispatch(loaderOn())
    try {
      // const res = await axios.post("https://dev-backoffice.tribe.sn/gift", formData);
      const res = await creategift(formData);
      if (res != null) {
        console.log("Gifts Created successfully");
        Object.keys(formValues).forEach((key: any) => {
          formValues[key].value = "";
        });
        handleClose1();
        fetchGifts();
        fetchGifts1();
        dispatch(loaderOff())
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  };

  const handleEdittGift = async (giftId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    // const invalidSubmit = validateForm("gift-add");
    // if (!invalidSubmit) {
      const formData = {
        gift_name: formValues.gift_name.value,
        gift_description: formValues.gift_description.value,
        gift_criteria: formValues.gift_criteria.value,
        no_of_tokens: formValues.no_of_tokens.value,
      };
      dispatch(loaderOn())
      try {
        // const res = await axios.put(`https://dev-backoffice.tribe.sn/gift/${giftId}`, formData)
        const res = await updategift(giftId, formData);
        if (res != null) {
          console.log("updated sucessfully");
          Object.keys(formValues).forEach((key: any) => {
            formValues[key].value = "";
          });
          handleClose4();
          fetchGifts();
          fetchGifts1();
          dispatch(loaderOff())
        }
      } catch (error) {
        console.log(error);
        dispatch(loaderOff())
      }
    // }
  };

  useEffect(() => {
    fetchGifts();
    fetchGifts1();
  }, []);

  const textFieldStyle: React.CSSProperties = {
    border: "1px solid #8E47E6",
    height: "6vh",
    borderRadius: "12px",
    paddingBottom: "10%",
    background: "#FFFFFF",
    marginTop: "10px",
    // width:'100%'
  };
  const CustomTabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTabs-indicator": {
      backgroundColor: "#8E47E6", // Set your custom color here
    },
  }));
  // Restriction
  const [userId, setUserId] = useState(0);
  let temp: number;
  const [gifts_read, setGiftsRead] = useState(false);
  const [gifts_write, setGiftsWrite] = useState(false);

  const getRoleData = async () => {
    const res: any = await getUser();

    if (res && res.role_type) {
      if (
        res.role_type.gifts_read !== undefined &&
        res.role_type.gifts_write !== undefined
      ) {
        setGiftsRead(res.role_type.gifts_read);
        setGiftsWrite(res.role_type.gifts_write);
      }
    }
  };

  const filteredColumns = gifts_write ? columns4 : columns4.filter(column => column.id !== 'gift_id');

  const getLoginUserData = async () => {
    const res: any = await getUser();
    if (res != null) {
      temp = res.id;
      setUserId(temp);
    }
    console.log(temp);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getLoginUserData();
      getRoleData();
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ width: "100%", marginTop: "40px" }}>
      <Box>
        <CustomTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Gifts"
            {...a11yProps(0)}
            style={{
              color: "#8E47E6",
              fontWeight: "600",
              textTransform: "none",
              fontSize: "16px",
            }}
          />
          <Tab
            label="Gifts History"
            {...a11yProps(1)}
            style={{
              color: "#8E47E6",
              fontWeight: "600",
              textTransform: "none",
              fontSize: "16px",
            }}
          />
        </CustomTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontFamily: "",
                  fontSize: "16px",
                  fontWeight: "700",
                  paddingRight: "43px",
                }}
              >
                Gifts
              </Typography>
              <span>
                {" "}
                <TextField
                  // label="Search"
                  value={searchQuery3}
                  onChange={(e) => setSearchQuery3(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#140026" }} />
                      </InputAdornment>
                    ),
                    style: {
                      width: "300px",
                      boxShadow: "0px 4px 4px 0px rgba(142, 71, 230, 0.25)",
                      borderRadius: "6px",
                    },
                  }}
                />
              </span>
            </div>
            {/* <div>
              <CustomPrimaryButton

                onClick={handleOpen1}
                disabled={!gifts_write && !gifts_read}
                startIcon={<CardGiftcardIcon />}
              >
                Set A Gift
              </CustomPrimaryButton>
            </div> */}

            {gifts_write === false ? (
              ""
            ) : (
              <div>
                <CustomPrimaryButton
                  onClick={handleOpen1}
                  startIcon={<CardGiftcardIcon />}
                >
                  Set A Gift
                </CustomPrimaryButton>
              </div>
            )}
          </div>

          <Box
            sx={{
              color: "black",
              marginTop: "30px",
            }}
          >
            <Paper sx={{ overflow: "hidden" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                }}
              ></div>
              <TableContainer
                sx={{
                  maxHeight: 440,
                  borderRadius: "18px",
                  boxShadow: "0px 4px 12px 0px #8E47E640",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}>
                      {columns4.map((column4) => (
                        <TableCell
                          key={column4.id}
                          align={column4.align}
                          style={{
                            minWidth: column4.minWidth,
                            color: column4.color,
                          }}
                        >
                          {/* {column4.label} */}
                          {gifts_write || column4.id !== 'gift_id' ? column4.label : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows3
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.Gift_Description}
                          >
                            {filteredColumns.map((column4) => {
                              const value = row[column4.id];
                              return (
                                <TableCell
                                  key={column4.id}
                                  align={column4.align}
                                >
                                  {column4.id === "gift_id" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      {gifts_write === true ? (
                                        <p
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleEditGift(row.gift_id)
                                          }
                                        >
                                          <CreateIcon
                                            style={{ color: "#5151ED" }}
                                          />
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            cursor: "not-allowed",
                                            filter: "blur(1px)",
                                          }}
                                        >
                                          <CreateIcon
                                            style={{ color: "#5151ED" }}
                                          />
                                        </p>
                                      )}
                                      {gifts_write === true ? (
                                        <p
                                          onClick={() => {
                                            setSelectedGiftId(row.gift_id);
                                            setopenDialog(true);
                                          }}
                                          // onClick={() =>
                                          //   handleDeleteGift(row.gift_id)
                                          // }
                                        >
                                          <DeleteIcon
                                            style={{ color: "#DF2525" }}
                                          />
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            cursor: "not-allowed",
                                            filter: "blur(1px)",
                                          }}
                                        >
                                          <DeleteIcon
                                            style={{ color: "#DF2525" }}
                                          />
                                        </p>
                                      )}
                                    </div>
                                  ) : column4.format &&
                                    typeof value === "number" ? (
                                    column4.format(value)
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <CommonDialog
                open={openDialog}
                handleClose={handleCloseDialog}
                handleConfirm={() => {
                  handleDeleteGift(selectedGiftId);
                  handleCloseDialog();
                }}
                contentText="Are you sure to delete gift?"
              />
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredRows3.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>

          <Modal
            open={openGift}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={
                  {
                    // display: "flex",
                    // flexDirection:'row'
                  }
                }
                onClick={handleClose4}
              >
                <div style={{ float: "left" }}>
                  {" "}
                  <h1>Update Gift</h1>
                </div>

                <div style={{ float: "right" }}>
                  <CloseIcon />
                </div>
              </div>

              <Grid container spacing={2} style={{ marginTop: "5px" }}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Gift Names
                    <CustomTextField
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="gift_name"
                      readOnly={true}
                    />
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Gift Description
                    <CustomTextField
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="gift_description"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "5px" }}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Gift Criteria
                    <CustomTextField
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="gift_criteria"
                    />
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    No of Tokens
                    <CustomTextField
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="no_of_tokens"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid
                  item
                  lg={6}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <CommonDialog
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    handleConfirm={() => {
                      handleEdittGift(giftId);
                      handleCloseDialog();
                    }}
                    contentText="Are you sure to Update gift?"
                  />

                  <CustomPrimaryButton
                    style={{
                      marginTop: "20px",
                    }}
                    onClick={() => {
                      const invalidSubmit = validateForm("gift-add");
                      if(!invalidSubmit){
                      setopenDialog(true);
                      }
                    }}
                  >
                    Update
                  </CustomPrimaryButton>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <Modal
            open={open1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
                onClick={handleClose1}
              >
                <CloseIcon />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "Left",
                }}
              >
                <h1>Add Gifts</h1>
              </div>
              <Grid container spacing={2} style={{ marginTop: "5px" }}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Gift Name
                    <CustomTextField
                      placeholder="Enter your Gift Name"
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="gift_name"
                    />
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Gift Description
                    <CustomTextField
                      placeholder="Enter Your Gift Description"
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="gift_description"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "5px" }}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    Gift Criteria
                    <CustomTextField
                      placeholder="Enter Your Gift  Criteria"
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="gift_criteria"
                    />
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    No of Tokens
                    <CustomTextField
                      placeholder="Enter Your Number of Tokens"
                      formValues={formValues}
                      handleChange={(e: any) => handleChangee(e)}
                      fieldName="no_of_tokens"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid
                  item
                  lg={6}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <CommonDialog
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    handleConfirm={() => {
                      handleAddGift();
                      handleCloseDialog();
                    }}
                    contentText="Are you sure to add gift?"
                  />

                  <CustomPrimaryButton
                    style={{
                      padding: "15px 25px",
                      marginTop: "15px",
                    }}
                    onClick={() => {
                      const invalidSubmit = validateForm("gift-add");
                      if (!invalidSubmit) {
                        setopenDialog(true);
                      }
                    }}
                  >
                    Add
                  </CustomPrimaryButton>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontFamily: "",
                  fontSize: "16px",
                  fontWeight: "700",
                  paddingRight: "43px",
                }}
              >
                Gifts History
              </Typography>
              <span>
                <TextField
                  // label="Search"
                  value={searchQuery5}
                  onChange={(e) => setSearchQuery5(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#140026" }} />
                      </InputAdornment>
                    ),
                    style: {
                      width: "300px",
                      boxShadow: "0px 4px 4px 0px rgba(142, 71, 230, 0.25)",
                      borderRadius: "6px",
                    },
                  }}
                />
              </span>
            </div>

            {/* <Button
                                style={{
                                    color: "black",
                                    backgroundColor: "#8E47E6",
                                }}
                                endIcon={<DownloadIcon />}
                            >
                                Export{" "}
                            </Button> */}
          </div>

          <Box
            sx={{
              color: "black",
              marginTop: "30px",
            }}
          >
            <div></div>
            <Paper sx={{ overflow: "hidden" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                }}
              ></div>
              <TableContainer
                sx={{
                  maxHeight: 440,
                  borderRadius: "18px",
                  boxShadow: "0px 4px 12px 0px #8E47E640",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}>
                      {columns6.map((column6) => (
                        <TableCell
                          key={column6.id}
                          align={column6.align}
                          style={{
                            minWidth: column6.minWidth,
                            color: column6.color,
                          }}
                        >
                          {column6.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows5
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.Gift_Description}
                          >
                            {columns6.map((column6) => {
                              const value = row[column6.id];
                              return (
                                <TableCell
                                  key={column6.id}
                                  align={column6.align}
                                >
                                  {column6.format && typeof value === "number"
                                    ? column6.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredRows5.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Box>
      </CustomTabPanel>
    </Box>
  );
}