import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { getUserdetails, updateStatus } from "../utilities/userService";
import { updateuser, updateusers } from "../utilities/backofficeservice";
import Checkbox from "@mui/material/Checkbox";
import CommonDialog from "../commondialog/confirm";
import { useDispatch, useSelector } from "react-redux";
import { loaderOff, loaderOn, logout, openSnackbar } from "../redux/actions";
import { getUser } from "../utilities/adminservice";
import { useNavigate } from "react-router-dom";
import store from "../redux/store";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid white",
  // boxShadow: 24,
  p: 4,
};

interface Column3 {
  id: "First Name" | "Email" | "Action" | "SerialNumber" | "Last Name";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns3: readonly Column3[] = [
  { id: "SerialNumber", label: "Sl No" },
  { id: "First Name", label: "First Name" },
  { id: "Last Name", label: "Last Name" },
  { id: "Email", label: "Email" },
  { id: "Action", label: "Restrict Access" },
];

interface Data3 {
  first_name: string;
  last_name: string;
  userId: string;
  email: string;
}

export default function User_Access() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows2, setRows2] = useState<Data3[]>([]);
  const [searchQuery2, setSearchQuery2] = useState("");
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [showPopup, setShowPopup] = useState(false);

  const [showContent, setShowContent] = useState(false);
  const [openDialog, setopenDialog] = React.useState(false);
  const [selectedAccessId, setSelectedAccessId] = useState<any | null>(null);


  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isTokenExpired = useSelector((state: any) => state.auth.isTokenExpired);
  console.log("syed" + isLoggedIn);
  useEffect(() => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken || isTokenExpired) {
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    } else if (!isLoggedIn) { 
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    }
  }, [isLoggedIn, isTokenExpired]);


















  const handleCloseDialog = () => {
    setopenDialog(false);
    setShowContent(false);
  };
  const filteredRows2 = rows2.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery2.toLowerCase())
    )
  );
  const slicedRows2 = filteredRows2.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [accessrestriction_read, setAccessrestriction_read] = useState(false);
  const [accessrestriction_write, setAccessrestriction_write] = useState(false);

  const getRoleData = async () => {
    const res: any = await getUser();

    if (res && res.role_type) {
      if (
        res.role_type.accessrestriction_read !== undefined &&
        res.role_type.accessrestriction_write !== undefined
      ) {
        setAccessrestriction_read(res.role_type.accessrestriction_read);
        setAccessrestriction_write(res.role_type.accessrestriction_write);
      }
    }
  };

  const filteredColumns = accessrestriction_write ? columns3 : columns3.filter(column => column.id !== 'Action');

  const fetchUserData = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    const backendData = await getUserdetails();

    const frontendData = backendData.map((backendRow: any) => ({
      userId: backendRow.userId,
      first_name: backendRow.first_name,
      last_name: backendRow.last_name,
      email: backendRow.email,
    }));

    setRows2(frontendData);
    console.log(frontendData);
  };

  useEffect(() => {
    fetchUserData();
    getRoleData();
  }, []);

  const userStatusDelete = async (userId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn())
    try{
     
    const formData: any = {
      account_status: "deleted",
    };
    const res = await updateStatus(userId, formData);
    fetchUserData();
    dispatch(loaderOff())
  }catch(error){
    
  }
  };
  const handleCheckboxClick = (userId: string) => {
    const isSelected = selectedRows.includes(userId);
    if (isSelected) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((id) => id !== userId)
      );
    } else {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, userId]);
    }
  };
  const handleRestrictClick = async () => {
    for (const userId of selectedRows) {
      await handleApplyClick(userId);
    }

    setSelectedRows([]);
  };

  const handleCancelClick = () => {
    setSelectedRows([]);
  };

  /////////////////

  //   fetch("https://dev-backoffice.tribe.sn/users/all")
  //   .then((response) => response.json())
  //   .then((backendData: Data3[]) => {
  //     const frontendData: Data3[] = backendData.map((backendRow) => ({
  //        userId: backendRow. userId,
  //       email: backendRow.email,

  //     }));

  //     setRows2(frontendData);
  //     console.log(frontendData);
  //   })
  //   .catch((error) => {
  //     console.error("Error fetching data:", error);
  //   });

  const dispatch = useDispatch();

  const handleApplyClick = async (userId: string) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn())
    // const apiEndpoint = `https://dev-backoffice.tribe.sn/users/update/${userId}`;
    const apiEndpoint = await updateuser(userId);

    try {
      // Make the API call to delete the user with the specified userId
      //   const res = await axios.put(apiEndpoint, {});
      const res = await updateusers(apiEndpoint);

      // Update the frontend state by filtering out the deleted user
      setRows2((prevRows) => prevRows.filter((row) => row.userId !== userId));

      // Handle the API response as needed
      console.log("API response:", res);
    } catch (error) {
      // Handle errors, e.g., display an error message to the user
      console.error("Error calling API:", error);
      dispatch(loaderOff())
    }
  };

  return (
    <Box marginTop={"40px"}>
      <div
        style={{
          display: "flex",
          justifyContent: "row",
          marginRight: "20px",
          paddingBottom: "34px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: "17px",
              fontWeight: "700",
              paddingRight: "40px",
            }}
          >
            Restrict Access
          </Typography>
          <span
          // style={{
          //   display: "flex",
          //   justifyContent: "start",
          // }}
          >
            <TextField
              label="Search"
              value={searchQuery2}
              onChange={(e) => setSearchQuery2(e.target.value)}
              variant="outlined"
              margin="normal"
              style={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
              inputProps={{
                style: {
                  width: "200px",
                },
              }}

              // InputProps={{
              //     startAdornment: (
              //       <InputAdornment position="start">
              //         <SearchIcon />
              //       </InputAdornment>
              //     ),
              //   }}
            />
          </span>
        </div>
        {/* <span>
          <Button
            style={{
              color: "black",
              backgroundColor: "#FFFFFF",
              border: "1px solid #8E47E6",
              marginRight: "20px",
              fontFamily: "Open Sans",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "22px",
              letterSpacing: "0em",
              textAlign: "left",
            }}
            endIcon={<ExpandMoreOutlinedIcon />}
          >
            Last 6 Months
          </Button>
          <Button
            style={{
              color: "#FFFFFF",
              backgroundColor: "#8E47E6",
              fontFamily: "Open Sans",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "22px",
              letterSpacing: "0em",
              textAlign: "left",
            }}
            endIcon={<DownloadIcon />}
          >
            Export{" "}
          </Button>
        </span> */}
      </div>

      <Box
        sx={{
          color: "black",
          marginTop: "20px",
        }}
      >
        <Paper sx={{ overflow: "hidden" }}>
          {/* <div style={{
                        display: 'flex',
                        justifyContent: 'start'
                    }}>
                        <TextField
                            label="Search"
                            value={searchQuery2}
                            onChange={(e) => setSearchQuery2(e.target.value)}
                            variant="outlined"
                            margin="normal"
                            inputProps={{
                                style: {
                                    width: '200px',


                                }
                            }}

                        />
                    </div> */}
          <TableContainer
            sx={{
              maxHeight: 440,
              borderRadius: "18px",
              boxShadow: "0px 4px 12px 0px #8E47E640",
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ color: "#8E47E6" }}
            >
              <TableHead>
                <TableRow sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}>
                  {columns3.map((column3) => (
                    <TableCell
                      key={column3.id}
                      align={column3.align}
                      style={{ minWidth: column3.minWidth, color: "#8E47E6" }}
                    >
                      {/* {column3.label} */}
                      {accessrestriction_write || column3.id !== 'Action' ? column3.label : null}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {slicedRows2.map((row, index) => (
                  <TableRow
                    key={row.email}
                    sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                  >
                    {filteredColumns.map((column, columnIndex) => (
                      <TableCell key={column.id}>
                        {column.id === "SerialNumber" ? index + 1 : null}
                        {column.id === "First Name" ? row.first_name : null}
                        {column.id === "Last Name" ? row.last_name : null}
                        {column.id === "Email" ? row.email : null}
                        {column.id === "Action" ? (
                          accessrestriction_write === true ? (
                            <DeleteIcon
                              onClick={() => {
                                setopenDialog(true);
                                setSelectedAccessId(row.userId);
                              }}
                              style={{ cursor: "pointer", color: "#DF2525" }}
                            />
                          ) : (
                            <DeleteIcon
                              style={{
                                color: "#DF2525",
                                cursor: "not-allowed",
                                filter: "blur(1px)",
                              }}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CommonDialog
            open={openDialog}
            handleClose={handleCloseDialog}
            handleConfirm={() => {
              userStatusDelete(selectedAccessId);
              handleCloseDialog();
            }}
            contentText="Confirm if you wish to restrict user access to the Tribe."
          />

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredRows2.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "63px 0px 0px 0px",
        }}
      >
        <Button
          style={{
            backgroundColor: "#8E47E6",
            marginRight: "20px",

            fontFamily: "poppins",
            textTransform: "none",
            fontSize: "12px",
            fontWeight: 600,
            lineHeight: "18px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#FFFFFF",
          }}
          onClick={handleRestrictClick}
        >
          Restrict{" "}
        </Button>
        <Button
          style={{
            color: "#000000",
            backgroundColor: "#FFFFFF",
            border: "1px solid #8E47E6",
            fontFamily: "poppins",
            textTransform: "none",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "18px",
            letterSpacing: "0em",
            textAlign: "left",
          }}
          onClick={handleCancelClick}
        >
          Cancel{" "}
        </Button>
      </div> */}
    </Box>
  );
}
