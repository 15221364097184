import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { loaderOff, loaderOn, logout, openSnackbar } from "../redux/actions";
import { Stack } from "@mui/system";
import internalApi from "../utilities/internalApi";
import { createRole } from "../utilities/adminservice";
import { CustomPrimaryButton } from "../customtextfield/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "../redux/store";
import { useEffect } from "react";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Create_Role() {


  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isTokenExpired = useSelector((state: any) => state.auth.isTokenExpired);
  console.log("syed" + isLoggedIn);
  useEffect(() => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken || isTokenExpired) {
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    } else if (!isLoggedIn) { 
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    }
  }, [isLoggedIn, isTokenExpired]);

  const [formvalues, setFormvalues]: any = React.useState({
    role_name: {
      name: "role_name",
      value: "",
      error: false,
      errorMessage: "Please enter role name",
    },
    role_desc: {
      name: "role_desc",
      value: "",
      error: false,
      errorMessage: "Please enter role description",
    },
    location_read: {
      name: "location_read",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    location_write: {
      name: "location_write",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    token_read: {
      name: "token_read",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    token_write: {
      name: "token_write",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    achievements_read: {
      name: "achievements_read",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    achievements_write: {
      name: "achievements_write",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    gifts_read: {
      name: "gifts_read",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    gifts_write: {
      name: "gifts_write",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    features_read: {
      name: "features_read",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    features_write: {
      name: "features_write",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    bonus_read: {
      name: "bonus_read",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    bonus_write: {
      name: "bonus_write",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    locationuploaded_read: {
      name: "locationuploaded_read",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    locationuploaded_write: {
      name: "locationuploaded_write",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    tokenreceived_read: {
      name: "tokenreceived_read",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    tokenreceived_write: {
      name: "tokenreceived_write",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    accessrestriction_read: {
      name: "accessrestriction_read",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
    accessrestriction_write: {
      name: "accessrestriction_write",
      value: false,
      error: false,
      errorMessage: "Please select",
    },
  });
  const clearForm = () => {
    setFormvalues({
      role_name: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please enter Role Name",
      },
      role_desc: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please enter  Role Desc",
      },
      location_read: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select check box",
      },
      location_write: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      token_read: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      token_write: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      achievements_read: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      achievements_write: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      bonus_read: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      bonus_write: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      gifts_read: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      gifts_write: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      features_read: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      features_write: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      locationuploaded_read: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      locationuploaded_write: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      tokenreceived_read: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      tokenreceived_write: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      accessrestriction_read: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
      accessrestriction_write: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please select checkbox",
      },
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormvalues({
      ...formvalues,
      [name]: {
        ...formvalues[name],
        value,
        error: value === "" ? true : false,
      },
    });
  };

  const handleCheckChange = (e: any) => {
    setFormvalues((prevState: { [x: string]: any }) => ({
      ...prevState,
      [e.target.name]: {
        ...prevState[e.target.name],
        value: e.target.checked,
      },
    }));
  };
  const validateForm = () => {
    let invalidSubmit = false;
    const formFields = Object.keys(formvalues);
    let newFormValues = { ...formvalues };
    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formvalues[currentField].value;
      if (currentValue === "") {
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: true,
          },
        };
      }
    }
    setFormvalues(newFormValues);
    Object.values(newFormValues).every((number: any) => {
      if (number.error) {
        invalidSubmit = number.error;
        //dispatch(loaderOff())
        return false;
      }
    });
    //dispatch(loaderOff())
    return invalidSubmit;
  };

  const dispatch = useDispatch();
  const submitForm = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
    const invalidSubmit = validateForm();
    if (!invalidSubmit) {
      const formData = {
        role_name: formvalues.role_name.value,
        role_desc: formvalues.role_desc.value,

        location_read: formvalues.location_read.value,
        location_write: formvalues.location_write.value,

        token_read: formvalues.token_read.value,
        token_write: formvalues.token_write.value,

        bonus_read: formvalues.bonus_read.value,
        bonus_write: formvalues.bonus_write.value,

        achievements_read: formvalues.achievements_read.value,
        achievements_write: formvalues.achievements_write.value,

        gifts_read: formvalues.gifts_read.value,
        gifts_write: formvalues.gifts_write.value,

        features_read: formvalues.features_read.value,
        features_write: formvalues.features_write.value,

        locationuploaded_read: formvalues.locationuploaded_read.value,
        locationuploaded_write: formvalues.locationuploaded_write.value,

        tokenreceived_read: formvalues.tokenreceived_read.value,
        tokenreceived_write: formvalues.tokenreceived_write.value,

        accessrestriction_read: formvalues.accessrestriction_read.value,
        accessrestriction_write: formvalues.accessrestriction_write.value,
      };
      dispatch(loaderOn());
      try {
        const res = await createRole(formData);

        // Now you can access properties on the resolved value
        if (res.status === 200) {
          alert("Role created successfully...");
        }

        console.log("formData", formData);
        console.log("res", res);
        dispatch(openSnackbar("role added successfully", "green"));
        dispatch(loaderOff());
      } catch (error) {
        console.error("Error creating role:", error);
        dispatch(loaderOff());
      }
    }
  };

  React.useEffect(()=>{
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.log("JWT token not available. Unable to make API call.");
      dispatch(
        openSnackbar("Opps something went wrong please login again", "red")
      );
      return;
    }
  })
  
  return (
    <Box>
      <div
        style={{ display: "flex", justifyContent: "start", marginTop: "30px" }}
      >
        <h1>Add New Role</h1>
      </div>
      <div
        style={{
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
          paddingTop: "45px",
          paddingBottom: "100px",
          borderRadius: "18px",
          border: "1px solid #8E47E640",
          marginRight: "15px",
        }}
      >
        <div>
          <div
            style={{
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "left",
              paddingBottom: "20px",
              paddingLeft: "20px",
            }}
          >
            Role Name{" "}
          </div>
          <TextField
            name="role_name"
            autoComplete="off"
            onChange={(e: any) => handleChange(e)}
            hiddenLabel
            value={formvalues.role_name.value}
            error={formvalues.role_name.error}
            helperText={
              formvalues.role_name.error && formvalues.role_name.errorMessage
            }
            id="filled-hidden-label-small"
            variant="filled"
            size="small"
            InputProps={{
              placeholder: "Enter Your role name",
              disableUnderline: true,
              style: {
                width: "250px",
                border: "1px solid #8E47E6",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                height: "7vh",
                borderRadius: "12px",
                // paddingBottom: '10px',
                background: "#FFFFFF",
                // marginTop: "10px",
              },
            }}
            style={{
              display: "flex",
              justifyContent: "start",
              paddingLeft: "20px",
            }}
          />
        </div>
        <Grid container spacing={2} style={{ gap: "50px" }}>
          <Grid item lg={5}>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "bold",
                textAlign: "left",
                paddingTop: "25px",
                paddingBottom: "20px",
                paddingLeft: "20px",
              }}
            >
              Role Description
            </div>
            <FormControl
              style={{
                display: "flex",
                paddingLeft: "20px",
                justifyContent: "start",
              }}
            >
              <TextareaAutosize
                name="role_desc"
                autoComplete="off"
                onChange={(e) => handleChange(e)}
                value={formvalues.role_desc.value}
                aria-label="empty textarea"
                placeholder="Enter Your role description..."
                style={{
                  maxWidth: "500px",
                  border: "1px solid #8E47E6",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  minHeight: "300px",
                  padding: "10px 0px 0px 10px",
                  borderRadius: "12px",
                }}
              />
              {formvalues.role_desc.error && (
                <FormHelperText error>
                  {formvalues.role_desc.errorMessage}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item lg={5}>
            <Box>
              <Box sx={{ textAlign: "left", paddingTop: "25px" }}>
                <h4>Location and Token:</h4>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ width: "100px" }}>
                    <p>Location</p>
                  </Box>
                  <Select variant="standard" disableUnderline>
                    <Box sx={{ width: "100px" }}>
                      <Box sx={{}}>
                        <Checkbox
                          name="location_read"
                          checked={formvalues.location_read.value}
                          onChange={(e) => handleCheckChange(e)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        <span>Read</span>
                      </Box>
                      <Box sx={{}}>
                        <Checkbox
                          name="location_write"
                          checked={formvalues.location_write.value}
                          onChange={(e) => handleCheckChange(e)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        <span>Write</span>
                      </Box>
                    </Box>
                  </Select>
                </Box>
              </Box>
              <Box>
                <Box sx={{ textAlign: "left" }}>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "100px" }}>
                      <p>Token</p>
                    </Box>
                    <Select variant="standard" disableUnderline>
                      <Box sx={{ width: "100px" }}>
                        <Box sx={{}}>
                          <Checkbox
                            name="token_read"
                            checked={formvalues.token_read.value}
                            onChange={(e) => handleCheckChange(e)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <span>Read</span>
                        </Box>
                        <Box sx={{}}>
                          <Checkbox
                            name="token_write"
                            checked={formvalues.token_write.value}
                            onChange={(e) => handleCheckChange(e)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <span>Write</span>
                        </Box>
                      </Box>
                    </Select>
                  </Box>
                </Box>

                <Box sx={{ textAlign: "left" }}>
                  <h4>Perks:</h4>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "100px" }}>
                      <p>Bonus</p>
                    </Box>
                    <Select variant="standard" disableUnderline>
                      <Box sx={{ width: "100px" }}>
                        <Box sx={{}}>
                          <Checkbox
                            name="bonus_read"
                            checked={formvalues.bonus_read.value}
                            onChange={(e) => handleCheckChange(e)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <span>Read</span>
                        </Box>
                        <Box sx={{}}>
                          <Checkbox
                            name="bonus_write"
                            checked={formvalues.bonus_write.value}
                            onChange={(e) => handleCheckChange(e)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <span>Write</span>
                        </Box>
                      </Box>
                    </Select>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "100px" }}>
                      <p>Achievements</p>
                    </Box>
                    <Select variant="standard" disableUnderline>
                      <Box sx={{ width: "100px" }}>
                        <Box sx={{}}>
                          <Checkbox
                            name="achievements_read"
                            checked={formvalues.achievements_read.value}
                            onChange={(e) => handleCheckChange(e)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <span>Read</span>
                        </Box>
                        <Box sx={{}}>
                          <Checkbox
                            name="achievements_write"
                            checked={formvalues.achievements_write.value}
                            onChange={(e) => handleCheckChange(e)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <span>Write</span>
                        </Box>
                      </Box>
                    </Select>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "100px" }}>
                      <p>Gifts</p>
                    </Box>
                    <Select variant="standard" disableUnderline>
                      <Box sx={{ width: "100px" }}>
                        <Box sx={{}}>
                          <Checkbox
                            name="gifts_read"
                            checked={formvalues.gifts_read.value}
                            onChange={(e) => handleCheckChange(e)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <span>Read</span>
                        </Box>
                        <Box sx={{}}>
                          <Checkbox
                            name="gifts_write"
                            checked={formvalues.gifts_write.value}
                            onChange={(e) => handleCheckChange(e)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <span>Write</span>
                        </Box>
                      </Box>
                    </Select>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "100px" }}>
                      <p>Features</p>
                    </Box>
                    <Select variant="standard" disableUnderline>
                      <Box sx={{ width: "100px" }}>
                        <Box sx={{}}>
                          <Checkbox
                            name="features_read"
                            checked={formvalues.features_read.value}
                            onChange={(e) => handleCheckChange(e)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <span>Read</span>
                        </Box>
                        <Box sx={{}}>
                          <Checkbox
                            name="features_write"
                            checked={formvalues.features_write.value}
                            onChange={(e) => handleCheckChange(e)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <span>Write</span>
                        </Box>
                      </Box>
                    </Select>
                  </Box>

                  <Box sx={{ textAlign: "left" }}>
                    <h4>End_User:</h4>

                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ width: "100px" }}>
                        <p>Location Uploaded</p>
                      </Box>
                      <Select variant="standard" disableUnderline>
                        <Box sx={{ width: "100px" }}>
                          <Box sx={{}}>
                            <Checkbox
                              name="locationuploaded_read"
                              checked={formvalues.locationuploaded_read.value}
                              onChange={(e) => handleCheckChange(e)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            <span>Read</span>
                          </Box>
                          <Box sx={{}}>
                            <Checkbox
                              name="locationuploaded_write"
                              checked={formvalues.locationuploaded_write.value}
                              onChange={(e) => handleCheckChange(e)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            <span>Write</span>
                          </Box>
                        </Box>
                      </Select>
                    </Box>

                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ width: "100px" }}>
                        <p>Token Received</p>
                      </Box>
                      <Select variant="standard" disableUnderline>
                        <Box sx={{ width: "100px" }}>
                          <Box sx={{}}>
                            <Checkbox
                              name="tokenreceived_read"
                              checked={formvalues.tokenreceived_read.value}
                              onChange={(e) => handleCheckChange(e)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            <span>Read</span>
                          </Box>
                          <Box sx={{}}>
                            <Checkbox
                              name="tokenreceived_write"
                              checked={formvalues.tokenreceived_write.value}
                              onChange={(e) => handleCheckChange(e)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            <span>Write</span>
                          </Box>
                        </Box>
                      </Select>
                    </Box>

                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ width: "100px" }}>
                        <p>Access Restrication</p>
                      </Box>
                      <Select variant="standard" disableUnderline>
                        <Box sx={{ width: "100px" }}>
                          <Box sx={{}}>
                            <Checkbox
                              name="accessrestriction_read"
                              checked={formvalues.accessrestriction_read.value}
                              onChange={(e) => handleCheckChange(e)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            <span>Read</span>
                          </Box>
                          <Box sx={{}}>
                            <Checkbox
                              name="accessrestriction_write"
                              checked={formvalues.accessrestriction_write.value}
                              onChange={(e) => handleCheckChange(e)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            <span>Write</span>
                          </Box>
                        </Box>
                      </Select>
                    </Box>
                    {/* <Select>
                        <Box sx={{display: 'flex'}}>                              
                            </Box>
                            <Box sx={{width: '100px'}}>
                                <Box sx={{}}><Checkbox
                                    name='enduser_read'
                                    checked={formvalues.enduser_read.value}
                                    onChange={(e)=>handleCheckChange(e)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    /><span>Read</span></Box>
                                <Box sx={{}}><Checkbox
                                    name='enduser_write'
                                    checked={formvalues.enduser_write.value}
                                    onChange={(e)=>handleCheckChange(e)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    /><span>Write</span></Box>
                            </Box>
                            </Select> */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            paddingTop: "20px",
            paddingLeft: "40px",
            justifyContent: "column",
            gap: "20px",
            float: "left",
            paddingBottom: "30px",
          }}
        >
          <CustomPrimaryButton
            //   style={{

            //  transition: 'background-color 0.3s',
            //  border:'1px solid #8E47E6',
            //  textTransform:'none',

            //   }}
            onClick={submitForm}
            //   onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#8E47E6"}
            //   onMouseOut={(e) => e.currentTarget.style.backgroundColor = "white"}
          >
            Save
          </CustomPrimaryButton>
          <CustomPrimaryButton
            style={{
              // transition: 'background-color 0.3s',
              // border:'1px solid #8E47E6',
              // textTransform:'none'
              background: "red",
              border: "none",
            }}
            //   onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#8E47E6'}
            //   onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'white'}
            onClick={clearForm}
          >
            Cancel
          </CustomPrimaryButton>
        </div>
      </div>
    </Box>
  );
}
