import backofficeApi from "./backofficeApi"



export async function updateToken(tokenId: any, formData: any) {
    try {
      const res = await backofficeApi.put(`/token/`+tokenId,formData);
      return res.data;
      
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  export async function updateTokendelete(tokenId: any, formData: any) {
    try {
      const res = await backofficeApi.put(`token/delete/`+tokenId,formData);
      return res.data;
      
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  export async function updateBonus(tokenId: any, formData: any) {
    try {
      const res = await backofficeApi.put(`/bonus/`+tokenId,formData);
      return res.data;
      
    } catch (error) {
      console.log(error);
      return error;
    }
  };


  export async function updateBonusDelete(bonusId: any, formData: any) {
    try {
      const res = await backofficeApi.put(`/bonus/delete/`+bonusId,formData);
      return res.data;
      
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  


  export async function updateuser(userId: any) {
    try {
      const res = await backofficeApi.put(`/users/update/`+userId);
      return res.data;
      
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  

  export async function updateusers(updateusers: any) {
    try {
      const res = await backofficeApi.put(`/users/update/`+updateusers);
      return res.data;
      
    } catch (error) {
      console.log(error);
      return error;
    }
  };


  export async function updateAchievements(achievementid:any,formData: any) {
    try {
      const res = await backofficeApi.put(`/achievement/delete/`+achievementid,formData);
      return res.data;
      
    } catch (error) {
      console.log(error);
      return error;
    }
  };


  export async function updateAchievements1(achievementid:any,formData: any) {
    try {
      const res = await backofficeApi.put(`/achievement/`+achievementid,formData);
      return res.data;
      
    } catch (error) {
      console.log(error);
      return error;
    }
  };
  


  export async function updategifts(giftid:any,formData: any) {
    try {
      const res = await backofficeApi.put(`gift/delete/`+giftid,formData);
      return res.data;
      
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  export async function deletefeatures(featureId:any,formData: any) {
    try {
      const res = await backofficeApi.put(`feature/delete/${featureId}`,formData);
      return res.data;
      
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  export async function updategift(giftid:any,formData: any) {
    try {
      const res = await backofficeApi.put(`gift/`+giftid,formData);
      return res.data;
      
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  export async function updateFeature(featureId:any,formData: any) {
    try {
        const response = await backofficeApi.put(`feature/update/`+featureId,formData)
        return response.data
    } catch (error) {
        return error
    }
    }

  export async function deleteToken(tokenId: any, formData: any) {
    try {
      const res = await backofficeApi.delete(`/token/delete/${tokenId}`, { data: formData });
      return res.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  




export async function getActiveToken() {
try {
    const response = await backofficeApi.get('/token/active')
    return response.data
} catch (error) {
    return error
}
}



export async function getactivegifts() {
  try {
      const response = await backofficeApi.get('/gift/active')
      return response.data
  } catch (error) {
      return error
  }
  }


export async function getToken(tokenId:any) {
    try {
        const response = await backofficeApi.get(`token/`+tokenId)
        return response.data
    } catch (error) {
        return error
    }
    }

    export async function getBonus() {
      try {
          const response = await backofficeApi.get(`bonus`)
          return response.data
      } catch (error) {
          return error
      }
      }

      
      export async function getgifts() {
        try {
            const response = await backofficeApi.get(`gift`)
            return response.data
        } catch (error) {
            return error
        }
        }

        export async function getFeatures() {
          try {
              const response = await backofficeApi.get(`feature`)
              return response.data
          } catch (error) {
              return error
          }
          }


      export async function getBonusId(bonusId:any) {
        try {
            const response = await backofficeApi.get(`bonus/`+bonusId)
            return response.data
        } catch (error) {
            return error
        }
        }

        


      export async function getgiftbyid(giftId:any) {
        try {
            const response = await backofficeApi.get(`gift/`+giftId)
            return response.data
        } catch (error) {
            return error
        }
        }

        export async function getfeaturebyid(featureId:any) {
          try {
              const response = await backofficeApi.get(`feature/`+featureId)
              return response.data
          } catch (error) {
              return error
          }
          }

        export async function getActiveFeature() {
          try {
              const response = await backofficeApi.get('feature/active')
              return response.data
          } catch (error) {
              return error
          }
          }
        
          export async function getFeature() {
            try {
                const response = await backofficeApi.get(`feature/getall`)
                return response.data
            } catch (error) {
                return error
            }
            }


        export async function getAchievements(achievementId:any) {
          try {
              const response = await backofficeApi.get(`achievement/`+achievementId)
              return response.data
          } catch (error) {
              return error
          }
          }


          export async function getAllAchievements() {
            try {
                const response = await backofficeApi.get(`achievement`)
                return response.data
            } catch (error) {
                return error
            }
            }

          

      export async function getActiveBonus() {
        try {
            const response = await backofficeApi.get(`bonus/active`)
            return response.data
        } catch (error) {
            return error
        }
        }


        export async function getAchievementsActive() {
          try {
              const response = await backofficeApi.get(`achievement/active`)
              return response.data
          } catch (error) {
              return error
          }
          }

        


    export async function addToken(formData:any) {
        try {
            const response = await backofficeApi.post(`token`, formData)
            return response.data
        } catch (error) {
            return error
        }
        }

        export async function addBonus(formData:any) {
          try {
              const response = await backofficeApi.post(`bonus`, formData)
              return response.data
          } catch (error) {
              return error
          }
          }



          export async function creategift(formData:any) {
            try {
                const response = await backofficeApi.post(`gift`, formData)
                return response.data
            } catch (error) {
                return error
            }
            }

            export async function createFeature(formData:any) {
              try {
                  const response = await backofficeApi.post(`feature`, formData)
                  return response.data
              } catch (error) {
                  return error
              }
              }

          

          export async function createAchievement(formData:any) {
            try {
                const response = await backofficeApi.post(`achievement`, formData)
                return response.data
            } catch (error) {
                return error
            }
            }

        export async function getAllLocation() {
          try {
              const response = await backofficeApi.get('/locations/all')
              return response.data
          } catch (error) {
              return error
          }
          }

          export async function getAllUser() {
            try {
                const response = await backofficeApi.get('/users/all')
                return response.data
            } catch (error) {
                return error
            }
            }
