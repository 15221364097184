import { Password } from "@mui/icons-material";
import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  styled,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { createAdmin, getRole, getUser } from "../utilities/adminservice";
// import { loaderOff, loaderOn, login, openSnackbar } from '../redux/actions'

import { loaderOff, loaderOn, login, openSnackbar } from "../redux/actions";
import { useDispatch } from "react-redux";
import { getAuthToken } from "../utilities/TokenUtility";
import {
  emailRegex,
  numberRegex,
  passwordRegex,
} from "../CustomRegex/CustomRegex";
import { CustomPrimaryButton } from "../customtextfield/CustomButton";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Create_User() {
  const [len, setLen] = useState(0);
  let Length = 0;
  let TotalTokens = 0;
  const [token, setToken] = useState(0);
  const [pending, setPending] = useState(0);
  const [approved, setApproved] = useState(0);
  const [rejected, setRejected] = useState(0);

  const [Id, setId] = useState("");

  const [FirstName, setFirstName] = useState("");

  const [Email, setEmail] = useState("");

  const [Domain, setDomin] = useState("");

  const dispatch = useDispatch();

  const [formvalues, setFormvalues]: any = useState({
    first_name: {
      name: "",
      value: "",
      error: false,
      errorMessage: "Please enter a First Name",
    },
    last_name: {
      name: "",
      value: "",
      error: false,
      errorMessage: "Please enter a Last Name",
    },
    email: {
      name: "",
      value: "",
      error: false,
      errorMessage: "Please enter a valid  Email",
    },
    // password: {
    //     name: '',
    //     value: '',
    //     error: false,
    //     errorMessage: 'Please enter a Password',
    // },
    // confirm_password: {
    //     name: '',
    //     value: '',
    //     error: false,
    //     errorMessage: 'Please enter confirm Password',
    // },
    given_User_Name: {
      name: "",
      value: "",
      error: false,
      errorMessage: "Please enter a User Name",
    },
    domain: {
      name: "",
      value: "",
      error: false,
      errorMessage: "Please enter a Domain",
    },
    role: {
      name: "",
      value: "",
      error: false,
      errorMessage: "Please enter a Role",
    },
  });
  const [passwordMatch, setPasswordMatch] = useState(true);

  // const handleChange = (name: string | number, newValue: any) => {
  //     setFormvalues((prevState: { [x: string]: any; }) => ({
  //         ...prevState,
  //         [name]: {
  //             ...prevState[name],
  //             value: newValue,
  //         },
  //     }));
  // };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name == "password") {
      //   const password: string = value
      //   const isValidPassword: boolean = passwordRegex.test(password)
      setFormvalues({
        ...formvalues,
        [name]: {
          ...formvalues[name],
          value,
          //   error: !isValidPassword,
        },
      });
    } else if (name === "confirm_password") {
      //   const confirmPassword = value
      //   const isValidConfirmPassword =
      // confirmPassword === formvalues.password.value
      //   setPasswordMatch(isValidConfirmPassword)
      setFormvalues({
        ...formvalues,
        [name]: {
          ...formvalues[name],
          value,
          //   error: !isValidConfirmPassword,
        },
      });
    } else if (name == "email") {
      const email: string = value;
      const isValidEmail: boolean = emailRegex.test(email);
      setFormvalues({
        ...formvalues,
        [name]: {
          ...formvalues[name],
          value,
          error: !isValidEmail,
        },
      });
    } else if (name == "login") {
      const login: string = value;
      const isValidLogin: boolean = emailRegex.test(login);
      setFormvalues({
        ...formvalues,
        [name]: {
          ...formvalues[name],
          value,
          error: !isValidLogin,
        },
      });
    } else if (name == "otp") {
      const number: string = value;
      const isValidNumber: boolean = numberRegex.test(number);
      setFormvalues({
        ...formvalues,
        [name]: {
          ...formvalues[name],
          value,
          error: !isValidNumber,
        },
      });
    } else {
      setFormvalues({
        ...formvalues,
        [name]: {
          ...formvalues[name],
          value,
          error: value === "" ? true : false,
        },
      });
    }
  };
  const validateForm = (type: any) => {
    let invalidSubmit = false;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const email: string = formvalues.email.value;
    const isValidEmail: boolean = emailRegex.test(email);

    setFormvalues({
      ...formvalues,
      ["email"]: {
        ...formvalues["email"],
        error: !isValidEmail,
      },
    });
    invalidSubmit = !isValidEmail;

    // const passwordRegex =
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%^&*])[A-Za-z\d@$!%^&*]{8,}$/
    // const password: string = formvalues.password.value
    // const isValidPassword: boolean = passwordRegex.test(password)
    // if (formValues.password.value == '') {
    //     setFormvalues({
    //   ...formvalues,
    //   ['password']: {
    //     ...formvalues['password'],
    //     error: true,
    //   },
    // })
    // invalidSubmit = !isValidPassword
    const formFields = Object.keys(formvalues);
    let newFormValues = { ...formvalues };
    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formvalues[currentField].value;
      if (type == "create") {
        if (currentValue === "") {
          newFormValues = {
            ...newFormValues,
            [currentField]: {
              ...newFormValues[currentField],
              error: true,
            },
          };
        }
      }
    }
    setFormvalues(newFormValues);
    Object.values(newFormValues).every((number: any) => {
      if (number.error) {
        invalidSubmit = number.error;
        return false;
      }
    });
    return invalidSubmit;
  };

  const [allRole, setAllRole]: any = useState<any[]>([]);

  const getAllRole = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn())
    try {
      const res: any = await getRole();
     // const roleNames = res.data

    //  const roleNames: any = res.map((data: any) => data.role_name);
    console.log('response of roles', res)
      setAllRole(res);
      dispatch(loaderOff())
    } catch (error) {
      console.error("Error fetching roles:", error);
      dispatch(loaderOff())
    }
  };

  useEffect(() => {
    getAllRole();
  }, []);

  const submitForm = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    const invalidSubmit = validateForm("create");
    if (!invalidSubmit) {
      const formData = {
        first_name: formvalues.first_name.value,
        last_name: formvalues.last_name.value,
        email: formvalues.email.value,
        // password: formvalues.password.value,
        // confirm_password: formvalues.confirm_password.value,
        given_User_Name: formvalues.given_User_Name.value,
        domain: formvalues.domain.value,
        role_type: formvalues.role.value,  
      };
      dispatch(loaderOn())
      try {
        const response = await axios.post(
          "https://dev-admin.tribe.sn/admin",
          formData
        );
        console.log(response);

        if (response.status === 200) {
        //   alert("User Created Successfully...");
          console.log(formvalues);
          console.log("Form data submitted successfully");
        } else {
          console.error("Failed to submit form data");
        }
        dispatch(openSnackbar('user added successfully', 'green'))
        dispatch(loaderOff())
      } catch (error) {
        dispatch(openSnackbar("User Already Exists... ", "red"));

        // alert('User Already Exists...')
        console.error("Error submitting form data:", error);
        dispatch(loaderOff())
      }
    }
  };
  const clearForm = () => {
    setFormvalues({
      first_name: {
        name: "",
        value: "",
        errorMessage: "Please enter First Name",
      },
      last_name: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please enter Last Name",
      },
      email: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please enter a valid user name",
      },
      // password: {
      //     name: '',
      //     value: '',
      //     error: false,
      //     errorMessage: 'Please enter a Password',
      // },
      // confirm_password: {
      //     name: '',
      //     value: '',
      //     error: false,
      //     errorMessage: 'Please enter confirm Password',
      // },
      given_User_Name: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please enter a Given User Name",
      },
      domain: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please enter a Domain",
      },
      role: {
        name: "",
        value: "",
        error: false,
        errorMessage: "Please enter a Role",
      },
    });
  };
  const textfeild = {
    border: "1px solid #8E47E6",
    height: "6vh",
    width: "200px",
    borderRadius: "12px",
    // paddingBottom: "10%",
    paddingBottom: "15px",
    background: "#FFFFFF",
    // marginTop: "10px",
    fontSize: "16px",
    fontFamily: "sans-serif",
    fontWeight: "400",
  };

  return (
    <Box marginTop={"30px"}>
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          padding: "10px 0px 10px 0px",
        }}
      >
        <p style={{ fontSize: "17px", fontWeight: "700" }}>
          Add New User / Member
        </p>
      </div>
      <div
        style={{
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",

          borderRadius: "18px",
          border: "1px solid #8E47E640",
          marginRight: "15px",
          display: "flex",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={4}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  textAlign: "left",
                  padding: "0px 0px 10px 0px",
                }}
              >
                First Name
              </div>
              <TextField
                id="filled-basic"
                variant="filled"
                name="first_name"
                value={formvalues.first_name.value}
                onChange={(e: any) => handleChange(e)}
                autoComplete="off"
                InputProps={{
                  disableUnderline: true,
                  style: textfeild,
                  // style: {
                  //     height: '40px', width: '180px', border: '1px solid #8E47E6',
                  //     paddingBottom: '15px'
                  // }
                }}
                error={formvalues.first_name.error}
                helperText={
                  formvalues.first_name.error &&
                  formvalues.first_name.errorMessage
                }
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  textAlign: "left",
                  padding: "0px 0px 10px 0px",
                }}
              >
                Last Name
              </div>

              <TextField
                id="filled-basic"
                variant="filled"
                name="last_name"
                value={formvalues.last_name.value}
                onChange={(e: any) => handleChange(e)}
                InputProps={{
                  disableUnderline: true,
                  style: textfeild,
                  // style: {
                  //     height: '40px', width: '180px', border: '1px solid #8E47E6',
                  //     paddingBottom: '15px'
                  // }
                }}
                autoComplete="off"
                error={formvalues.last_name.error}
                helperText={
                  formvalues.last_name.error &&
                  formvalues.last_name.errorMessage
                }
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  textAlign: "left",
                  padding: "0px 0px 10px 0px",
                }}
              >
                Email ID
              </div>
              <TextField
                id="filled-basic"
                variant="filled"
                name="email"
                value={formvalues.email.value}
                onChange={(e: any) => handleChange(e)}
                InputProps={{
                  disableUnderline: true,
                  style: textfeild,
                  // style: {
                  //     height: '40px', width: '180px', border: '1px solid #8E47E6',
                  //     paddingBottom: '15px'
                  // }
                }}
                autoComplete="off"
                error={formvalues.email.error}
                helperText={
                  formvalues.email.error && formvalues.email.errorMessage
                }
              />{" "}
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {/* <div
                            style={{

                                fontSize: '17px',
                                fontWeight: 'bold', textAlign: "left",
                                padding:'0px 0px 10px 0px'
                            }}
                        >
                            Password
                        </div>
                        <TextField id="filled-basic" variant="filled"
                            name="password"
                            value={formvalues.password.value}
                            onChange={(e:any) => handleChange( e)}
                            InputProps={{
                                disableUnderline: true,
                                style: textfeild,
                                // style: {
                                //     height: '40px', width: '180px', border: '1px solid #8E47E6',
                                //     paddingBottom: '15px'
                                // }
                            }}
                            autoComplete="off"

                            error={formvalues.password.error}
                            helperText={
                              formvalues.password.error &&
                              formvalues.password.errorMessage
                            }
                             /> */}
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  textAlign: "left",
                  padding: "0px 0px 10px 0px",
                }}
              >
                Domain
              </div>
              <TextField
                id="filled-basic"
                variant="filled"
                name="domain"
                value={formvalues.domain.value}
                onChange={(e: any) => handleChange(e)}
                InputProps={{
                  disableUnderline: true,
                  style: textfeild,
                }}
                autoComplete="off"
                error={formvalues.domain.error}
                helperText={
                  formvalues.domain.error && formvalues.domain.errorMessage
                }
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {/* <div
                            style={{

                                fontSize: '17px',
                                fontWeight: 'bold', textAlign: "left",
                                padding:'0px 0px 10px 0px'
                            }}
                        >
                            Confirm Password
                        </div>
                        <TextField id="filled-basic" variant="filled"
                            name="confirm_password"
                            value={formvalues.confirm_password.value}
                            onChange={(e:any) => handleChange( e)}
                            InputProps={{
                                disableUnderline: true,
                                style: textfeild,
                                // style: {
                                //     height: '40px', width: '180px', border: '1px solid #8E47E6',
                                //     paddingBottom: '15px'
                                // }
                            }}
                            autoComplete="off"

                            error={formvalues.confirm_password.error}
                            helperText={
                              formvalues.confirm_password.error &&
                              formvalues.confirm_password.errorMessage
                            }
                             /> */}

              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  textAlign: "left",
                  padding: "0px 0px 10px 0px",
                }}
              >
                Role
              </div>
              <Select
                id="filled-basic"
                variant="filled"
                name="role"
                value={formvalues.role.value}
                onChange={(e: any) => handleChange(e)}
                style={
                  textfeild
                  // // disableUnderline: true,

                  // // style: {
                  //     height: '40px', width: '180px', border: '1px solid #8E47E6',
                  //     paddingBottom: '15px'
                  // // }
                }
                autoComplete="off"
                disableUnderline
                error={formvalues.role.error}
                // helperText={
                //   formvalues.role.error &&
                //   formvalues.role.errorMessage
                // }
              >
                {allRole.map((roleData: any, index: any) => (
                  <MenuItem key={index} value={roleData}>
                    {roleData.role_name}
                  </MenuItem>
                ))}
              </Select>
              {formvalues.role.error && (
                <div style={{ color: "#d32f2f", textAlign: "left" ,paddingLeft:'15px'}}>
                  {formvalues.role.errorMessage}
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  textAlign: "left",
                  padding: "0px 0px 10px 0px",
                }}
              >
                Given User Name
              </div>
              <TextField
                id="filled-basic"
                variant="filled"
                name="given_User_Name"
                value={formvalues.given_User_Name.value}
                onChange={(e: any) => handleChange(e)}
                InputProps={{
                  disableUnderline: true,
                  style: textfeild,
                  // style: {
                  //     height: '40px', width: '180px', border: '1px solid #8E47E6',
                  //     paddingBottom: '15px'
                  // }
                }}
                autoComplete="off"
                error={formvalues.given_User_Name.error}
                helperText={
                  formvalues.given_User_Name.error &&
                  formvalues.given_User_Name.errorMessage
                }
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {/* <div
                            style={{

                                fontSize: '17px',
                                fontWeight: 'bold', textAlign: "left",
                                padding:'0px 0px 10px 0px'
                            }}
                        >
                            Domain
                        </div>
                        <TextField id="filled-basic" variant="filled"
                            name="domain"
                            value={formvalues.domain.value}
                            onChange={(e:any) => handleChange( e)}
                            InputProps={{
                                disableUnderline: true,
                                style: textfeild,
                                 }} 
                            autoComplete="off"

                            error={formvalues.domain.error}
                            helperText={
                              formvalues.domain.error &&
                              formvalues.domain.errorMessage
                            }
                            /> */}
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {/* <div
                            style={{

                                fontSize: '17px',
                                fontWeight: 'bold', textAlign: "left",
                                padding:'0px 0px 10px 0px'
                            }}
                        >
                            Role
                        </div>
                        <Select id="filled-basic" variant="filled"
                            name="role"
                            value={formvalues.role.value}
                            onChange={(e:any) => handleChange( e)}
                            style={textfeild
                                // // disableUnderline: true,

                                // // style: {
                                //     height: '40px', width: '180px', border: '1px solid #8E47E6',
                                //     paddingBottom: '15px'
                                // // }
                            } 
                            autoComplete="off"

                            error={formvalues.role.error}
                            // helperText={
                            //   formvalues.role.error &&
                            //   formvalues.role.errorMessage
                            // }
                           
                            >
                                 {allRole.map((roleName:any, index:any) => (
                                <MenuItem key={index} value={roleName}>
                                    {roleName}
                                </MenuItem>
                            ))}
                                </Select>
                                {formvalues.role.error && (
        <div style={{ color: 'red' }}>{formvalues.role.errorMessage}</div>
      )} */}
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <CustomPrimaryButton
              style={{
                marginRight: "20px",
              }}
              onClick={submitForm}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#8E47E6")
              }
              // onMouseOut={(e) => e.currentTarget.style.backgroundColor = "white"}
            >
              Add
            </CustomPrimaryButton>
            <CustomPrimaryButton
              style={{
                background: "red",
                border: "none",
                gap: "10px",
              }}
              // onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#8E47E6'}
              // onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'white'}
              onClick={clearForm}
            >
              Cancel
            </CustomPrimaryButton>
          </Box>
        </Box>
      </div>
    </Box>
  );
}
