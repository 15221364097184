import { createStore, combineReducers } from 'redux'

// Define types for the state

interface AuthState {
  isLoggedIn: boolean
  user: string | null
}

// Initial state
const initialAuthState: AuthState = {
  isLoggedIn: false,
  user: null,
}

interface LoadingState {
  isLoading: boolean
}

const initialLoadingState: LoadingState = {
  isLoading: false,
}

// Reducer to manage login state
function authReducer(state = initialAuthState, action: any): AuthState {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
      }
    case 'LOGOUT':
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      }
    default:
      return state
  }
}

// Reducer to manage isLoading state
function loadingReducer(
  state = initialLoadingState,
  action: any,
): LoadingState {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: true,
      }
    case 'SET_LOADING_OFF':
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

// Snackbar Reducer
const snackbarReducer = (
  state = { open: false, message: '', color: '' },
  action: any,
) => {
  switch (action.type) {
    case 'OPEN_SNACKBAR':
      return { open: true, message: action.payload, color: action.color }
    case 'CLOSE_SNACKBAR':
      return { open: false, message: '', color: '' }
    default:
      return state
  }
}

// Combine reducers if you have more than one reducer
const rootReducer = combineReducers({
  auth: authReducer,
  loading: loadingReducer,
  snackbar: snackbarReducer,
})

export type RootState = ReturnType<typeof rootReducer>

// Create the Redux store
const store = createStore(rootReducer)

export default store
