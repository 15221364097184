import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import axios from "axios";
import { useTokenForm } from "../custom_forms/TokenForm";
import {
  containsOnlyCharacters,
  emailRegex,
  numberRegex,
  passwordRegex,
} from "../CustomRegex/CustomRegex";
import { loaderOff, loaderOn, logout, openSnackbar } from "../redux/actions";
import {
  addToken,
  deleteToken,
  getActiveToken,
  getToken,
  updateToken,
  updateTokendelete,
} from "../utilities/backofficeservice";
import { useDispatch } from "react-redux";
import { getUser } from "../utilities/adminservice";
import CommonDialog from "../commondialog/confirm";
import { CustomPrimaryButton } from "../customtextfield/CustomButton";
import { useSelector } from "react-redux";
import store from "../redux/store";
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid white",
  borderRadius: "18px",
  p: 2,
};

const overlayStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0)", // Semi-transparent overlay
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const popupStyle: React.CSSProperties = {
  background: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
  position: "relative", // Ensure relative positioning for close icon
};

const closeIconStyle: React.CSSProperties = {
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
};

const popupContentStyle: React.CSSProperties = {
  textAlign: "center",
  marginTop: "30px", // Adjust the margin to make space for the close icon
};

interface Column2 {
  id:
    | "Category"
    | "Details"
    | "Discovery"
    | "Collector"
    | "Updatepoi"
    | "token_id";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}
const columns2: readonly Column2[] = [
  { id: "Category", label: "Category", color: "#8E47E6" },
  { id: "Details", label: "Details", color: "#8E47E6" },
  {
    id: "Discovery",
    label: "Discovery",
    // format: (value: number) => value.toLocaleString("en-US"),
    color: "#8E47E6",
  },
  {
    id: "Collector",
    label: "2nd Collector (Verif)",
    // format: (value: number) => value.toLocaleString("en-US"),
    color: "#8E47E6",
  },
  {
    id: "Updatepoi",
    label: "Update (POI> 6 Months)",
    color: "#8E47E6",
  },
  {
    id: "token_id",
    label: "Action",
    format: (value: number) => value.toFixed(2),
    color: "#8E47E6",
  },
];

interface Data2 {
  category: string;
  details: string;
  discovery: number;
  collector: number;
  updatepoi: number;
  token_id: number;
  referenceId: string;
  deleteStatus: string;
}

interface Column3 {
  id: "token_id" | "Category" | "Details" | "Discovery" | "Collector" | "Updatepoi";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  color?: string;
}
const columns3: readonly Column3[] = [
  { id: "Category", label: "Category", color: "#8E47E6" },
  { id: "Details", label: "Details", color: "#8E47E6" },
  {
    id: "Discovery",
    label: "Discovery",
    // format: (value: number) => value.toLocaleString("en-US"),
    color: "#8E47E6",
  },
  {
    id: "Collector",
    label: "2nd Collector (Verif)",
    // format: (value: number) => value.toLocaleString("en-US"),
    color: "#8E47E6",
  },
  {
    id: "Updatepoi",
    label: "Update (POI> 6 Months)",
    color: "#8E47E6",
  },
];

interface Data3 {
  category: string;
  details: string;
  discovery: number;
  collector: number;
  updatepoi: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Token() {
  const [page, setPage] = React.useState(0);
  const [rows1, setRows1] = useState<Data2[]>([]);
  const [rows2, setRows2] = useState<Data3[]>([]);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tokenId, setTokenId] = React.useState(null);
  const [searchQuery1, setSearchQuery1] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [openDialog, setopenDialog] = React.useState(false);
  const [selectedTokenId, setSelectedTokenId] = useState<any | null>(null);

  const navigate = useNavigate();


  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isTokenExpired = useSelector((state: any) => state.auth.isTokenExpired);
  console.log("syed" + isLoggedIn);
  useEffect(() => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken || isTokenExpired) {
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    } else if (!isLoggedIn) { 
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    }
  }, [isLoggedIn, isTokenExpired]);

  
  const handleCloseDialog = () => {
    setopenDialog(false);
    setShowContent(false);
  };

  const { formValues, setFormValues } = useTokenForm();
  const dispatch = useDispatch();

  const validateInput = (name: string, value: string) => {
    switch (name) {
      case "password":
        return passwordRegex.test(value);
      case "email":
        return emailRegex.test(value);
      case "category":
      case "details":
        return containsOnlyCharacters.test(value);
      case "discovery":
      case "updatepoi":
      case "collector":
        return numberRegex.test(value);
      default:
        return true;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Validate the input
    const isValid = validateInput(name, value);

    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value,
        error: !isValid,
      },
    });
  };

  // let validateForm = (type: any) => {
  //   let invalidSubmit = false;

  //   const validationFields = [
  //     "category",
  //     "details",
  //     "collector",
  //     "discovery",
  //     "updatepoi",
  //   ];

  //   validationFields.forEach((field) => {
  //     const fieldValue = formValues[field].value;

  //     if (field === "category" || field === "details") {
  //       const isValidField = containsOnlyCharacters.test(fieldValue);
  //       setFormValues({
  //         ...formValues,
  //         [field]: {
  //           ...formValues[field],
  //           error: !isValidField,
  //         },
  //       });
  //       invalidSubmit = invalidSubmit || !isValidField;
  //     }
  //     else {
  //       const isValidNumber = numberRegex.test(fieldValue);
  //       setFormValues({
  //         ...formValues,
  //         [field]: {
  //           ...formValues[field],
  //           error: !isValidNumber,
  //         },
  //       });
  //       invalidSubmit = invalidSubmit || !isValidNumber;
  //     }
  //   });

  //   if (type === "Token-add") {
  //     validationFields.forEach((field) => {
  //       if (formValues[field].value === "") {
  //         setFormValues({
  //           ...formValues,
  //           [field]: {
  //             ...formValues[field],
  //             error: true,
  //           },
  //         });
  //         invalidSubmit = true;
  //       }
  //     });
  //   }

  //   dispatch(loaderOff());
  //   return invalidSubmit;
  // };
  let validateForm = (type: any) => {
    let invalidSubmit = false;

    const validationFields = [
      "category",
      "details",
      "collector",
      "discovery",
      "updatepoi",
    ];

    const updatedFormValues = { ...formValues }; // Create a copy to update asynchronously

    validationFields.forEach((field) => {
      const fieldValue = formValues[field].value;

      // Check for null value
      if (
        fieldValue === null ||
        fieldValue === undefined ||
        fieldValue === ""
      ) {
        updatedFormValues[field] = {
          ...updatedFormValues[field],
          error: true,
        };
        invalidSubmit = true;
      } else {
        // Check for valid field based on type
        if (field === "category" || field === "details") {
          const isValidField = containsOnlyCharacters.test(fieldValue);
          updatedFormValues[field] = {
            ...updatedFormValues[field],
            error: !isValidField,
          };
          invalidSubmit = invalidSubmit || !isValidField;
        } else {
          const isValidNumber = numberRegex.test(fieldValue);
          updatedFormValues[field] = {
            ...updatedFormValues[field],
            error: !isValidNumber,
          };
          invalidSubmit = invalidSubmit || !isValidNumber;
        }
      }
    });

    setFormValues(updatedFormValues); // Update the state asynchronously

    if (type === "Token-add") {
      // Check for null values in additional fields if needed
      // Add conditions similar to the ones above
      // Example:
      // if (formValues.additionalField.value === null || formValues.additionalField.value === undefined || formValues.additionalField.value === "") {
      //   updatedFormValues.additionalField = {
      //     ...updatedFormValues.additionalField,
      //     error: true,
      //   };
      //   invalidSubmit = true;
      // }
    }

    dispatch(loaderOff());
    return invalidSubmit;
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFormValues({       
      category: {
         value: '',
         error: false,
         errorMessage: 'Please enter valid category',
     },
     details: { 
         value: '',
         error: false,
         errorMessage: 'Please enter details',
     },
     discovery: {
          value: '',
         error: false,
         errorMessage: 'Please enter  discovery',
     },
     collector: {
          value: '',
         error: false,
         errorMessage: 'Please enter  collector',
     },
     updatepoi: {
          value: '',
         error: false,
         errorMessage: 'Please enter updatepoi',
     },
 })
  }

  const filteredRows1 = rows1.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery1.toLowerCase())
    )
  );

  const filteredRows2 = rows2.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery2.toLowerCase())
    )
  );

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [openToken, setOpenToken] = React.useState(false);
  const handleOpenToken = (tokenId: any) => {
    setTokenId(tokenId);
    setOpenToken(true);
  };

  const handleClose4 = () => {
    setTokenId(null);
    setOpenToken(false);
    Object.keys(formValues).forEach((key: any) => {
      formValues[key].value = "";
    });

    setFormValues({       
      category: {  
         value: '',
         error: false,
         errorMessage: 'Please enter valid category',
     },
     details: {   
         value: '',
         error: false,
         errorMessage: 'Please enter details',
     },
     discovery: {
          value: '',
         error: false,
         errorMessage: 'Please enter  discovery',
     },
     collector: {
          value: '',
         error: false,
         errorMessage: 'Please enter  collector',
     },
     updatepoi: {
          value: '',
         error: false,
         errorMessage: 'Please enter updatepoi',
     },
 })
  };

  const handleUpdateToken = async (tokenId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    // const invalidSubmit = validateForm('Token-add')
    // if(!invalidSubmit){
    const formData = {
      category: formValues.category.value,
      details: formValues.details.value,
      discovery: formValues.discovery.value,
      collector: formValues.collector.value,
      updatepoi: formValues.updatepoi.value,
    };
    dispatch(loaderOn());
    try {
      // const res = await axios.put(`https://dev-backoffice.tribe.sn/token/${tokenId}`, formData)
      const res = await updateToken(tokenId, formData);
      if (res != null) {
        console.log("data edited sucessfully");
        fetchLocationType();
        handleClose4();
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
    // }
  };

  const handleDeleteToken = async (tokenId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    console.log("came to delete");
    const formData = {
      tokenId: tokenId,
      deleteStatus: "yes",
    };
    dispatch(loaderOn());
    try {
      // const res = await axios.put(`https://dev-backoffice.tribe.sn/token/delete/${tokenId}`, formData)
      const res = await updateTokendelete(tokenId, formData);
      if (res != null) {
        console.log("data edited sucessfully");
        fetchLocationType();
        togglePopup();
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  const handleEditToken = async (tokenId: any) => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    // const res = await axios.get(`https://dev-backoffice.tribe.sn/token/${tokenId}`)
    dispatch(loaderOn())
    try {
      const res = await getToken(tokenId);
      console.log(res);
      if (res != null) {
        formValues.category.value = res.category;
        formValues.collector.value = res.collector;
        formValues.details.value = res.details;
        formValues.discovery.value = res.discovery;
        formValues.updatepoi.value = res.updatepoi;
        dispatch(loaderOff())
      }
      handleOpenToken(tokenId);
    } catch {
      dispatch(loaderOff());
    }
  };

  const handleDeleteTokenn = (tokenId: any) => {
    setTokenId(tokenId);
    setShowPopup(true);
    // handleDeleteTokenn(tokenId);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleAddToken = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    const formData = {
      category: formValues.category.value,
      details: formValues.details.value,
      discovery: formValues.discovery.value,
      collector: formValues.collector.value,
      updatepoi: formValues.updatepoi.value,
    };
    dispatch(loaderOn());
    try {
      //  const res = await axios.post("https://dev-backoffice.tribe.sn/token", formData);
      const res = await addToken(formData);
      if (res != null) {
        handleClose();
        fetchLocationType();
        Object.keys(formValues).forEach((key: any) => {
          formValues[key].value = "";
        });
        dispatch(loaderOff());
      }
    } catch (error) {
      console.log(error);
      dispatch(loaderOff());
    }
  };

  const fetchLocationType = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn())
    try{
    const backendData = await getActiveToken();
    const frontendData = backendData.map((backendRow: any) => ({
      category: backendRow.category,
      details: backendRow.details,
      discovery: backendRow.discovery,
      collector: backendRow.collector,
      updatepoi: backendRow.updatepoi,
      token_id: backendRow.token_id,
    }));
    setRows1(frontendData);
    dispatch(loaderOff())
  }catch{
    dispatch(loaderOff())
  }
  };

  const fetchAll = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn())
    try{
      const token = sessionStorage.getItem("jwtToken");
    const res = await axios.get("https://dev-backoffice.tribe.sn/token", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res1 = res.data;
    const frontendData: any = res1.map((backendRow: any) => ({
      category: backendRow.category,
      details: backendRow.details,
      discovery: backendRow.discovery,
      collector: backendRow.collector,
      updatepoi: backendRow.updatepoi,
      token_id: backendRow.token_id,
    }));
    setRows2(frontendData);
    dispatch(loaderOff())
  }catch{
    dispatch(loaderOff())
  }
  };

  useEffect(() => {
    fetchLocationType();
    fetchAll();
  }, []);
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const handleChangee = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const CustomTabs = styled(Tabs)(({ theme: any }) => ({
    "& .MuiTabs-indicator": {
      backgroundColor: "#8E47E6", // Set your custom color here
    },
  }));
  // Restriction
  const [userId, setUserId] = useState(0);
  let temp: number;
  const [token_read, setTokenRead] = useState(false);
  const [token_write, setTokenWrite] = useState(false);

  const getRoleData = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn())
    try{
    const res: any = await getUser();
    if (res && res.role_type) {
      // if (
      //   res.role_type.token_read !== undefined &&
      //   res.role_type.token_write !== undefined
      // ) {
      //   setTokenRead(res.role_type.token_read);
      //   setTokenWrite(res.role_type.token_write);
      // }
      if (res.role_type.token_write === true) {
        setTokenRead(true);
        setTokenWrite(true);
      } else if (res.role_type.token_read === true) {
        setTokenRead(true);
        // Optionally, setBonusWrite(false); // depending on your logic
      } else {
        // Handle the case where bonus_read and bonus_write are both false or undefined
        console.error("token data is undefined");
      }
      dispatch(loaderOff())
    }
  }catch{
    dispatch(loaderOff())
  }
  };

  const filteredColumns = token_write ? columns2 : columns2.filter(column => column.id !== 'token_id');

  const getLoginUserData = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn())
    try{
    const res: any = await getUser();
    if (res != null) {
      temp = res.id;
      setUserId(temp);
      dispatch(loaderOff())
    }
    console.log(temp);
  }catch{
    dispatch(loaderOff())
  }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getLoginUserData();
      getRoleData();
    };

    fetchData();
  }, []);
  return (
    <Box marginTop={"30px"}>
      <div style={{}}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "white" }}>
            <CustomTabs
              value={value}
              onChange={handleChangee}
              aria-label="basic tabs example"
            >
              <Tab
                label="Token"
                {...a11yProps(0)}
                style={{
                  color: "#8E47E6",
                  fontWeight: "600",
                  textTransform: "none",
                  fontSize: "16px",
                }}
              />
              <Tab
                label="Token History"
                {...a11yProps(1)}
                style={{
                  color: "#8E47E6",
                  fontWeight: "600",
                  textTransform: "none",
                  fontSize: "16px",
                }}
              />
            </CustomTabs>
          </Box>
        </Box>
      </div>
      <CustomTabPanel value={value} index={0}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: "30px",
              }}
            >
              <h1
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  paddingRight: "56px",
                }}
              >
                Token Configuration
              </h1>
              <span style={{}}>
                <TextField
                  value={searchQuery1}
                  onChange={(e) => setSearchQuery1(e.target.value)}
                  placeholder="Search..."
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#140026" }} />
                      </InputAdornment>
                    ),
                    style: {
                      width: "300px",
                      boxShadow: "0px 4px 4px 0px rgba(142, 71, 230, 0.25)",
                      borderRadius: "6px",
                    },
                  }}
                />
              </span>
            </div>

            {/* <div style={{ paddingRight: "10px" }}>
              <CustomPrimaryButton
                style={{ background: "#8E47E6", color: "#FFFFFF" ,
              }}
                onClick={handleOpen}
                // disabled={!token_write && !token_read}
                disabled={token_write === false}
                // startIcon={<AddIcon />}
              >
                {" "}
                <AddIcon /> Add Token
              </CustomPrimaryButton>
            </div> */}
            {
                token_write === false ?'':(
              <div style={{ paddingRight: "10px" }}>
              <CustomPrimaryButton
                style={{ background: "#8E47E6", color: "#FFFFFF" ,
              }}
                onClick={handleOpen}
              >
                {" "}
                <AddIcon /> Add Token
              </CustomPrimaryButton>
            </div>
                )  
            }
          </div>
        </div>
        <div>
          <Box
            sx={{
              color: "black",
              marginTop: "20px",
            }}
          >
            <Paper sx={{ overflow: "hidden" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                }}
              ></div>
              <TableContainer
                sx={{
                  maxHeight: 440,
                  borderRadius: "18px",
                  boxShadow: "0px 4px 12px 0px #8E47E640",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}>
                      {columns2.map((column2) => (
                        <TableCell
                          key={column2.id}
                          align={column2.align}
                          style={{
                            minWidth: column2.minWidth,
                            color: column2.color,
                          }}
                        >
                          {/* {column2.label} */}
                          {token_write || column2.id !== 'token_id' ? column2.label : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows1
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow
                          sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.category}
                        >
                          {filteredColumns.map((column2) => {
                            const value =
                              row[column2.id.toLowerCase() as keyof Data2];
                            return (
                              <TableCell key={column2.id} align={column2.align}>
                                {column2.id === "token_id" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {token_write === true ? (
                                      <p
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleEditToken(row.token_id)
                                        }
                                      >
                                        <CreateIcon
                                          style={{ color: "#5151ED" }}
                                        />
                                      </p>
                                    ) : (
                                      <p
                                        style={{
                                          cursor: "not-allowed",
                                          filter: "blur(1px)",
                                        }}
                                      >
                                        <CreateIcon
                                          style={{ color: "#5151ED" }}
                                        />
                                      </p>
                                    )}
                                    {token_write === true ? (
                                      <p
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setopenDialog(true);
                                          setSelectedTokenId(row.token_id);
                                          // handleDeleteToken(row.token_id)
                                        }}
                                      >
                                        <DeleteIcon
                                          style={{ color: "#DF2525" }}
                                        />
                                      </p>
                                    ) : (
                                      <p
                                        style={{
                                          cursor: "not-allowed",
                                          filter: "blur(1px)",
                                        }}
                                      >
                                        <DeleteIcon
                                          style={{ color: "#DF2525" }}
                                        />
                                      </p>
                                    )}
                                  </div>
                                ) : column2.format &&
                                  typeof value === "number" ? (
                                  column2.format(value)
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <CommonDialog
                open={openDialog}
                handleClose={handleCloseDialog}
                handleConfirm={() => {
                  handleDeleteToken(selectedTokenId);
                  handleCloseDialog();
                }}
                contentText="Are you sure to delete token?"
              />
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredRows1.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>

          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={
                  {
                    // display: "flex",
                    // flexDirection:'row'
                  }
                }
                onClick={handleClose}
              >
                <div style={{ float: "left" }}>
                  {" "}
                  <h1>Add Token</h1>
                </div>

                <div style={{ float: "right" }}>
                  <CloseIcon />
                </div>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
                onClick={handleClose}
              >
                <CloseIcon />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h1>Add Token</h1>
              </div> */}
              <Grid container spacing={2}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Category
                  </div>
                  <TextField
                    id="filled-basic"
                    placeholder="Enter your token category"
                    variant="filled"
                    name="category"
                    value={formValues.category.value}
                    error={formValues.category.error}
                    onChange={(e: any) => handleChange(e)}
                    helperText={
                      formValues.category.error &&
                      formValues.category.errorMessage
                    }
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                        height: "7vh",
                        borderRadius: "12px",
                        // paddingBottom: "10%",
                        paddingBottom: "20px",
                        background: "#FFFFFF",
                        marginTop: "5px",
                        display: "flex", // Center vertically
                        alignItems: "center",
                      },
                      inputProps: {},
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Details
                  </div>
                  <TextField
                    id="filled-basic"
                    placeholder="Enter your token details"
                    variant="filled"
                    name="details"
                    value={formValues.details.value}
                    error={formValues.details.error}
                    onChange={(e: any) => handleChange(e)}
                    helperText={
                      formValues.details.error &&
                      formValues.details.errorMessage
                    }
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                        height: "7vh",
                        borderRadius: "12px",
                        // paddingBottom: "10%",
                        paddingBottom: "20px",
                        background: "#FFFFFF",
                        marginTop: "5px",
                        display: "flex", // Center vertically
                        alignItems: "center",
                      },
                      inputProps: {
                        style: {
                          // padding:'5px'
                        },
                      },
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "5px" }}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Discovery
                  </div>
                  <TextField
                    id="filled-basic"
                    placeholder="Enter your token number"
                    variant="filled"
                    name="discovery"
                    value={formValues.discovery.value}
                    error={formValues.discovery.error}
                    onChange={(e: any) => handleChange(e)}
                    helperText={
                      formValues.discovery.error &&
                      formValues.discovery.errorMessage
                    }
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                        height: "7vh",
                        borderRadius: "12px",
                        // paddingBottom: "10%",
                        paddingBottom: "20px",
                        background: "#FFFFFF",
                        marginTop: "5px",
                        display: "flex", // Center vertically
                        alignItems: "center",
                      },
                      inputProps: {
                        style: {
                          //  padding:'5px'
                        },
                      },
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Collector
                  </div>
                  <TextField
                    id="filled-basic"
                    placeholder="Enter your token number"
                    variant="filled"
                    name="collector"
                    value={formValues.collector.value}
                    error={formValues.collector.error}
                    onChange={(e: any) => handleChange(e)}
                    helperText={
                      formValues.collector.error &&
                      formValues.collector.errorMessage
                    }
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                        height: "7vh",
                        borderRadius: "12px",
                        // paddingBottom: "10%",
                        paddingBottom: "20px",
                        background: "#FFFFFF",
                        marginTop: "5px",
                        display: "flex", // Center vertically
                        alignItems: "center",
                      },
                      inputProps: {},
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "5px" }}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    UpdatePOI
                  </div>
                  <TextField
                    id="filled-basic"
                    placeholder="Enter your token number"
                    variant="filled"
                    name="updatepoi"
                    value={formValues.updatepoi.value}
                    error={formValues.updatepoi.error}
                    onChange={(e: any) => handleChange(e)}
                    helperText={
                      formValues.updatepoi.error &&
                      formValues.updatepoi.errorMessage
                    }
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                        height: "7vh",
                        borderRadius: "12px",
                        // paddingBottom: "10%",
                        paddingBottom: "20px",
                        background: "#FFFFFF",
                        marginTop: "5px",
                        display: "flex", // Center vertically
                        alignItems: "center",
                      },
                      inputProps: {},
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid item>
                  <CommonDialog
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    handleConfirm={() => {
                      handleAddToken();
                      handleCloseDialog();
                    }}
                    contentText="Are you sure to create token?"
                  />
                  <CustomPrimaryButton
                    style={{ padding: "15px 20px" }}
                    // onClick={handleAddToken}
                    onClick={() => {
                      let invalidSubmit = validateForm("Token-add");
                      if (!invalidSubmit) {
                        setopenDialog(true);
                      }
                    }}
                  >
                    Add
                  </CustomPrimaryButton>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <Modal
            open={openToken}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={
                  {
                    // display: "flex",
                    // flexDirection:'row'
                  }
                }
                onClick={handleClose4}
              >
                <div style={{ float: "left" }}>
                  {" "}
                  <h1>Update Token</h1>
                </div>

                <div style={{ float: "right" }}>
                  <CloseIcon />
                </div>
              </div>

              <Grid container spacing={2}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Category
                  </div>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    name="category"
                    value={formValues.category.value}
                    onChange={(e: any) => handleChange(e)}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                      style: {
                        border: "1px solid #8E47E6",
                        width: "330px",
                        height: "7vh",
                        paddingBottom: "20px",
                        background: "#FFFFFF",
                        borderRadius: "12px",
                        marginTop: "5px",
                      },
                    }}
                  />
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Details
                  </div>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    name="details"
                    value={formValues.details.value}
                    error={formValues.details.error}
                    helperText={
                      formValues.details.error &&
                      formValues.details.errorMessage
                    }
                    onChange={(e: any) => handleChange(e)}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                      style: {
                        border: "1px solid #8E47E6",
                        width: "330px",
                        height: "7vh",
                        paddingBottom: "20px",
                        background: "#FFFFFF",
                        borderRadius: "12px",
                        marginTop: "5px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ paddingTop: "5px" }}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Discovery
                  </div>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    name="discovery"
                    value={formValues.discovery.value}
                    error={formValues.discovery.error}
                    helperText={
                      formValues.discovery.error &&
                      formValues.discovery.errorMessage
                    }
                    onChange={(e: any) => handleChange(e)}
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                        width: "330px",
                        height: "7vh",
                        paddingBottom: "20px",
                        background: "#FFFFFF",
                        borderRadius: "12px",
                        marginTop: "5px",
                      },
                    }}
                  />
                </Grid>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Collector
                  </div>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    name="collector"
                    value={formValues.collector.value}
                    error={formValues.collector.error}
                    onChange={(e: any) => handleChange(e)}
                    helperText={
                      formValues.collector.error &&
                      formValues.collector.errorMessage
                    }
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                        width: "330px",
                        height: "7vh",
                        paddingBottom: "20px",
                        background: "#FFFFFF",
                        borderRadius: "12px",
                        marginTop: "5px",
                      },
                      inputProps: {
                        style: {},
                      },
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ paddingTop: "5px" }}>
                <Grid item lg={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    UpdatePOI
                  </div>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    name="updatepoi"
                    value={formValues.updatepoi.value}
                    error={formValues.updatepoi.error}
                    helperText={
                      formValues.updatepoi.error &&
                      formValues.updatepoi.errorMessage
                    }
                    onChange={(e: any) => handleChange(e)}
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                        width: "330px",
                        height: "7vh",
                        paddingBottom: "20px",
                        background: "#FFFFFF",
                        borderRadius: "12px",
                        marginTop: "5px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid
                  item
                  lg={6}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    paddingTop: "10px",
                  }}
                >
                  <CommonDialog
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    handleConfirm={() => {
                      handleUpdateToken(tokenId);
                      handleCloseDialog();
                    }}
                    contentText="Are you sure to update token?"
                  />
                  <CustomPrimaryButton
                    style={{ padding: "15px 25px" }}
                    onClick={() => {
                      const invalidSubmit = validateForm('Token-add')
                      if(!invalidSubmit){
                      setopenDialog(true);
                      }
                    }}
                    // onClick={() => handleUpdateToken(tokenId)}
                  >
                    Update
                  </CustomPrimaryButton>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: "30px",
              }}
            >
              <h1
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  paddingRight: "56px",
                }}
              >
                Token Configuration
              </h1>
              <span style={{}}>
                <TextField
                  value={searchQuery2}
                  onChange={(e) => setSearchQuery2(e.target.value)}
                  placeholder="Search..."
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#140026" }} />
                      </InputAdornment>
                    ),
                    style: {
                      width: "300px",
                      boxShadow: "0px 4px 4px 0px rgba(142, 71, 230, 0.25)",
                      borderRadius: "6px",
                    },
                  }}
                />
              </span>
            </div>

            {/* <div style={{ paddingRight: "10px" }}>
              <Button
                style={{
                  color: "black",
                  backgroundColor: "#8E47E6",
                }}
                onClick={handleOpen}
                startIcon={<AddIcon />}
              >
                Add Token
              </Button>
            </div> */}
          </div>
        </div>
        <div>
          <Box
            sx={{
              color: "black",
              marginTop: "20px",
            }}
          >
            <Paper sx={{ overflow: "hidden" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                }}
              ></div>
              <TableContainer
                sx={{
                  maxHeight: 440,
                  borderRadius: "18px",
                  boxShadow: "0px 4px 12px 0px #8E47E640",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}>
                      {columns3.map((column3) => (
                        <TableCell
                          key={column3.id}
                          align={column3.align}
                          style={{
                            minWidth: column3.minWidth,
                            color: column3.color,
                          }}
                        >
                          {column3.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows2
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow
                          sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.category}
                        >
                          {columns3.map((column3) => {
                            const value =
                              row[column3.id.toLowerCase() as keyof Data3];
                            return (
                              <TableCell key={column3.id} align={column3.align}>
                                {column3.id === "token_id" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  ></div>
                                ) : column3.format &&
                                  typeof value === "number" ? (
                                  column3.format(value)
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <CommonDialog
                open={openDialog}
                handleClose={handleCloseDialog}
                handleConfirm={() => {
                  handleDeleteToken(selectedTokenId);
                  handleCloseDialog();
                }}
                contentText="Are you sure to delete token?"
              />
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredRows2.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>

          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
                onClick={handleClose}
              >
                <CloseIcon />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h1>Add Token</h1>
              </div>

              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Category
                  </div>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    name="category"
                    value={formValues.category.value}
                    error={formValues.category.error}
                    onChange={(e: any) => handleChange(e)}
                    helperText={
                      formValues.category.error &&
                      formValues.category.errorMessage
                    }
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Details
                  </div>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    name="details"
                    value={formValues.details.value}
                    error={formValues.details.error}
                    onChange={(e: any) => handleChange(e)}
                    helperText={
                      formValues.details.error &&
                      formValues.details.errorMessage
                    }
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Discovery
                  </div>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    name="discovery"
                    value={formValues.discovery.value}
                    error={formValues.discovery.error}
                    onChange={(e: any) => handleChange(e)}
                    helperText={
                      formValues.discovery.error &&
                      formValues.discovery.errorMessage
                    }
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Collector
                  </div>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    name="collector"
                    value={formValues.collector.value}
                    error={formValues.collector.error}
                    onChange={(e: any) => handleChange(e)}
                    helperText={
                      formValues.collector.error &&
                      formValues.collector.errorMessage
                    }
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    UpdatePOI
                  </div>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    name="updatepoi"
                    value={formValues.updatepoi.value}
                    error={formValues.updatepoi.error}
                    onChange={(e: any) => handleChange(e)}
                    helperText={
                      formValues.updatepoi.error &&
                      formValues.updatepoi.errorMessage
                    }
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{
                      width: "250px",
                      backgroundColor: "#8E47E6",
                      color: "black",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                    onClick={handleAddToken}
                  >
                    Add Token
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <Modal
            open={openToken}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
                onClick={handleClose4}
              >
                <CloseIcon />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h1>Update Token</h1>
              </div>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Category
                  </div>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    name="category"
                    value={formValues.category.value}
                    onChange={(e: any) => handleChange(e)}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,

                      style: {
                        border: "1px solid #8E47E6",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Details
                  </div>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    name="details"
                    value={formValues.details.value}
                    onChange={(e: any) => handleChange(e)}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,

                      style: {
                        border: "1px solid #8E47E6",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Discovery
                  </div>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    name="discovery"
                    value={formValues.discovery.value}
                    onChange={(e: any) => handleChange(e)}
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Collector
                  </div>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    name="collector"
                    value={formValues.collector.value}
                    onChange={(e: any) => handleChange(e)}
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    UpdatePOI
                  </div>
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    name="updatepoi"
                    value={formValues.updatepoi.value}
                    error={formValues.updatepoi.error}
                    helperText={
                      formValues.updatepoi.error &&
                      formValues.updatepoi.errorMessage
                    }
                    onChange={(e: any) => handleChange(e)}
                    InputProps={{
                      disableUnderline: true,

                      style: {
                        border: "1px solid #8E47E6",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{
                      width: "250px",
                      backgroundColor: "#8E47E6",
                      color: "black",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                    onClick={() => handleUpdateToken(tokenId)}
                  >
                    Update Token
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </div>
      </CustomTabPanel>
    </Box>
  );
}
