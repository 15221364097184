import {
  Box,
  Breadcrumbs,
  Button,
  Collapse,
  Divider,
  Drawer,
  Fade,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Link, { LinkProps } from "@mui/material/Link";
import LogoutIcon from "@mui/icons-material/Logout";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import {
  Link as RouterLink,
  Route,
  Routes,
  MemoryRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { loaderOff, loaderOn, logout } from "../redux/actions";
import { navigate } from "@reach/router";

import { useDispatch, useSelector } from "react-redux";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { getRoleById, getUser } from "../utilities/adminservice";
import { CustomPrimaryButton } from "../customtextfield/CustomButton";
import CommonDialog from "../commondialog/confirm";

interface ListItemLinkProps extends ListItemProps {
  to: string;
  open?: boolean;
}

const breadcrumbNameMap: { [key: string]: { imgUrl: string; title: string } } =
  {
    "/location_and_token": {
      imgUrl: "/assets/static/svg/location.svg",
      title: "Location & Token",
    },
    "/perks": { imgUrl: "/assets/static/svg/perksIcon.svg", title: "Perks" },
    "/end_user": {
      imgUrl: "/assets/static/svg/enduser.svg",
      title: "End - User",
    },
    "/admin": { imgUrl: "/assets/static/svg/admin.svg", title: "Admin" },
    "/dashboard": {
      imgUrl: "/assets/static/svg/dashboard.svg",
      title: "Dashboard",
    },
    "/location": {
      imgUrl: "/assets/static/images/right-arrow.png",
      title: "Location",
    },
    "/token": {
      imgUrl: "/assets/static/images/right-arrow.png",
      title: "Token",
    },
    "/bonus": {
      imgUrl: "/assets/static/images/right-arrow.png",
      title: "Bonus",
    },
    "/achievements": {
      imgUrl: "/assets/static/images/right-arrow.png",
      title: "Achievements",
    },
    "/gifts": {
      imgUrl: "/assets/static/images/right-arrow.png",
      title: "Gifts",
    },
    "/features": {
      imgUrl: "/assets/static/images/right-arrow.png",
      title: "Features",
    },
    "/user_location": {
      imgUrl: "/assets/static/images/right-arrow.png",
      title: "Location Uploaded",
    },
    "/user_token": {
      imgUrl: "/assets/static/images/right-arrow.png",
      title: "Token Received",
    },
    "/user_access": {
      imgUrl: "/assets/static/images/right-arrow.png",
      title: "Access Restricition",
    },
    "/profile": {
      imgUrl: "/assets/static/images/right-arrow.png",
      title: "My profile",
    },
    "/create_user": {
      imgUrl: "/assets/static/images/right-arrow.png",
      title: "Create User",
    },
    "/create_role": {
      imgUrl: "/assets/static/images/right-arrow.png",
      title: "Create Role",
    },
  };

function ListItemLink(props: ListItemLinkProps) {
  let { to, open, ...other } = props;
  const { imgUrl, title } = breadcrumbNameMap[to];

  let icon = null;
  if (open != null) {
    icon = open ? <ExpandLess /> : <ExpandMore />;
  }

  if (to == "/location_and_token") {
    to = "/location";
  } else if (to == "/perks") {
    to = "/bonus";
  } else if (to == "/end_user") {
    to = "/user_location";
  } else if (to == "/admin") {
    to = "/profile";
  }

  return (
    <li>
      <ListItem button component={RouterLink as any} to={to} {...other}>
        {/* <ListItemIcon> */}
        <img
          src={imgUrl}
          alt="breadcrumb-icon"
          style={{ width: 20, height: 20, marginRight: "10px" }}
        />
        {/* </ListItemIcon> */}
        <ListItemText primary={title} />
        {icon}
      </ListItem>
    </li>
  );
}

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

function Page() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <LinkRouter underline="hover" color="inherit" to="/">
        Home
      </LinkRouter>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        return last ? (
          <Typography color="text.title" key={to}>
            {breadcrumbNameMap[to].title}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="inherit" to={to} key={to}>
            {breadcrumbNameMap[to].title}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
}

export default function NavBar() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [openPerks, setOpenPerks] = React.useState(false);
  const [openEndUser, setOpenEndUser] = React.useState(false);
  const [openAdmin, setOpenAdmin] = React.useState(false);

  const navigate = useNavigate();
  const [showContent, setShowContent] = useState(false);
  const [openDialog, setopenDialog] = React.useState(false);
  const handleCloseDialog = () => {
    console.log(openDialog);
    setopenDialog(false);
    console.log(openDialog);
    console.log("crossed set state");
    setShowContent(false);
  };
  const [location_read, setLocationRead] = useState(0);
  const [location_write, setLocationWrite] = useState(0);

  const [token_read, setTokenRead] = useState(0);
  const [token_write, setTokenWrite] = useState(0);

  const [bonus_read, setBonusRead] = useState(0);
  const [bonus_write, setBonusWrite] = useState(0);

  const [achievements_read, setAchievementsRead] = useState(0);
  const [achievements_write, setAchievementsWrite] = useState(0);

  const [gifts_read, setGiftsRead] = useState(0);
  const [gifts_write, setGiftsWrite] = useState(0);

  const [features_read, setFeaturesRead] = useState(0);
  const [features_write, setFeaturesWrite] = useState(0);

  const [locationuploaded_read, setLocationuploadedRead] = useState(0);
  const [locationuploaded_write, setLocationuploadedWrite] = useState(0);

  const [tokenreceived_read, setTokenreceivedRead] = useState(0);
  const [tokenreceived_write, setTokenreceivedWrite] = useState(0);

  const [accessrestriction_read, setAccessrestrictionRead] = useState(0);
  const [accessrestriction_write, setAccessrestrictionWrite] = useState(0);

  const [userId, setUserId] = useState(0);
  let temp: number;
  const getRoleData = async () => {
    const res: any = await getRoleById(temp);
    console.log(res);
    if (res != null) {
      setLocationRead(res.location_read);
      setLocationWrite(res.location_write);
      setTokenRead(res.token_read);
      setTokenWrite(res.token_write);
      setBonusRead(res.bonus_read);
      setBonusWrite(res.bonus_write);
      setAchievementsRead(res.achievements_read);
      setAchievementsWrite(res.achievements_write);
      setGiftsRead(res.gifts_read);
      setGiftsWrite(res.gifts_write);
      setFeaturesRead(res.features_read);
      setFeaturesWrite(res.features_write);
      setLocationuploadedRead(res.locationuploaded_read);
      setLocationuploadedWrite(res.locationuploaded_write);
      setTokenreceivedRead(res.tokenreceived_read);
      setTokenreceivedWrite(res.enduser_write);
      setAccessrestrictionRead(res.accessrestriction_read);
      setAccessrestrictionWrite(res.accessrestriction_write);
    }
  };
  const isCurrentPath = (path: any) => {
    return location.pathname === path;
  };
  const getLoginUserData = async () => {
    const res: any = await getUser();
    if (res != null) {
      temp = res.id;
      setUserId(temp);
    }
    console.log(temp);
  };
  useEffect(() => {
    const fetchData = async () => {
      await getLoginUserData();
      getRoleData();
    };

    fetchData();
  }, []);

  const handleClick = (from: any) => {
    if (from == "location_and_token") {
      setOpen((prevOpen) => !prevOpen);
      setOpenPerks(false);
      setOpenEndUser(false);
      setOpenAdmin(false);
    } else if (from == "perks") {
      setOpen(false);
      setOpenPerks((prevOpenPerks) => !prevOpenPerks);
      setOpenEndUser(false);
      setOpenAdmin(false);
    } else if (from == "end_user") {
      setOpen(false);
      setOpenPerks(false);
      setOpenEndUser((prevOpenEndUser) => !prevOpenEndUser);
      setOpenAdmin(false);
    } else if (from == "admin") {
      setOpen(false);
      setOpenPerks(false);
      setOpenEndUser(false);
      setOpenAdmin((prevOpenAdmin) => !prevOpenAdmin);
    }
  };

  // const handleClick = (from: any) => {
  //   if (from === 'location_and_token' &&(location_read == 1 && location_write == 1) && (token_read == 1 && token_write == 1)) {
  //     setOpen((prevOpen) => !prevOpen);
  //     setOpenPerks(false);
  //     setOpenEndUser(false);
  //     setOpenAdmin(false);
  //   } else if (from === 'perks' && bonus_read == 1 && bonus_write == 1) {
  //     setOpen(false);
  //     setOpenPerks((prevOpenPerks) => !prevOpenPerks);
  //     setOpenEndUser(false);
  //     setOpenAdmin(false);
  //   } else if (from === 'end_user' && locationuploaded_read == 1 && locationuploaded_write == 1) {
  //     setOpen(false);
  //     setOpenPerks(false);
  //     setOpenEndUser((prevOpenEndUser) => !prevOpenEndUser);
  //     setOpenAdmin(false);
  //   } else if (from === 'admin') {
  //     setOpen(false);
  //     setOpenPerks(false);
  //     setOpenEndUser(false);
  //     setOpenAdmin((prevOpenAdmin) => !prevOpenAdmin);
  //   }
  // };
  // const isAuthenticated = useSelector((state: any) => state.auth.isLoggedIn)
  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     navigate('/sign_in')
  //     sessionStorage.removeItem('token')
  //   }
  // })
  // const handleLogOut = () => {
  //   //dispatch(logout())
  //   sessionStorage.removeItem('jwtToken')
  //   sessionStorage.removeItem('isLoggedIn')
  //   navigate("/sign_in");
  // dispatch(logout())

  // navigate('')
  //  }
  const listItemStyle = {
    borderRadius: "8px",
    margin: "5px 0px 5px 0px",
    fontFamily: " Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21.79px",
    "&:hover": {
      backgroundColor: "#8E47E6",
      color: "#FFFFFF",
      boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
    },
  };
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const handleLogOut = () => {
    dispatch(loaderOn());
    try {
      dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      setIsLoggedIn(false);
      navigate("/sign_in");
    } catch {
      dispatch(loaderOff());
    }
  };

  // useEffect(() => {
  //   if (location.pathname === '/sign_in') {
  //     dispatch(loaderOff());
  //   }
  // }, [location.pathname, dispatch]);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <Drawer
      sx={{
        width: "240px",
        flexShrink: 0,

        "& .MuiDrawer-paper": {
          width: "240px",
          boxSizing: "border-box",
          backgroundColor: "#ECECEC",
          border: "none",
          boxShadow: "0px 6px 14px 5px #8E47E640 !important",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "50px",
          paddingBottom: "20px",
        }}
      >
        <img src="assets/static/svg/applogo.svg" alt="Logo" />
      </Box>
      {/* <Divider /> */}
      <Box
        sx={{
          marginLeft: "10px",
          textAlign: "Left",
          paddingLeft: "10px",
          marginTop: "20px",
          fontWeight: "500",
          color: "#00000080",
          background: "#ECECEC !important",
        }}
      >
        Menu
      </Box>
      <List
        sx={{
          marginLeft: "5px",
          marginRight: "5px",
          background: "#ECECEC !important",
        }}
      >
        <ListItemLink
          to="/dashboard"
          sx={{
            ...listItemStyle,
            color: isCurrentPath("/dashboard") ? "#FFFFFF" : "#140026",
            backgroundColor: isCurrentPath("/dashboard") ? "#8E47E6" : "",
          }}
        />
        <ListItemLink
          to="/location_and_token"
          sx={{
            ...listItemStyle,
            // color: isCurrentPath('/location_and_token') ? '#FFFFFF' : '#140026',
            // backgroundColor: isCurrentPath('/location_and_token') ? '#8E47E6' : '',
          }}
          open={open}
          onClick={() => {
            handleClick("location_and_token");
          }}
        />
        <Collapse component="li" in={open} timeout="auto" unmountOnExit>
          <List
            disablePadding
            sx={{ marginLeft: "30px", marginTop: "0px", marginBottom: "0px" }}
          >
            <ListItemLink
              sx={{
                ...listItemStyle,
                color: isCurrentPath("/location") ? "#FFFFFF" : "#140026",
                backgroundColor: isCurrentPath("/location") ? "#8E47E6" : "",
                "&:active": {
                  backgroundColor: "#8E47E6",
                  color: "#FFFFFF",
                  boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
                },
              }}
              to="/location"
            />

            <ListItemLink
              sx={{
                ...listItemStyle,
                color: isCurrentPath("/token") ? "#FFFFFF" : "#140026",
                backgroundColor: isCurrentPath("/token") ? "#8E47E6" : "",
                "&:active": {
                  backgroundColor: "#8E47E6",
                  color: "#FFFFFF",
                  boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
                },
              }}
              to="/token"
            />
          </List>
        </Collapse>
        <ListItemLink
          to="/perks"
          open={openPerks}
          onClick={() => {
            handleClick("perks");
          }}
          sx={{
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#8E47E6",
              color: "#FFFFFF",
              boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
            },
          }}
        />
        <Collapse component="li" in={openPerks} timeout="auto" unmountOnExit>
          <List
            disablePadding
            sx={{ marginLeft: "30px", marginTop: "0px", marginBottom: "0px" }}
          >
            <ListItemLink
              sx={{
                ...listItemStyle,
                color: isCurrentPath("/bonus") ? "#FFFFFF" : "#140026",
                backgroundColor: isCurrentPath("/bonus") ? "#8E47E6" : "",
                "&:active": {
                  backgroundColor: "#8E47E6",
                  color: "#FFFFFF",
                  boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
                },
              }}
              to="/bonus"
            />
            <ListItemLink
              sx={{
                ...listItemStyle,
                color: isCurrentPath("/achievements") ? "#FFFFFF" : "#140026",
                backgroundColor: isCurrentPath("/achievements")
                  ? "#8E47E6"
                  : "",
                "&:active": {
                  backgroundColor: "#8E47E6",
                  color: "#FFFFFF",
                  boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
                },
              }}
              to="/achievements"
            />
            <ListItemLink
              sx={{
                ...listItemStyle,
                color: isCurrentPath("/gifts") ? "#FFFFFF" : "#140026",
                backgroundColor: isCurrentPath("/gifts") ? "#8E47E6" : "",
                "&:active": {
                  backgroundColor: "#8E47E6",
                  color: "#FFFFFF",
                  boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
                },
              }}
              to="/gifts"
            />
            <ListItemLink
              sx={{
                ...listItemStyle,
                color: isCurrentPath("/features") ? "#FFFFFF" : "#140026",
                backgroundColor: isCurrentPath("/features") ? "#8E47E6" : "",
                "&:active": {
                  backgroundColor: "#8E47E6",
                  color: "#FFFFFF",
                  boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
                },
              }}
              to="/features"
            />
          </List>
        </Collapse>
        <ListItemLink
          to="/end_user"
          open={openEndUser}
          onClick={() => {
            handleClick("end_user");
          }}
          sx={{
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#8E47E6",
              color: "#FFFFFF",
              boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
            },
          }}
        />
        <Collapse component="li" in={openEndUser} timeout="auto" unmountOnExit>
          <List
            disablePadding
            sx={{ marginLeft: "30px", marginTop: "0px", marginBottom: "0px" }}
          >
            <ListItemLink
              sx={{
                ...listItemStyle,
                color: isCurrentPath("/user_location") ? "#FFFFFF" : "#140026",
                backgroundColor: isCurrentPath("/user_location")
                  ? "#8E47E6"
                  : "",
                "&:active": {
                  backgroundColor: "#8E47E6",
                  color: "#FFFFFF",
                  boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
                },
              }}
              to="/user_location"
            />
            <ListItemLink
              sx={{
                ...listItemStyle,
                color: isCurrentPath("/user_token") ? "#FFFFFF" : "#140026",
                backgroundColor: isCurrentPath("/user_token") ? "#8E47E6" : "",
                "&:active": {
                  backgroundColor: "#8E47E6",
                  color: "#FFFFFF",
                  boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
                },
              }}
              to="/user_token"
            />
            <ListItemLink
              sx={{
                ...listItemStyle,
                color: isCurrentPath("/user_access") ? "#FFFFFF" : "#140026",
                backgroundColor: isCurrentPath("/user_access") ? "#8E47E6" : "",
                "&:active": {
                  backgroundColor: "#8E47E6",
                  color: "#FFFFFF",
                  boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
                },
              }}
              to="/user_access"
            />
          </List>
        </Collapse>
        <ListItemLink
          to="/admin"
          open={openAdmin}
          onClick={() => {
            handleClick("admin");
          }}
          sx={{
            ...listItemStyle,
            color: isCurrentPath("/admin") ? "#FFFFFF" : "#140026",
            backgroundColor: isCurrentPath("/admin") ? "#8E47E6" : "",
            "&:active": {
              backgroundColor: "#8E47E6",
              color: "#FFFFFF",
              boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
            },
          }}
        />
        <Collapse component="li" in={openAdmin} timeout="auto" unmountOnExit>
          <List
            disablePadding
            sx={{ marginLeft: "30px", marginTop: "0px", marginBottom: "0px" }}
          >
            <ListItemLink
              sx={{
                ...listItemStyle,
                color: isCurrentPath("/profile") ? "#FFFFFF" : "#140026",
                backgroundColor: isCurrentPath("/profile") ? "#8E47E6" : "",
                "&:active": {
                  backgroundColor: "#8E47E6",
                  color: "#FFFFFF",
                  boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
                },
              }}
              to="/profile"
            />
            <ListItemLink
              sx={{
                ...listItemStyle,
                color: isCurrentPath("/create_user") ? "#FFFFFF" : "#140026",
                backgroundColor: isCurrentPath("/create_user") ? "#8E47E6" : "",
                "&:active": {
                  backgroundColor: "#8E47E6",
                  color: "#FFFFFF",
                  boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
                },
              }}
              to="/create_user"
            />
            <ListItemLink
              sx={{
                ...listItemStyle,
                color: isCurrentPath("/create_role") ? "#FFFFFF" : "#140026",
                backgroundColor: isCurrentPath("/create_role") ? "#8E47E6" : "",
                "&:active": {
                  backgroundColor: "#8E47E6",
                  color: "#FFFFFF",
                  boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
                },
              }}
              to="/create_role"
            />
          </List>
        </Collapse>

        <CustomPrimaryButton
          // onClick={() => handleLogOut()}
          onClick={() => {
            setopenDialog(true);
          }}
          sx={{
            background: "none",
            margin: "20px 0px 0px 10px",
            color: "#000000",
            border: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#8E47E6",
              color: "#FFFFFF",
              boxShadow: "0px 2px 14px 10px #AE43FF2E !important",
            },
          }}
          // startIcon={ <LogoutIcon />}
        >
          {" "}
          <Box
            sx={{
              background: "none",
              margin: "5px 0px 0px 10px",
              color: "#000000",
            }}
          >
            <LogoutIcon />
          </Box>
          Log out
          <MenuItem></MenuItem>
        </CustomPrimaryButton>
      </List>
      <CommonDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        handleConfirm={() => {
          handleLogOut();
          handleCloseDialog();
        }}
        contentText="Are you sure you want to logout?"
      />
    </Drawer>
  );
}
