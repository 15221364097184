import { useState } from 'react'
type FormValues = {
  [key: string]: {
    value: string
    error: boolean
    errorMessage: any
  }
}
export function useSignInForm() {
  const [formValues, setFormValues] = useState<FormValues>({
    email: {
      value: '',
      error: false,
      errorMessage: 'Please enter valid email',
    },
    password: {
      value: '',
      error: false,
      errorMessage: 'Please enter valid password',
    },
  })
  return { formValues, setFormValues }
}
