import { Password } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { createAdmin, getUser } from "../utilities/adminservice";
// import { loaderOff, loaderOn, login, openSnackbar } from '../redux/actions'

import { loaderOff, loaderOn, login, logout, openSnackbar } from '../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthToken } from "../utilities/TokenUtility";
import { useNavigate } from "react-router-dom";
import store from "../redux/store";
export default function BackOffice() {
  const [len, setLen] = useState(0);
  let Length = 0;
  let TotalTokens = 0;
  const [token, setToken] = useState(0);
  const [pending, setPending] = useState(0);
  const [approved, setApproved] = useState(0);
  const [rejected, setRejected] = useState(0);

  const [Id, setId] = useState("");

  const [FirstName, setFirstName] = useState("");

  const [Email, setEmail] = useState("");

  const [Domain, setDomin] = useState("");
  const [Role, setRole] = useState("");


  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isTokenExpired = useSelector((state: any) => state.auth.isTokenExpired);
  console.log("syed" + isLoggedIn);
  useEffect(() => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken || isTokenExpired) {
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    } else if (!isLoggedIn) { 
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    }
  }, [isLoggedIn, isTokenExpired]);


  const [formvalues, setFormvalues]: any = useState({
    first_name: {
      name: '',
      value: '',
      error: false
    },
    last_name: {
      name: '',
      value: '',
      error: false
    },
    email: {
      name: '',
      value: '',
      error: false
    },
    password: {
      name: '',
      value: '',
      error: false
    },
    confirm_password: {
      name: '',
      value: '',
      error: false
    },
    given_User_Name: {
      name: '',
      value: '',
      error: false
    },
    domain: {
      name: '',
      value: '',
      error: false
    },
    role: {
      name: '',
      value: '',
      error: false
    },


  })
  const handleChange = (name: string | number, newValue: any) => {
    setFormvalues((prevState: { [x: string]: any; }) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: newValue,
      },
    }));
  };


const dispatch = useDispatch();

  const fetchData = async () => {
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get("https://dev-backoffice.tribe.sn/locations/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data != null) {
        console.log("Fetched data:", res.data);
        Length = res.data.length;
        setLen(Length);

        console.log("Length of the array:", res.data.length);
        dispatch(loaderOff())
      }
    } catch (error) {
      console.error("Failed to fetch data", error);
      dispatch(loaderOff())
    }
  };

  const totalTokenDistributed = async () => {
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get("https://dev-location.tribe.sn/location/token/getall", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data != null) {
        console.log(res.data);
        TotalTokens = res.data;
        setToken(TotalTokens);
        dispatch(loaderOff())
      }

    } catch (error) {
      console.log(error);
      dispatch(loaderOff())

    }


  }


  const fetchLocationPending = async () => {
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get("https://dev-location.tribe.sn/location/getpending", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data != null) {
        console.log(res.data);
        Length = res.data.length;
        setPending(Length);
        dispatch(loaderOff())
      }

    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  }


  const fetchLocationApproved = async () => {
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get("https://dev-location.tribe.sn/location/getapproved", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data != null) {
        console.log(res.data);
        Length = res.data.length;
        setApproved(Length);
        dispatch(loaderOff())
      }

    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  }

  const fetchLocationRejected = async () => {
    dispatch(loaderOn())
    try {
      const token = sessionStorage.getItem("jwtToken");
      const res = await axios.get("https://dev-location.tribe.sn/location/getrejected", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data != null) {

        console.log(res.data);
        Length = res.data.length;

        setRejected(Length);
        dispatch(loaderOff())
      }

    } catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }

  }

  const submitForm = async () => {
    const formData = {
      first_name: formvalues.first_name.value,
      last_name: formvalues.last_name.value,
      email: formvalues.email.value,
      password: formvalues.password.value,
      confirm_password: formvalues.confirm_password.value,
      given_User_Name: formvalues.given_User_Name.value,
      domain: formvalues.domain.value,
      role: formvalues.role.value,
    }
    dispatch(loaderOn())
    try {
      const response = await createAdmin(formData)
      console.log(response);

      if (response !== null) {
        // handleLogin(response)
        console.log(formvalues);
        console.log('Form data submitted successfully');
        dispatch(loaderOff())
      } else {
        console.error('Failed to submit form data');
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
      dispatch(loaderOff())
    }
  };


  const fetchToken = async () => {
    dispatch(loaderOn())
    try {
      const token = getAuthToken();
      const response = await getUser()
      if (response != null) {

        setId(response.id);
        setFirstName(response.first_name);
        setEmail(response.email);
        setDomin(response.domain);
        setRole(response.role);
        console.log(Id);
        console.log(FirstName);
        console.log(Email);
        console.log(Domain);
        console.log(Role);
        console.log(response);
        dispatch(loaderOff())
      }

    }
    catch (error) {
      console.log(error);
      dispatch(loaderOff())
    }
  }

  useEffect(() => {
    fetchData();
    totalTokenDistributed();
    fetchLocationPending();
    fetchLocationApproved();
    fetchLocationRejected();
    fetchToken();
  }, [0]);


  const handleCancel = () => {

    setFormvalues({});
  };
  return (
    <div style={{
      overflowX: 'hidden'
    }}>
      <div style={{ padding: "30px 30px 42px 250px" }}>
        
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            overflowX: 'scroll'
          }}
        > */}
          {/* <Box
            sx={{
              height: "100px",
              // width: "150px",
              //border: "2px solid black",
              marginRight: "65px",
              backgroundColor: '#DEC9FE'
            }}
          > */}
            {/* <div style={{ padding: "12px 5px 12px 10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize: '16px',
                  fontFamily: '400',
                  width: '150px'
                }}
              >
                Locations Uploaded
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize: '16px',
                  fontWeight: '700',
                }}
              >
                {len}
              </div> */}
              {/* <div style={{ display: "flex", justifyContent: "start" }}>
                +5%
              </div> */}
            {/* </div>
          </Box> */}
          {/* <Box
            sx={{
              height: "100px",
              // width: "150px",
              // border: "2px solid black",
              marginRight: "65px",
              backgroundColor: '#DEC9FE'
            }}
          > */}
            {/* <div style={{ padding: "12px 5px 12px 10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize: '16px',
                  fontFamily: '400',
                  width: '150px'
                }}
              >
                Locations Rejected
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize: '16px',
                  fontWeight: '700',
                }}
              >
                {rejected}
              </div> */}
              {/* <div style={{ display: "flex", justifyContent: "start" }}>
                -2%
              </div> */}
            {/* </div>
          </Box> */}
          {/* <Box
            sx={{
              height: "100px",
              // width: "150px",
              // border: "2px solid black",
              marginRight: "65px",
              backgroundColor: '#DEC9FE'
            }}
          > */}
            {/* <div style={{ padding: "12px 5px 12px 10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize: '16px',
                  fontFamily: '400',
                  width: '150px'
                }}
              >
                Locations Approved
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize: '16px',
                  fontWeight: '700',
                }}
              >
                {approved}
              </div> */}
              {/* <div style={{ display: "flex", justifyContent: "start" }}>
                +10%
              </div> */}
            {/* </div>
          </Box> */}
          {/* <Box
            sx={{
              height: "100px",
              // width: "150px",
              // border: "2px solid black",
              marginRight: "65px",
              backgroundColor: '#DEC9FE'
            }}
          > */}
            {/* <div style={{ padding: "12px 12px 12px 12px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize: '16px',
                  fontFamily: '400',
                  width: '150px'
                }}
              >
                Locations Pending
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize: '16px',
                  fontWeight: '700',
                }}
              >
                {pending}
              </div>
              {/* <div style={{ display: "flex", justifyContent: "start" }}>
                +5%
              </div> */}
            {/* </div> */}
          {/* </Box> */}  
          {/* <Box
            sx={{
              height: "100px",
              // width: "150px",
              // border: "2px solid black",
              marginRight: "65px",
              backgroundColor: '#DEC9FE'
            }}
          > */}
            {/* <div style={{ padding: "12px 12px 12px 12px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize: '16px',
                  fontFamily: '400',
                  width: '150px'
                }}
              >
                Tokens Distributed
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize: '16px',
                  fontWeight: '700',
                }}
              >
                {token}
              </div>
              {/* <div style={{ display: "flex", justifyContent: "start" }}>
                +5%
              </div> */}
            {/* </div>
          </Box> */}  
          {/* <Box
            sx={{
              height: "100px",
              // width: "150px",
              // border: "2px solid black",
              marginRight: "65px",
              backgroundColor: '#DEC9FE'
            }}
          > */}
            {/* <div style={{ padding: "12px 12px 12px 12px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize: '16px',
                  fontFamily: '400',
                  width: '150px'
                }}
              >
                Token To Cash{" "}
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingBottom: "4px",
                  fontSize: '16px',
                  fontWeight: '700',
                }}
              >
                1,000,000
              </div> */}
              {/* <div style={{ display: "flex", justifyContent: "start" }}>
                +5%
              </div> */}
            {/* </div>
          </Box>
        </div> */}
        {/* <hr
          style={{
            borderTop: "10px solid  #F7F6F6",
          }}
        /> */}

        <div
          style={{ display: "flex", justifyContent: "left", marginTop: "38px", fontFamily: '', fontWeight: '700', fontSize: "16px" }}
        >
          Admin Details
        </div>

        <div
          style={{
            height: "115px",
            //backgroundColor: "lightskyblue",

            padding: "48px 39px 48px 39px",

          }}
        >
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",

          }}>

            <div style={{ paddingRight: '15px' }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  fontSize:'17px',
                  fontWeight:'bold'


                }}
              >
             Name
              </div>
              <div>


                <TextField id="filled-basic" variant="filled"
                  value={FirstName}
                  InputProps={{
                    disableUnderline: true,

                    style: {
                      height: '40px', width: '180px', border: '1px solid #8E47E6',
                      fontSize: '16px', fontFamily: 'sans-serif', fontWeight: '600',
                      paddingBottom: '15px'
                    }
                  }} />


              </div>
            </div>

            <div style={{ paddingRight: '15px' }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  fontSize:'17px',
                  fontWeight:'bold' 
                }}
              >
                Employer ID
              </div>
              <div>
                <TextField id="filled-basic" variant="filled"
                  value={Id}
                  InputProps={{
                    disableUnderline: true,

                    style: {
                      height: '40px', width: '180px', border: '1px solid #8E47E6',
                      fontSize: '16px', fontFamily: 'sans-serif', fontWeight: '600',
                      paddingBottom: '15px'
                    }
                  }} />      </div>
            </div>
            <div style={{ paddingRight: '15px' }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  fontSize:'17px',
                  fontWeight:'bold' 
                }}
              >
                Mail ID
              </div>
              <div>
                <TextField id="filled-basic" variant="filled"
                  value={Email}
                  InputProps={{
                    disableUnderline: true,

                    style: {
                      height: '40px', width: '180px', border: '1px solid #8E47E6',
                      fontSize: '16px', fontFamily: 'sans-serif', fontWeight: '600',
                      paddingBottom: '15px'
                    }
                  }} />       </div>
            </div>
            <div style={{ paddingRight: '15px' }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  fontSize:'17px',
                  fontWeight:'bold' 
                }}
              >
                Domain
              </div>
              <div>
                <TextField id="filled-basic" variant="filled"
                  value={Domain}
                  InputProps={{
                    disableUnderline: true,

                    style: {
                      height: '40px', width: '180px', border: '1px solid #8E47E6',
                      fontSize: '16px', fontFamily: 'sans-serif', fontWeight: '600',
                      paddingBottom: '15px'
                    }
                  }} />        </div>
            </div>
            <div style={{ paddingRight: '15px' }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  fontSize:'17px',
                  fontWeight:'bold' 
                }}
              >
                Role
              </div>
              <div>
                <TextField id="filled-basic" variant="filled"
                  value={Role}
                  InputProps={{
                    disableUnderline: true,

                    style: {
                      height: '40px', width: '180px', border: '1px solid #8E47E6',
                      fontSize: '16px', fontFamily: 'sans-serif', fontWeight: '600',
                      paddingBottom: '15px'

                    }
                  }} />       </div>
            </div>

          </div>
          <div>

            <div
              style={{
                display: "flex",
                justifyContent: "start",
                fontSize:'17px',
                fontWeight:'bold' 
              }}
            >
              Access
            </div>
            <div style={{
              display: "flex",
              justifyContent: "start",
            }}>
              <TextField id="filled-basic" variant="filled"
                InputProps={{
                  disableUnderline: true,

                  style: {
                    height: '40px', width: '180px', border: '1px solid #8E47E6',
                    fontSize: '16px', fontFamily: 'sans-serif', fontWeight: '600',
                    paddingBottom: '15px'
                  }
                }} />
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "left", marginTop: "38px", fontSize: '16px', fontWeight: 'bold' }}
        >
          Add New User / Member
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "48px 39px 48px 39px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ paddingRight: '15px' }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  fontSize:'17px',
                  fontWeight:'bold' 
                }}
              >
                First Name
              </div>
              <div>


                <TextField id="filled-basic" variant="filled"
                  name="first_name"
                  value={formvalues.first_name.value}
                  onChange={(e) => handleChange("first_name", e.target.value)}
                  InputProps={{
                    disableUnderline: true,

                    style: {
                      height: '40px', width: '180px', border: '1px solid #8E47E6',
                      paddingBottom: '15px'
                    }
                  }} />
              </div>

            </div>
            <div style={{ paddingRight: '15px' }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  fontSize:'17px',
                  fontWeight:'bold' 
                }}
              >
                Last Name
              </div>
              <div>


                <TextField id="filled-basic" variant="filled"
                  name="last_name"
                  value={formvalues.last_name.value}
                  onChange={(e) => handleChange("last_name", e.target.value)}
                  InputProps={{
                    disableUnderline: true,

                    style: {
                      height: '40px', width: '180px', border: '1px solid #8E47E6',
                      paddingBottom: '15px'
                    }
                  }} />
              </div>
            </div>
            <div style={{ paddingRight: '15px' }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  fontSize:'17px',
                  fontWeight:'bold' 
                }}
              >
                Email ID
              </div>
              <div>


                <TextField id="filled-basic" variant="filled"
                  name="email"
                  value={formvalues.email.value}
                  onChange={(e) => handleChange("email", e.target.value)}
                  InputProps={{
                    disableUnderline: true,

                    style: {
                      height: '40px', width: '180px', border: '1px solid #8E47E6',
                      paddingBottom: '15px'
                    }
                  }} />


              </div>
            </div>
            <div style={{ paddingRight: '15px' }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  fontSize:'17px',
                  fontWeight:'bold' 
                }}
              >
                Password
              </div>
              <div>


                <TextField id="filled-basic" variant="filled"
                  name="password"
                  value={formvalues.password.value}
                  onChange={(e) => handleChange("password", e.target.value)}
                  InputProps={{
                    disableUnderline: true,

                    style: {
                      height: '40px', width: '180px', border: '1px solid #8E47E6',
                      paddingBottom: '15px'
                    }
                  }} />
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  fontSize:'17px',
                  fontWeight:'bold' 
                }}
              >
                Confirm Password
              </div>
              <div>


                <TextField id="filled-basic" variant="filled"
                  name="confirm_password"
                  value={formvalues.confirm_password.value}
                  onChange={(e) => handleChange("confirm_password", e.target.value)}
                  InputProps={{
                    disableUnderline: true,

                    style: {
                      height: '40px', width: '180px', border: '1px solid #8E47E6',
                      paddingBottom: '15px'
                    }
                  }} />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize:'17px',
              fontWeight:'bold' 

            }}
          >
            <div style={{ paddingRight: '45px' }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                Given User Name
              </div>
              <div>
                <TextField id="filled-basic" variant="filled"
                  name="given_User_Name"
                  value={formvalues.given_User_Name.value}
                  onChange={(e) => handleChange("given_User_Name", e.target.value)}
                  InputProps={{
                    disableUnderline: true,

                    style: {
                      height: '40px', width: '180px', border: '1px solid #8E47E6',
                      paddingBottom: '15px'
                    }
                  }} />
              </div>
            </div>
            <div style={{paddingRight: '45px' }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  fontSize:'17px',
                  fontWeight:'bold' 
                }}
              >
                Domain
              </div>
              <div>


                <TextField id="filled-basic" variant="filled"
                  name="domain"
                  value={formvalues.domain.value}
                  onChange={(e) => handleChange("domain", e.target.value)}
                  InputProps={{
                    disableUnderline: true,

                    style: {
                      height: '40px', width: '180px', border: '1px solid #8E47E6', paddingBottom: '15px'

                    }
                  }} />
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  fontSize:'17px',
                  fontWeight:'bold' 
                }}
              >
                Role
              </div>
              <div>


                <TextField id="filled-basic" variant="filled"
                  name="role"
                  value={formvalues.role.value}
                  onChange={(e) => handleChange("role", e.target.value)}
                  InputProps={{
                    disableUnderline: true,

                    style: {
                      height: '40px', width: '180px', border: '1px solid #8E47E6',
                      paddingBottom: '15px'
                    }
                  }} />
              </div>
            </div>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'right'

          }}>
            <div style={{ paddingRight: '15px' }}>
              <button
                style={{
                  height: "30px",
                  width: "150px",
                  marginTop: "20px",
                  transition: 'background-color 0.3s',

                }}
                onClick={submitForm}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#8E47E6"}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = "white"}
              >
                Add
              </button>
            </div>

            <div style={{ paddingRight: '15px' }}>
              <button
                style={{
                  height: "30px",
                  width: "150px",
                  marginTop: "20px",
                  transition: 'background-color 0.3s'

                }}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#8E47E6'}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'white'}
              >
                Cancle
              </button>
            </div>
          </div>
        </div>
        {/* <div>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              marginTop: "38px",
              fontSize: '16px',
              fontWeight: '700'
            }}
          >
            Role Modification
          </div>

          <div
            style={{
              height: " 302px",
              padding: "48px 39px 48px 39px",
            }}
          >


            <div
              style={{
                display: "flex",
                justifyContent: "left",
                marginTop: '45px'
              }}
            >
              <div style={{ paddingRight: '15px' }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  User Name{" "}
                </div>
                <div>
                  <TextField id="filled-basic" variant="filled"

                    InputProps={{
                      disableUnderline: true,

                      style: {
                        height: '40px', width: '180px', border: '1px solid #8E47E6',
                      }
                    }} />       </div>
              </div>
              <div style={{ paddingRight: '15px' }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  User ID
                </div>
                <div>
                  <TextField id="filled-basic" variant="filled"

                    InputProps={{
                      disableUnderline: true,

                      style: {
                        height: '40px', width: '180px', border: '1px solid #8E47E6',
                      }
                    }} />             </div>
              </div>

              <div style={{ paddingRight: '15px' }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  Specific Menu
                </div>
                <div>
                  <TextField id="filled-basic" variant="filled"

                    InputProps={{
                      disableUnderline: true,

                      style: {
                        height: '40px', width: '180px', border: '1px solid #8E47E6',
                      }
                    }} />            </div>
              </div>
              <div style={{ paddingRight: '15px' }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  Role
                </div>
                <div>
                  <TextField id="filled-basic" variant="filled"

                    InputProps={{
                      disableUnderline: true,

                      style: {
                        height: '40px', width: '180px', border: '1px solid #8E47E6',
                      }
                    }} />               </div>
              </div>

            </div>




            <div style={{
              display: 'flex',
              justifyContent: 'right'

            }}>
              <div style={{ paddingRight: '15px' }}>
                <button
                  style={{
                    height: "30px",
                    width: "150px",
                    marginTop: "50px",
                    transition: 'background-color 0.3s',

                  }}
                  onClick={submitForm}
                  onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#8E47E6"}
                  onMouseOut={(e) => e.currentTarget.style.backgroundColor = "white"}
                >
                  Save
                </button>
              </div>

              <div style={{ paddingRight: '15px' }}>
                <button
                  style={{
                    height: "30px",
                    width: "150px",
                    marginTop: "50px",
                    transition: 'background-color 0.3s'

                  }}
                  onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#8E47E6'}
                  onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'white'}
                >
                  Cancle
                </button>
              </div>
            </div>








          </div>
        </div> */}
      </div>
    </div>
  );
}
