import userApi from "./userApi"

export async function getUserdetails() {
try {
    const response = await userApi.get('/user/active/getall')
    return response.data
} catch (error) {
    return error
}
}
export async function updateStatus(userId: any, formData: any) {
    try {
      const response = await userApi.put(`/user/update/${userId}`, formData);

      return response.data;
    } catch (error) {
      return error;
    }
  }


  export async function getAlltokens() {
    try {
        const response = await userApi.get('/user/getall')
        return response.data
    } catch (error) {
        return error
    }
    }


    export async function allocateToken(email: any, formData: any) {
      try {
        const response = await userApi.post(`/user/allocate-token/${email}`, formData);
  
        return response.data;
      } catch (error) {
        return error;
      }
    }
  