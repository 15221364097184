import locationApi from "./locationApi"

export async function getActiveToken() {
try {
    const response = await locationApi.get('/token/active')
    return response.data
} catch (error) {
    return error
}
}

export async function getToken() {
    try {
        const response = await locationApi.get('/location/data-collected')
        return response.data
    } catch (error) {
        return error
    }
    }

    export async function getAllToken() {
        try {
            const response = await locationApi.get('/location/token/getall')
            return response.data
        } catch (error) {
            return error
        }
        }

        export async function getActiveLocation() {
            try {
                const response = await locationApi.get('/location/active/getall')
                return response.data
            } catch (error) {
                return error
            }
            }


        export async function getById(referenceid:any) {
            try {
                const response = await locationApi.get('/location/getbyid/'+referenceid)
                return response.data
            } catch (error) {
                return error
            }
            }

            export async function updateLocation(formData: any) {
                try {
                  const res = await locationApi.put(`/location/update`,formData);
                  return res.data;
                  
                } catch (error) {
                  console.log(error);
                  return error;
                }
              };

              export async function updateDeleteLocation(formData: any) {
                try {
                  const res = await locationApi.put(`/location/delete`,formData);
                  return res.data;
                  
                } catch (error) {
                  console.log(error);
                  return error;
                }
              };