import { useState } from 'react'
type FormValues = {
    [key: string]: {
        value: string
        error: boolean
        errorMessage: any
    }
}
export function useTokenForm() {
    const [formValues, setFormValues] = useState<FormValues>({       
         category: {
           
            value: '',
            error: false,
            errorMessage: 'Please enter valid category',
        },
        details: {
             
            value: '',
            error: false,
            errorMessage: 'Please enter details',
        },
        discovery: {
             value: '',
            error: false,
            errorMessage: 'Please enter  discovery',
        },
        collector: {
             value: '',
            error: false,
            errorMessage: 'Please enter  collector',
        },
        updatepoi: {
             value: '',
            error: false,
            errorMessage: 'Please enter updatepoi',
        },
    })
    return { formValues, setFormValues }

}