// CustomTextFieldFilled.tsx

import React, { ChangeEvent, ReactNode } from "react";
import TextField from "@mui/material/TextField";

interface CustomTextFieldFilledProps {
  formValues: any; // Adjust the type based on your form data structure
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  fieldName: string;
  placeholder?: string;
  readOnly?: boolean;

  children?: ReactNode;
}

const CustomTextField: React.FC<CustomTextFieldFilledProps> = ({
  formValues,
  handleChange,
  fieldName,
  placeholder,
  readOnly,
  children,
}) => {
  return (
    <TextField
      id={fieldName}
      variant="filled"
      name={fieldName}
      onChange={handleChange}
      value={formValues[fieldName].value}
      error={formValues[fieldName].error}
      helperText={
        formValues[fieldName].error && formValues[fieldName].errorMessage
      }
      InputProps={{
        readOnly: readOnly,
        disableUnderline: true,
        style: {
          border: "1px solid #8E47E6",
          height: "7vh",
          borderRadius: "12px",
          // paddingBottom: "10%",
          paddingBottom: '20px',
          background: "#FFFFFF",
          marginTop: "10px",
          display: 'flex',  // Center vertically
          alignItems: 'center',

        },
        inputProps: {
          style: {
        
          
          },
        },
      }}
      placeholder={placeholder}
      
    >
      {children}
    </TextField>
  );
};

export default CustomTextField;
