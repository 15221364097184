import { useState } from "react";

type FormValues = {
  [key: string]: {
    value: string;
    error: boolean;
    errorMessage: any;
  };
};

export function useAchievementForm() {
  const [formValues, setFormValues] = useState<FormValues>({
    achievement_name: {
      value: "",
      error: false,
      errorMessage: "Please enter valid Achievement name",
    },
    achievement_description: {
      value: "",
      error: false,
      errorMessage: "Please enter valid Achievement description",
    },

    achievement_criteria: {
      value: "",
      error: false,
      errorMessage: "Please enter valid Achievement criteria",
    },
    level: {
      value: "",
      error: false,
      errorMessage: "Please enter valid Achievement level",
    },
    gift_name: {
      value: "",
      error: false,
      errorMessage: "Please enter valid Gift name",
    },
    no_of_tokens: {
      value: "",
      error: false,
      errorMessage: "Please enter token",
    },
  });
  return { formValues, setFormValues };
}

export function useGiftform() {
  const [formValues, setFormValues] = useState<FormValues>({
    gift_name: {
      value: "",
      error: false,
      errorMessage: "Please enter valid Gift name",
    },
    gift_description: {
      value: "",
      error: false,
      errorMessage: "Please enter valid Gift description",
    },

    gift_criteria: {
      value: "",
      error: false,
      errorMessage: "Please enter valid Gift criteria",
    },
    no_of_tokens: {
      value: "",
      error: false,
      errorMessage: "Please enter valid No. of Tokens",
    },
  });
  return { formValues, setFormValues };
}

export function useFeatureForm() {
  const [formValues, setFormValues] = useState<FormValues>({
    id: {
      value: "",
      error: false,
      errorMessage: "Please enter feature id",
    },
    feature_name: {
      value: "",
      error: false,
      errorMessage: "Please enter valid feature name",
    },
    feature_description: {
      value: "",
      error: false,
      errorMessage: "Please enter valid feature description",
    },
  });
  return { formValues, setFormValues };
}
