import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { getAllLocation } from "../utilities/backofficeservice";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { loaderOff, loaderOn, logout, openSnackbar } from "../redux/actions";
import { useNavigate } from "react-router-dom";
import store from "../redux/store";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid white",
  // boxShadow: 24,
  p: 4,
};

interface Column2 {
  // id: "id" | "User" | "Location_Name" | "Location_Address" | "Created_on" |"Token_Earned"  ;
  id:
    | "id"
    | "Sl No"
    | "UserEmail"
    | "Location_Address"
    | "token"
    | "SerialNumber";

  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

interface BackendRow2 {
  createdBy: string;
  id: any;
  token: any;
  // address: string;
  // createdOn: Date;
}
const generateOrFetchId = (): string => {
  return Math.random().toString(36).substr(2, 9);
};

const columns1: readonly Column2[] = [
  { id: "SerialNumber", label: "Sl No" },
  // { id: "id", label: "Sl No" },
  { id: "UserEmail", label: "User Email Id" },
  { id: "token", label: "Location Created" },
];

export default function User_Token() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows1, setRows1] = useState<BackendRow2[]>([]);
  const [searchQuery1, setSearchQuery1] = useState("");
  const [createdByCounts, setCreatedByCounts] = useState<
    Record<string, number>
  >({}); // Define state for createdByCounts


  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isTokenExpired = useSelector((state: any) => state.auth.isTokenExpired);
  console.log("syed" + isLoggedIn);
  useEffect(() => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken || isTokenExpired) {
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    } else if (!isLoggedIn) { 
      store.dispatch(logout());
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("isSubcribed");
      dispatch(
        openSnackbar("Your session has expired. Please log in again.", "red")
      );
      navigate("/sign_in");
    }
  }, [isLoggedIn, isTokenExpired]);
















  const filteredRows1 = rows1.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery1.toLowerCase())
    )
  );

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const slicedRows1 = filteredRows1.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );

  // useEffect(() => {
  //     fetchlocations();
  //   }, []);

  //   const generateOrFetchId = (): string => {
  //     return Math.random().toString(36).substr(2, 9);
  //   };

  //   const fetchlocations = async () => {
  //     try {
  //       const response = await getAllLocation();
  //       console.log(response);
  //       const backendData: any[] = response;

  //       const tokensByCreatedBy: Record<string, number> = {};

  //       backendData.forEach((backendRow: any) => {
  //         const createdBy = backendRow.createdBy;
  //         const token = backendRow.token;

  //         if (tokensByCreatedBy.hasOwnProperty(createdBy)) {
  //           tokensByCreatedBy[createdBy] += token;
  //         } else {
  //           tokensByCreatedBy[createdBy] = token;
  //         }
  //       });

  //       const aggregatedData = Object.entries(tokensByCreatedBy).map(
  //         ([createdBy, token]) => ({
  //           id: generateOrFetchId(),
  //           createdBy,
  //           token,
  //         })
  //       );

  //       setRows1(aggregatedData);
  //       console.log(aggregatedData);
  //     } catch (error) {
  //       console.error('Error fetching locations:', error);
  //     }
  //   };

  useEffect(() => {
    fetchLocations();
  }, []);

  const dispatch = useDispatch();

  const fetchLocations = async () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
        console.log("JWT token not available. Unable to make API call.");
        dispatch(openSnackbar("Opps something went wrong please login again", "red"));
        return;
    }
    dispatch(loaderOn())
    try {
      const response = await getAllLocation();
      console.log(response);
      const tokensByCreatedBy: Record<string, number> = {};
      const createdByCounts: Record<string, number> = {};
      response.forEach((location: any) => {
        const createdBy = location.createdBy;
        const token = location.token;
        if (tokensByCreatedBy.hasOwnProperty(createdBy)) {
          tokensByCreatedBy[createdBy] += token;
          dispatch(loaderOff())
        } else {
          tokensByCreatedBy[createdBy] = token;
        }
        createdByCounts[createdBy] = (createdByCounts[createdBy] || 0) + 1;
      });
      const aggregatedData = Object.entries(tokensByCreatedBy).map(
        ([createdBy, token]) => ({
          id: generateOrFetchId(),
          createdBy,
          token,
        })
      );

      setRows1(aggregatedData);
      setCreatedByCounts(createdByCounts);
      console.log(aggregatedData);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
    dispatch(loaderOff())
  };

  return (
    <Box marginTop={"40px"}>
      <div
        style={{
          display: "flex",
          justifyContent: "row",
          marginRight: "20px",
          paddingBottom: "34px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: "17px",
              fontWeight: "700",
              paddingRight: "40px",
            }}
          >
            Location Uploaded
          </Typography>
          <span>
            <TextField
              // label="Search"
              placeholder="Search..."
              value={searchQuery1}
              onChange={(e) => setSearchQuery1(e.target.value)}
              variant="outlined"
              margin="normal"
              style={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#140026" }} />
                  </InputAdornment>
                ),
                style: {
                  width: "300px",
                  boxShadow: "0px 4px 4px 0px rgba(142, 71, 230, 0.25)",
                  borderRadius: "6px",
                },
              }}
            />
          </span>
        </div>
      </div>
      {/* <div style={{
                        display: 'flex',
                        justifyContent: 'start'
                    }}> */}

      {/* </div> */}
      {/* <Button
                        style={{
                            color: "black",
                            backgroundColor: "#FFFFFF",
                            border:'1px solid #8E47E6',
                            marginRight:'20px',
                            fontFamily: 'Open Sans',
                            textTransform:'none',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left',
                        }}
                        endIcon={<ExpandMoreOutlinedIcon />}
                    >
                      Last 6 Months
                    </Button>
                    <Button
                        style={{
                            color: "#FFFFFF",
                            backgroundColor: "#8E47E6",
                            fontFamily: 'Open Sans',
                            textTransform:'none',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left',
                        }}
                        endIcon={<DownloadIcon />}
                    >
                        Export{" "}
                    </Button> */}
      {/* <Button
                        style={{
                            color: "black",
                            backgroundColor: "#8E47E6",
                        }}
                        endIcon={<DownloadIcon />}
                    >
                        Export{" "}
                    </Button> */}

      <Box
        sx={{
          color: "black",
          marginTop: "20px",
        }}
      >
        <div></div>
        <Paper sx={{ overflow: "hidden" }}>
          {/* <div style={{
                        display: 'flex',
                        justifyContent: 'start'
                    }}>
                        <TextField
                            label="Search"
                            value={searchQuery1}
                            onChange={(e) => setSearchQuery1(e.target.value)}
                            variant="outlined"
                            margin="normal"
                            inputProps={{
                                style: {
                                    width: '200px',
 
 
                                }
                            }}
 
                        />
                    </div> */}
          <TableContainer
            sx={{
              maxHeight: 440,
              borderRadius: "18px",
              boxShadow: "0px 4px 12px 0px #8E47E640",
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ color: "#8E47E6" }}
            >
              <TableHead>
                <TableRow sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}>
                  {columns1.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, color: "#8E47E6" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {slicedRows1.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ boxShadow: "0px 4px 12px 0px #8E47E640" }}
                  >
                    {/* {columns1.map((column) => (
                      <TableCell key={column.id}>
                        {column.id === "id" ? (index + 1).toString() : null}
                        {column.id === "Sl No" ? (index + 1).toString() : null}
                        {column.id === "UserEmail" ? row.createdBy : null}
                        {column.id === "token"
                          ? `${createdByCounts[row.createdBy]} `
                          : null}
                      </TableCell>
                    ))} */}
                    {columns1.map((column, columnIndex) => (
                      <TableCell key={column.id}>
                        {column.id === "SerialNumber"
                          ? index + 1
                          : column.id === "UserEmail"
                          ? row.createdBy
                          : column.id === "token"
                          ? `${createdByCounts[row.createdBy]} `
                          : null}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredRows1.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
